import { analyticsHandlers } from "../Analytics";
import { clipHandlers } from "../Clip";
import { customerHandlers } from "../Customer";
import { followallHandlers } from "../Followall";
import { linkHandlers } from "../Link";
import { podcastHandlers } from "../Podcast";
import { userHandlers } from "../User";
import { episodeHandlers } from "../Episode";
import { campaignHandlers } from "../Campaign";
import { promoHandlers } from "../Promo";

export default {
    ...podcastHandlers,
    ...analyticsHandlers,
    ...followallHandlers,
    ...userHandlers,
    ...customerHandlers,
    ...clipHandlers,
    ...linkHandlers,
    ...episodeHandlers,
    ...campaignHandlers,
    ...promoHandlers
}