import React, { useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import { AppstoreAddOutlined, LeftOutlined, RightOutlined, FolderAddOutlined, BarChartOutlined, SettingOutlined, AppstoreOutlined, HomeOutlined, SoundOutlined, TabletOutlined, AimOutlined } from "@ant-design/icons"
import Sider from 'antd/es/layout/Sider'
import { Menu, Divider } from 'antd'
import theme from '../../theme'
import styled from 'styled-components'
import { compose, prop } from 'ramda'
import { withPodcasts } from '../Podcast'
import { PodCardsButton } from '../../Typography'

const Sidebar = styled(Sider)`
    background: ${theme['bright-white']};
    border-radius: 0px ${theme['border-radius-base']} ${theme['border-radius-base']} 0px;
`

const TriggerButton = styled(PodCardsButton)`
    width: 80%;
    background: ${theme['light-gray']} !important;
    border: none;
`

const SidebarMenu = styled(Menu)`
    border-radius: 0px ${theme['border-radius-base']} ${theme['border-radius-base']} 0px;
    border-right: 0;
    overflow: auto;
    height: 100vh;
    min-width: 80px;
    max-width: ${({ collapsed }) => collapsed ? '80px' : '200px'};
    position: fixed
`

const MenuItem = styled.p`
    color: ${({ active }) => active ? theme['primary-blue'] : theme['primary-black']};
    line-height: 16px;

`

const MenuDivider = styled(Divider)`

`

const AnalyticsSidebar = ({
    Podcasts: { podcast },
    display = true
}) => {
    const navigate = useNavigate()
    const location = useLocation()

    const [collapsed, setCollapsed] = useState(false)
    const [keepOpen, setKeepOpen] = useState(true)

    const [openKeys, setOpenKeys] = useState([])

    const { id } = podcast

    const activeKey = prop('pathname', location)
    const params = prop('search', location)

    const items = [
        {
            icon: <HomeOutlined style={{ color: activeKey === `/${id}/summary` ? theme['primary-blue'] : theme['primary-black']}} />,
            label: <MenuItem active={activeKey === `/${id}/summary`}>Home</MenuItem>,
            key: `/${id}/summary${params}`
        },
        {
            key: `/${id}/summary${params}&k=1`,
            icon: <MenuDivider orientation="left" plain style={{ fontSize: '12px', color: theme['primary-black-opacity'] }}>{collapsed ? '' : 'Channels'}</MenuDivider>
        },
        {
            key: 'podcard',
            icon: <TabletOutlined style={{ color: activeKey.includes('podcard') ? theme['primary-blue'] : theme['primary-black'] }} />,
            label: <MenuItem active={activeKey.includes('podcard')}>Podcard</MenuItem>,
            children: [
                {
                    icon: <BarChartOutlined style={{ color: activeKey === `/${id}/podcard/analytics` ? theme['primary-blue'] : theme['primary-black']}} />,
                    label: <MenuItem active={activeKey === `/${id}/podcard/analytics`}>Analytics</MenuItem>,
                    key: `/${id}/podcard/analytics${params}`
                },
                {
                    icon: <SettingOutlined style={{ color: activeKey === `/${id}/podcard` ? theme['primary-blue'] : theme['primary-black']}} />,
                    label: <MenuItem active={activeKey === `/${id}/podcard`}>Settings</MenuItem>,
                    key: `/${id}/podcard${params}`
                }
            ]
        },
        {
            key: 'promos',
            icon: <SoundOutlined style={{ color: activeKey.includes('promos') ? theme['primary-blue'] : theme['primary-black'] }}/>,
            label: <MenuItem active={activeKey.includes('promos')}>Promos</MenuItem>,
            children: [
                {
                    icon: <BarChartOutlined style={{ color: activeKey === `/${id}/promos/analytics` ? theme['primary-blue'] : theme['primary-black']}} />,
                    label: <MenuItem active={activeKey ===  `/${id}/promos/analytics`}>Analytics</MenuItem>,
                    key: `/${id}/promos/analytics${params}`
                },
                {
                    icon: <FolderAddOutlined style={{ color: activeKey === `/${id}/promos` ? theme['primary-blue'] : theme['primary-black']}} />,
                    label: <MenuItem active={activeKey === `/${id}/promos`}>Manage</MenuItem>,
                    key: `/${id}/promos${params}`
                }
            ]
        },
        {
            key: 'followall',
            icon: <AppstoreOutlined style={{ color: activeKey.includes('followall') ? theme['primary-blue'] : theme['primary-black']}} />,
            label: <MenuItem active={activeKey.includes('followall')}>Followall</MenuItem>,
            children: [
                {
                    icon: <BarChartOutlined style={{ color: activeKey === `/${id}/followall/analytics` ? theme['primary-blue'] : theme['primary-black']}} />,
                    label: <MenuItem active={activeKey ===  `/${id}/followall/analytics`}>Analytics</MenuItem>,
                    key: `/${id}/followall/analytics${params}`
                },
                {
                    icon: <AppstoreAddOutlined style={{ color: activeKey === `/${id}/followall` ? theme['primary-blue'] : theme['primary-black']}} />,
                    label: <MenuItem active={activeKey === `/${id}/followall`}>Apps</MenuItem>,
                    key: `/${id}/followall${params}`
                }
            ]
        },
        {
            key: `/${id}/summary${params}&k=2`,
            icon: <MenuDivider />
        },
        {
            icon: <AimOutlined style={{ color: activeKey === `/${id}/campaigns` ? theme['primary-blue'] : theme['primary-black']}} />,
            label: <MenuItem active={activeKey ===  `/${id}/campaigns`}>Campaigns</MenuItem>,
            key: `/${id}/campaigns${params}`
        }
    ]

    const keepOpenTrigger = (
        <TriggerButton onClick={() => setKeepOpen(!keepOpen)}>
            <RightOutlined style={{ color: theme['primary-black'] }} />
        </TriggerButton>
    )
    const keepClosedTrigger = (
        <TriggerButton onClick={() => setKeepOpen(!keepOpen)}>
            <LeftOutlined style={{ color: theme['primary-black'] }} />
        </TriggerButton>
    )

    const handleMenuClick = compose(
        ([key, parent]) => {
            navigate(key)
            setOpenKeys([parent])
        },
        prop('keyPath')
    )

    return display ? (
        <Sidebar
            collapsible
            collapsed={collapsed}
            style={{ background: theme['bright-white'] }}
            onMouseEnter={() => keepOpen ? null : setCollapsed(false)}
            onMouseLeave={() => keepOpen ? null : setCollapsed(true)}
            trigger={keepOpen ? keepClosedTrigger : keepOpenTrigger}
        >
            <SidebarMenu
                collapsed={collapsed}
                items={items}
                mode="inline"
                activeKey={activeKey}
                openKeys={openKeys}
                onClick={handleMenuClick}
                onOpenChange={setOpenKeys}
            />
        </Sidebar>
    ) : null

}

export default compose(
    withPodcasts
)(AnalyticsSidebar)