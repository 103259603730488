import React from 'react'
import { compose } from "ramda"
import withCampaigns from "./withCampaigns"
import Card from '../../Components/Card'
import styled from 'styled-components'
import { Table } from 'antd'
import { StockOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { withPodcasts } from '../Podcast'
import { PodCardsButton } from '../../Typography'

const CampaignsCard = styled(Card)``

const Campaigns = ({
    Podcasts: {
        podcast: { id }
    },
    Campaigns: {
        campaigns = []
    }
}) => {

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name > b.name
        },
        {
            title: 'Id',
            dataIndex: ['utm', 'campaign'],
            key: 'campaign',
            sorter: (a, b) => a.id > b.id
        },
        {
            title: 'Source',
            dataIndex: ['utm', 'source'],
            key: 'source',
            sorter: (a, b) => a.utm.source > b.utm.source
        },
        {
            title: 'Medium',
            dataIndex: ['utm', 'medium'],
            key: 'medium',
            sorter: (a, b) => a.utm.medium > b.utm.medium
        },
        {
            key: 'analytics',
            render: (_, campaign) => (
                <div>
                    <Link to={`/${id}/summary?campaignId=${campaign.utm.campaign}`}>
                        <StockOutlined />
                    </Link>
                </div>
            )

        }
    ]

    const NewCampaign = (
        <Link to={'new'}>
            <PodCardsButton>New Campaign</PodCardsButton>
        </Link>
    )

    return (
        <CampaignsCard extra={NewCampaign}>
            <Table columns={columns} dataSource={campaigns} />
        </CampaignsCard>
    )

}

export default compose(
    withPodcasts,
    withCampaigns
)(Campaigns)