import React from "react"
import { Tooltip } from "antd"
import { compose } from "ramda"
import { withCustomer } from "../Customer"
import { withAuth } from "../Auth"
import { withPodcasts } from "../Podcast"
import { LockOutlined, UploadOutlined } from "@ant-design/icons"
import withClips from "./withClips"
import styled from "styled-components"
import ConnectedButton from "../Shared/ConnectedButton"

const FileUploader = styled.input`
    display: none;
`

const ClipUploader = ({
    episodeId = '',
    Podcasts: { podcast },
    Customer: { hasBasicSubscription },
    Auth: { user },
    Clips: { uploadClip, uploadingClip }
}) => {

    const handleSelectFile = (event) => {
        const file = event.target.files[0]

        uploadClip(file, podcast.id, episodeId, user.accessToken)
    }

    return (
        <Tooltip title='Upgrade access clips' overlayStyle={{ display: hasBasicSubscription ? 'none' : '' }}>
            <div style={{ width: '100px' }}>
                <ConnectedButton
                    loading={uploadingClip}
                    disabled={!hasBasicSubscription}
                    icon={hasBasicSubscription ? <UploadOutlined /> : <LockOutlined />}
                    content={<>
                        <label htmlFor="upload"> Upload Clip</label>
                        <FileUploader type="file" id="upload" name="file" onChange={handleSelectFile} />
                    </>}
                />
            </div>
        </Tooltip>
    )
}

export default compose(
    withCustomer,
    withAuth,
    withPodcasts,
    withClips
)(ClipUploader)