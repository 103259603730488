import axios from "axios"
import { compose, defaultTo, evolve, join, prepend, prop, split, __ } from "ramda"
import { dayjs } from "../../Utils/time.utils"

export const PLAYER_EVENT_TYPES = {
    PLAY: 'player/play',
    PAUSE: 'player/pause',
    SUBSCRIBE: 'player/subscribe',
    PROGRESS: 'player/progress'
}

const EventLogger = ({ sessionId }) => async ({
    type = '',
    podcastId = '',
    episodeGuid = '',
    clipId = '',
    progress = 0,
    duration = 0,
    app = ''
}) => {
    try {
        const DATA_MAPPER = {
            [PLAYER_EVENT_TYPES.PLAY]: {
                type,
                sessionId,
                timestamp: dayjs().format(process.env.DATE_FORMAT),
                clipId,
                episodeGuid,
                podcastId,
                progressSeconds: progress,
                durationSeconds: duration
            },
            [PLAYER_EVENT_TYPES.PAUSE]: {
                type,
                sessionId,
                timestamp: dayjs().format(process.env.DATE_FORMAT),
                clipId,
                episodeGuid,
                podcastId,
                progressSeconds: progress,
                durationSeconds: duration
            },
            [PLAYER_EVENT_TYPES.PROGRESS]: {
                type,
                sessionId,
                timestamp: dayjs().format(process.env.DATE_FORMAT),
                clipId,
                episodeGuid,
                podcastId,
                progressSeconds: progress,
                durationSeconds: duration
            },            
            [PLAYER_EVENT_TYPES.SUBSCRIBE]: {
                type,
                sessionId,
                timestamp: dayjs().format(process.env.DATE_FORMAT),
                clipId,
                episodeGuid,
                podcastId,
                progressSeconds: progress,
                durationSeconds: duration,
                app
            },
            default: {
                type,
                sessionId,
                timestamp: dayjs().format(process.env.DATE_FORMAT),
                clipId,
                episodeGuid,
                podcastId,
                progressSeconds: progress,
                durationSeconds: duration
            }
        }

        const createEvent = compose(
            evolve({ type: compose(join(':'), prepend('analytics'), split('/'))}),
            defaultTo(DATA_MAPPER.default),
            prop(__, DATA_MAPPER)
        )

        await axios({
            method: 'POST',
            url: `${process.env.FLYWHEEL_ENDPOINT}/analytics/client`,
            data: createEvent(type)
        })
    } catch (error) {
        console.error(error)
    }
}

export default EventLogger