import { compose, defaultTo, head, match, nth, path, prop, replace } from "ramda";

const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/

export const getFwSessionIdFromDocument = compose(
    nth(1),
    match(/FW_SESSION_ID=(.*)/),
    prop('cookie')
)

export const parseWindowPodcast = compose(
    JSON.parse,
    replace(/\n/g, '\\n'),
    defaultTo(''),
    prop('__podcast')
)

export const getActiveEpisode = (window = {}) => compose(
    defaultTo(compose(
        prop('guid'),
        head,
        prop('episodes')
    )(parseWindowPodcast(window))),
    Params => Params.get('episodeGuid'),
    search => new URLSearchParams(search),
    path(['location', 'search'])
)(window)

export const getPodcastIdFromWindow = compose(
    head,
    match(uuidRegex),
    path(['location', 'href'])
)