import { createBrowserRouter } from 'react-router-dom';
import { analyticsRoutes } from "../Analytics";
import { authRoutes, protectRoutes } from '../Auth';
import { podcardRoutes } from "../Podcard";
import { podcastRoutes } from "../Podcast";
import { followallRoutes } from '../Followall';
import { customerRoutes } from '../Customer';
import { campaignRoutes } from '../Campaign';
import { promoRoutes } from '../Promo';
import layoutRoutes from "./withLayout";
import Home from './Home';
import React from 'react';
import NotFound from './NotFound';


const routes = [
    ...authRoutes,
    ...protectRoutes([{
        path: '*',
        element: <NotFound />
    }]),
    ...protectRoutes([{
        path: '/',
        element: <Home />
    }]),
    ...protectRoutes(analyticsRoutes),
    ...protectRoutes(podcardRoutes),
    ...protectRoutes(followallRoutes),
    ...protectRoutes(podcastRoutes),
    ...protectRoutes(customerRoutes),
    ...protectRoutes(campaignRoutes),
    ...protectRoutes(promoRoutes)
]

export const router = createBrowserRouter(
    layoutRoutes(routes)
)