import React from "react"
import { Row } from "antd"
import styled from "styled-components"
import { Player } from "../player"

const IframeContainer = styled(Row)`
    padding: 50px;
    margin: 0px 0px 15%; 0px;
`

export default ({
    podcast
}) => {
    return <Player podcast={podcast} />
}