

const breakPoints = {
    xs: {
        width: 576,
        comp: 'lt'
    },
    sm: {
        width: 576,
        comp: 'gte'
    },
    md: {
        width: 768,
        comp: 'gte'
    },
    lg: {
        width: 992,
        comp: 'gte'
    },
    xl: {
        width: 1200,
        comp: 'gte'
    },
    xxl: {
        width: 1600,
        comp: 'gte'
    },
}

export const createMediaQuery = ({ breakpoint = 'xs', properties = '' }) => `
@media screen and (${breakPoints[breakpoint].comp === 'gte' ? 'min-width' : 'max-width'}: ${breakPoints[breakpoint].width}px) {
    ${properties}
}
`

export const rgbToHex = ([r, g, b]) => {
    return (1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1);
}