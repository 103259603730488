import React, { useState } from "react"
import { Image } from "antd"
import QRCode from "antd/es/qr-code" // bad imports needs direct import here
import styled from "styled-components"

const CoverArt = styled(Image)`
    border-radius: 5%;
    max-width: 350px;
`

const CoverArtContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 0px 0px 0px;
    margin: ${({ xs }) => xs ? '0px 0px 25px 0px' : '0px'};
`

const EpisodeCover = ({
    podcast,
    coverUrl = '',
    width = 120,
    xs = false,
    placeholderColor = 'FFFFFF'
}) => {
    const { slug: podcastSlug } = podcast 
    const [showQrCode, setShowQrCode] = useState(false)

    const toggleCover = () => setShowQrCode(!showQrCode)

    const CoverArtImage = (
        <CoverArt preview={false} width={width} height={width} src={coverUrl} onClick={toggleCover} placeholder={
            <CoverArt
                preview={false}
                src={`https://placehold.co/${width}x${width}/${placeholderColor}/${placeholderColor}`}
                width={width}
                height={width}
            />
        } />
    )

    const CoverArtQrCode = (
        <div onClick={toggleCover}>
            <QRCode
                size={width}
                value={`${process.env.PODCARDS_URL}/${podcastSlug}`}
                icon={coverUrl}
            />
        </div>
    )

    return (
        <CoverArtContainer xs={xs}>
            { showQrCode ? CoverArtQrCode : CoverArtImage }
        </CoverArtContainer>
    )
}

export default EpisodeCover