import { createCampaign, createCampaignError, createCampaignSuccess, fetchCampaign, fetchCampaignError, fetchCampaignSuccess, fetchCampaigns, fetchCampaignsError, fetchCampaignsSuccess } from "./campaignSlice"

export const CAMPAIGN_CREATE = 'campaign:create'
export const CAMPAIGN_CREATE_SUCCESS = 'campaign:create:success'
export const CAMPAIGN_CREATE_FAILED = 'campaign:create:failed'

export const CAMPAIGN_FETCH = 'campaign:get:id'
export const CAMPAIGN_FETCH_SUCCESS = 'campaign:get:id:success'
export const CAMPAIGN_FETCH_FAILED = 'campaign:get:id:failed'

export const CAMPAIGN_FETCH_ALL = 'campaign:get'
export const CAMPAIGN_FETCH_ALL_SUCCESS = 'campaign:get:success'
export const CAMPAIGN_FETCH_ALL_FAILED = 'campaign:get:failed'

export const events = {
    [fetchCampaign().type]: CAMPAIGN_FETCH,
    [fetchCampaigns().type]: CAMPAIGN_FETCH_ALL,
    [createCampaign().type]: CAMPAIGN_CREATE
}

export const handlers = {
    [CAMPAIGN_CREATE_SUCCESS]: createCampaignSuccess,
    [CAMPAIGN_CREATE_FAILED]: createCampaignError,
    [CAMPAIGN_FETCH_SUCCESS]: fetchCampaignSuccess,
    [CAMPAIGN_FETCH_FAILED]: fetchCampaignError,
    [CAMPAIGN_FETCH_ALL_SUCCESS]: fetchCampaignsSuccess,
    [CAMPAIGN_FETCH_ALL_FAILED]: fetchCampaignsError,
}