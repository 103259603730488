import { compose, map, prop, reduce, replace, split } from "ramda"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { parseAuthenticationHash, selectUser } from "./authSlice"

const AuthResponse = () => {
    const user = useSelector(selectUser)
    const { hash } = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const getSearchParamsFromHash = compose(
        reduce((values, [key, value]) => ({ ...values, [key]: value }), {}),
        map(split('=')),
        split('&'),
        replace('?', ''),
        decodeURIComponent,
    )

    const getRedirectPath = compose(
        decodeURIComponent,
        prop('redirectPath'),
        JSON.parse,
        prop('state'),
        getSearchParamsFromHash
    )

    const navigateToRedirectPath = compose(
        navigate,
        getRedirectPath
    )

    useEffect(() => {
        if (user.email) { 
            navigateToRedirectPath(hash)
        } else {
            dispatch(parseAuthenticationHash(hash))
        }
    }, [hash, user])
}

export default AuthResponse