import React from "react";
import RegisterPodcast from "./RegisterPodcast";
import Settings from "./Settings";


export default [
    {
        path: '/podcast/new',
        element: <RegisterPodcast />
    },
    {
        path: '/:id/podcast/settings',
        element: <Settings />
    }
]