import { Col, Row } from "antd"
import React from "react"
import styled from "styled-components"
import theme from "../theme"
import withEventLogger from "./EventLogger/withEventLogger"
import { Followall } from "../followall"
import { EllipsisOutlined } from "@ant-design/icons"

const FollowContainer = styled(Row)`
    max-width: 100vw;
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`

const AppsContainer = styled(Col)`
    display: flex;
    align-items: center;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`

const FollowSection = ({
    podcast = {},
    episodeGuid = null,
    sessionId = null,
    wrapBehavior = 'scroll'
}) => {
    const { id, apps } = podcast

    return (
        <FollowContainer>
            <AppsContainer xs={{ span: 24 }}>
                <Followall
                    property='podcard'
                    podcastId={id}
                    episodeGuid={episodeGuid}
                    sessionId={sessionId}
                    followall={{ apps }}
                    iconSize="md"
                    iconType="svg"
                    margin='0px 0px 15px 15px'
                    wrapBehavior={wrapBehavior}
                />
            </AppsContainer>
        </FollowContainer>
    )
}

export default withEventLogger(FollowSection)