import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Row } from "antd"
import { v4 as uuid } from 'uuid'
import { withPodcasts } from "../Podcast"
import { compose, filter, head, map, path, prop } from "ramda"
import { withFollowall } from "../Followall"
import { withlinks } from "../Link"
import { PodcardApp } from "../../podcards"
import withLoading from "../App/withLoading"
import withEpisodes from "../Episode/withEpisodes"
import { getClipUrl, withClips } from "../Clip"


const PodcardContainer = styled.div`
    padding: 75px 25px 25px 25px;
`

const LoadingContainer = withLoading(Row)

const PodcardPreview = ({
    activeEpisodeGuid = null,
    Podcasts: { podcast = {}, fetchingPodcast },
    Episodes: { episodes = [], fetchingEpisodes },
    Followall: { followall },
    Links: { links },
    Clips: { clips }
}) => {
    const defaultActiveEpisodeGuid = compose(prop('guid'), head)(episodes)
    
    const episodeGuid = activeEpisodeGuid || defaultActiveEpisodeGuid
    
    const [episodesWithClips, setEpisodesWithClips] = useState(episodes)

    const { settings = {} } = podcast;
    const { apps = [] } = followall;
    const [ clip = {} ] = clips

    useEffect(() => {
        const episodesWithClip = map(episode => episode.id === clip.episodeId ? {...episode, clip: getClipUrl(clip) } : episode, episodes)
        
        setEpisodesWithClips(episodesWithClip)
    }, [fetchingEpisodes, clips])

    return (
        <LoadingContainer align="center" predicate={fetchingPodcast || fetchingEpisodes}>
            <PodcardContainer span={10}>
                <PodcardApp
                    podcast={{ 
                        ...podcast,
                        episodes: episodesWithClips,
                        settings,
                        apps: filter(app => path(['appLinks', `${app}Url`], podcast), apps),
                        links
                    }}
                    episodeGuid={episodeGuid}
                    sessionId={uuid()}
                    displayLogo={false}
                />
            </PodcardContainer>
        </LoadingContainer>

    )
}

export default compose(
    withPodcasts,
    withFollowall,
    withlinks,
    withEpisodes,
    withClips
)(PodcardPreview)