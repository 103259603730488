const { createSlice } = require("@reduxjs/toolkit")
const { mergeDeepLeft, path } = require("ramda")


const initialState = {
    user: {
        id: '',
        email: '',
        name: '',
        plans: []
    },
    fetchingUser: true,
    fetchingUserError: null
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        fetchUser: (state = {}) => mergeDeepLeft({
            user: {},
            fetchingUser: true,
            fetchingUserError: null
        }, state),
        fetchUserSuccess: (state = {}, action = {}) => mergeDeepLeft({
            user: action.payload,
            fetchingUser: false,
            fetchingUserError: null
        }, state),
        fetchUserError: (state = {}, action = {}) => mergeDeepLeft({
            user: initialState.user,
            fetchingUser: false,
            fetchingUserError: action.payload
        }, state),
        updateUserSuccess: (state = {}, action = {}) => mergeDeepLeft({
            user: action.payload,
            fetchingUser: false,
            fetchingUserError: null
        }, state),
    }
})

export const {
    fetchUser,
    fetchUserSuccess,
    fetchUserError
} = userSlice.actions

const selectUserPath = (props = []) => path(['user', ...props])

export const selectUser = selectUserPath(['user'])
export const selectUserFetching = selectUserPath(['fetchingUser'])
export const selectUserFetchingError = selectUserPath(['fetchingUserError'])

export default userSlice.reducer