import React from 'react'
import { Grid, Row } from 'antd'
import styled from 'styled-components'
import { find, hasPath, path } from 'ramda'
import { rgbToHex } from '../Utils/style.utils'
import EpisodeCover from '../player/Podcast/EpisodeCover'

const CoverArtConatiner = styled.div`
    display: flex;
`

const CoverArt = ({
    podcast = {},
    activeEpisodeGuid = '',
    coverSize = 300
}) => {


    const { episodes, settings: { backgroundColor = [] } } = podcast

    const episode = find(({ guid }) => guid === activeEpisodeGuid, episodes)
    const image = hasPath(['itunes', 'image'], episode) ? path(['itunes', 'image'], episode) : path(['itunes', 'image'], podcast)

    return (
        <CoverArtConatiner align="center">
            <EpisodeCover podcast={podcast} coverUrl={image} width={coverSize} placeholderColor={rgbToHex(backgroundColor)} />
        </CoverArtConatiner>
    )

}

export default CoverArt