import { all, call, put, takeEvery } from "redux-saga/effects"
import CustomerApi from "./customer.api"
import { fetchCheckoutLink, fetchCheckoutLinkError, fetchCheckoutLinkSuccess, fetchCustomerByCheckoutId, fetchCustomerError, fetchCustomerSuccess } from "./customerSlice"

export function* fetchCustomerFromCheckoutId(action = { payload: {} }) {
    const { payload: checkoutId } = action
    
    try {
        const customer = yield call(CustomerApi.fetchByCheckoutId, checkoutId)
        
        yield put(fetchCustomerSuccess(customer))
    } catch (error) {
        yield put(fetchCustomerError(error.message))
    }
}

export function* watchFetchCustomerFromCheckoutId() {
    yield takeEvery(fetchCustomerByCheckoutId().type, fetchCustomerFromCheckoutId)
}

export function* fetchCustomerCheckoutLink(action = { payload: {} }) {
    const { payload: { customerId, planId } } = action

    try {
        const customer = yield call(CustomerApi.fetchCheckoutLink, planId, customerId)
        
        yield put(fetchCheckoutLinkSuccess(customer))
    } catch (error) {
        yield put(fetchCheckoutLinkError(error.message))
    }
}

export function* watchFetchCheckoutLink() {
    yield takeEvery(fetchCheckoutLink().type, fetchCustomerCheckoutLink)
}

export default function* customerSaga() {
    yield all([
        watchFetchCustomerFromCheckoutId(),
        watchFetchCheckoutLink()
    ])
}