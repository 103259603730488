import { fetchEpisodesByProgress, fetchEpisodesByProgressError, fetchEpisodesByProgressSuccess, fetchPlayerSummary, fetchPlayerSummaryError, fetchPlayerSummarySuccess, fetchProgress, fetchProgressError, fetchProgressSuccess } from "./playerSlice"

export const ANALYTICS_PLAYER_SUMMARY_FETCH = 'analytics:player:summaries:get'
export const ANALYTICS_PLAYER_SUMMARY_FETCH_SUCCESS = 'analytics:player:summaries:get:success'
export const ANALYTICS_PLAYER_SUMMARY_FETCH_ERROR = 'analytics:player:summaries:get:failed'

export const ANALYTICS_PROGRESS_FETCH = 'analytics:progress:get'
export const ANALYTICS_PROGRESS_FETCH_SUCCESS = 'analytics:progress:get:success'
export const ANALYTICS_PROGRESS_FETCH_ERROR = 'analytics:progress:get:failed'

export const ANALYTICS_EPISODES_PROGRESS_FETCH = 'analytics:episodes:progress:get'
export const ANALYTICS_EPISODES_PROGRESS_FETCH_SUCCESS = 'analytics:episodes:progress:get:success'
export const ANALYTICS_EPISODES_PROGRESS_FETCH_ERROR = 'analytics:episodes:progress:get:failed'

export const events = {
    [fetchProgress().type]: ANALYTICS_PROGRESS_FETCH,
    [fetchEpisodesByProgress().type]: ANALYTICS_EPISODES_PROGRESS_FETCH,
    [fetchPlayerSummary().type]: ANALYTICS_PLAYER_SUMMARY_FETCH
}

export const handlers = {
    [ANALYTICS_PLAYER_SUMMARY_FETCH_SUCCESS]: fetchPlayerSummarySuccess,
    [ANALYTICS_PLAYER_SUMMARY_FETCH_ERROR]: fetchPlayerSummaryError,
    [ANALYTICS_PROGRESS_FETCH_SUCCESS]: fetchProgressSuccess,
    [ANALYTICS_PROGRESS_FETCH_ERROR]: fetchProgressError,
    [ANALYTICS_EPISODES_PROGRESS_FETCH_SUCCESS]: fetchEpisodesByProgressSuccess,
    [ANALYTICS_EPISODES_PROGRESS_FETCH_ERROR]: fetchEpisodesByProgressError
}