import React from 'react'
import { Alert } from "antd"
import { Link } from "react-router-dom"

const Notification = ({
    type = 'warning',
    alert = { action: {} },
    display = true
}) => {
    return display ? (
        <Alert
            key={alert.id}
            message={alert.message}
            description={alert.description}
            action={<Link to={alert.action.path}>{alert.action.text}</Link>}
            type={type}
            showIcon
        />
    ) : null
}

export default Notification