import { events as conversionEvents, handlers as conversionHandlers } from "./Conversion/conversionEvents";
import { events as playerEvents, handlers as playerHandlers } from "./Player/playerEvents";
import { events as sessionEvents, handlers as sessionHandlers } from "./Session/sessionEvents";
import { events as combinedEvents, handlers as combinedHandlers } from "./Combined/combinedEvents";
import { events as promoEvents, handlers as promoHandlers } from "./Promo/promoEvents";
import { events as followallEvents, handlers as followallHandlers } from "./Followall/followallEvents";

export const events = {
    ...conversionEvents,
    ...playerEvents,
    ...sessionEvents,
    ...combinedEvents,
    ...promoEvents,
    ...followallEvents
}

export const handlers = {
    ...conversionHandlers,
    ...playerHandlers,
    ...sessionHandlers,
    ...combinedHandlers,
    ...promoHandlers,
    ...followallHandlers
}