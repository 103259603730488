import { createSlice } from "@reduxjs/toolkit"
import { mergeDeepLeft, path } from "ramda"

const initialState = {
    followallSummary: {
        total_clicks: { count: 0 }
    },
    fetchingFollowallSummary: false,
    fetchingFollowallSummaryError: null,
    followallTimeseries: [[]],
    fetchingFollowallTimeseries: false,
    fetchingFollowallTimeseriesError: null,
}

export const followallSlice = createSlice({
    name: 'followallAnalytics',
    initialState,
    reducers: {
        fetchFollowallSummary: mergeDeepLeft({
            fetchingFollowallSummary: true,
            fetchingFollowallSummaryError: null
        }),
        fetchFollowallSummarySuccess: (state, action) => ({
            ...state,
            fetchingFollowallSummary: false,
            followallSummary: action.payload    
        }),
        fetchFollowallSummaryError: (state, action) => mergeDeepLeft({
            fetchingFollowallSummary: false,
            fetchingFollowallSummaryError: action.payload    
        }, state),
        fetchFollowallTimeseries: mergeDeepLeft({
            fetchingFollowallTimeseries: true,
            fetchingFollowallTimeseriesError: null
        }),
        fetchFollowallTimeseriesSuccess: (state, action) => ({
            ...state,
            fetchingFollowallTimeseries: false,
            followallTimeseries: action.payload    
        }),
        fetchFollowallTimeseriesError: (state, action) => mergeDeepLeft({
            fetchingFollowallTimeseries: false,
            fetchingFollowallTimeseriesError: action.payload    
        }, state)
    }
})

export const {
    fetchFollowallSummary,
    fetchFollowallSummarySuccess,
    fetchFollowallSummaryError,
    fetchFollowallTimeseries,
    fetchFollowallTimeseriesSuccess,
    fetchFollowallTimeseriesError,
} = followallSlice.actions

const selectFollowallPath = (props = []) => path(['followallAnalytics', ...props])

export const selectFollowallSummary = selectFollowallPath(['followallSummary'])
export const selectFetchingFollowallSummary = selectFollowallPath(['fetchingFollowallSummary'])
export const selectFetchingFollowallSummaryError = selectFollowallPath(['fetchingFollowallSummaryError'])
export const selectFollowallTimeseries = selectFollowallPath(['followallTimeseries'])
export const selectFetchingFollowallTimeseries = selectFollowallPath(['fetchingFollowallTimeseries'])
export const selectFetchingFollowallTimeseriesError = selectFollowallPath(['fetchingFollowallTimeseriesError'])

export default followallSlice.reducer