import axios from "axios"
import { BASIC_PLAN } from "./Constants"

export default {
    fetchByCheckoutId: async (checkoutId = '') => {

        const { data } = await axios({
            method: 'GET',
            url: `${process.env.FLYWHEEL_ENDPOINT}/customers/checkout-session/${checkoutId}`
        })

        return data
    },

    fetchCheckoutLink: async (planId = BASIC_PLAN, customerId = '') => {

        const { data } = await axios({
            method: 'GET',
            url: `${process.env.FLYWHEEL_ENDPOINT}/customers/${customerId}/checkout/${planId}`
        })

        return data
    }
}
