import { createSlice } from "@reduxjs/toolkit"
import { mergeDeepLeft, path } from "ramda"

const initialState = {
    playerSummary: {},
    fetchingPlayerSummary: true,
    fetchingPlayerSummaryError: null,
    progress: [],
    fetchingProgress: false,
    fetchingProgressError: null,
    episodesByProgress: [],
    fetchingEpisodesByProgress: false,
    fetchingEpisodesByProgressError: null,
}

const playerSlice = createSlice({
    name: 'player',
    initialState,
    reducers: {
        fetchPlayerSummary: mergeDeepLeft({
            fetchingPlayerSummary: true,
            fetchingPlayerSummaryError: null
        }),
        fetchPlayerSummarySuccess: (state, action) => ({
            ...state,
            fetchingPlayerSummary: false,
            playerSummary: action.payload    
        }),
        fetchPlayerSummaryError: (state, action) => mergeDeepLeft({
            fetchingPlayerSummary: false,
            fetchingPlayerSummaryError: action.payload    
        }, state),
        fetchProgress: mergeDeepLeft({
            fetchingProgress: true,
            fetchingProgressError: null
        }),
        fetchProgressSuccess: (state, action) => ({
            ...state,
            fetchingProgress: false,
            progress: action.payload    
        }),
        fetchProgressError: (state, action) => mergeDeepLeft({
            fetchingProgress: false,
            fetchingProgressError: action.payload    
        }, state),
        fetchEpisodesByProgress: mergeDeepLeft({
            fetchingEpisodesByProgress: true,
            fetchingEpisodesByProgressError: null
        }),
        fetchEpisodesByProgressSuccess: (state, action) => ({
            ...state,
            fetchingEpisodesByProgress: false,
            episodesByProgress: action.payload    
        }),
        fetchEpisodesByProgressError: (state, action) => mergeDeepLeft({
            fetchingEpisodesByProgress: false,
            fetchingEpisodesByProgressError: action.payload    
        }, state)
    }
})

export const {
    fetchPlayerSummary,
    fetchPlayerSummarySuccess,
    fetchPlayerSummaryError,
    fetchProgress,
    fetchProgressSuccess,
    fetchProgressError,
    fetchEpisodesByProgress,
    fetchEpisodesByProgressSuccess,
    fetchEpisodesByProgressError
} = playerSlice.actions

export const selectPlayerPath = (props = []) => path(['player', ...props])

export const selectPlayerSummary = selectPlayerPath(['playerSummary'])
export const selectFetchingPlayerSummary = selectPlayerPath(['fetchingPlayerSummary'])
export const selectFetchingPlayerSummaryError = selectPlayerPath(['fetchingPlayerSummaryError'])
export const selectProgress = selectPlayerPath(['progress'])
export const selectFetchingProgress = selectPlayerPath(['fetchingProgress'])
export const selectFetchingProgressError = selectPlayerPath(['fetchingProgressError'])
export const selectEpisodesByProgress = selectPlayerPath(['episodesByProgress'])
export const selectFetchingEpisodesByProgress = selectPlayerPath(['fetchingEpisodesByProgress'])
export const selectFetchingEpisodesByProgressError = selectPlayerPath(['fetchingEpisodesByProgressError'])

export default playerSlice.reducer