import { fetchConversionEpisodes, fetchConversionEpisodesError, fetchConversionEpisodesSuccess, fetchConversionReferers, fetchConversionReferersError, fetchConversionReferersSuccess, fetchConversionSummary, fetchConversionSummaryError, fetchConversionSummarySuccess, fetchConversionTimeseries, fetchConversionTimeseriesError, fetchConversionTimeseriesSuccess } from "./conversionSlice"

export const ANALYTICS_CONVERSION_SUMMARY_FETCH = 'analytics:conversion:summaries:get'
export const ANALYTICS_CONVERSION_SUMMARY_FETCH_SUCCESS = 'analytics:conversion:summaries:get:success'
export const ANALYTICS_CONVERSION_SUMMARY_FETCH_ERROR = 'analytics:conversion:summaries:get:failed'

export const ANALYTICS_CONVERSION_TIMESERIES_FETCH = 'analytics:conversion:timeseries:get'
export const ANALYTICS_CONVERSION_TIMESERIES_FETCH_SUCCESS = 'analytics:conversion:timeseries:get:success'
export const ANALYTICS_CONVERSION_TIMESERIES_FETCH_ERROR = 'analytics:conversion:timeseries:get:failed'

export const ANALYTICS_CONVERSION_REFERERS_FETCH = 'analytics:conversion:referers:get'
export const ANALYTICS_CONVERSION_REFERERS_FETCH_SUCCESS = 'analytics:conversion:referers:get:success'
export const ANALYTICS_CONVERSION_REFERERS_FETCH_ERROR = 'analytics:conversion:referers:get:failed'

export const ANALYTICS_CONVERSION_EPISODES_FETCH = 'analytics:conversion:episodes:get'
export const ANALYTICS_CONVERSION_EPISODES_FETCH_SUCCESS = 'analytics:conversion:episodes:get:success'
export const ANALYTICS_CONVERSION_EPISODES_FETCH_ERROR = 'analytics:conversion:episodes:get:failed'

export const events = {
    [fetchConversionSummary().type]: ANALYTICS_CONVERSION_SUMMARY_FETCH,
    [fetchConversionTimeseries().type]: ANALYTICS_CONVERSION_TIMESERIES_FETCH,
    [fetchConversionReferers().type]: ANALYTICS_CONVERSION_REFERERS_FETCH,
    [fetchConversionEpisodes().type]: ANALYTICS_CONVERSION_EPISODES_FETCH
}

export const handlers = {
    [ANALYTICS_CONVERSION_SUMMARY_FETCH_SUCCESS]: fetchConversionSummarySuccess,
    [ANALYTICS_CONVERSION_SUMMARY_FETCH_ERROR]: fetchConversionSummaryError,
    [ANALYTICS_CONVERSION_TIMESERIES_FETCH_SUCCESS]: fetchConversionTimeseriesSuccess,
    [ANALYTICS_CONVERSION_TIMESERIES_FETCH_ERROR]: fetchConversionTimeseriesError,
    [ANALYTICS_CONVERSION_REFERERS_FETCH_SUCCESS]: fetchConversionReferersSuccess,
    [ANALYTICS_CONVERSION_REFERERS_FETCH_ERROR]: fetchConversionReferersError,
    [ANALYTICS_CONVERSION_EPISODES_FETCH_SUCCESS]: fetchConversionEpisodesSuccess,
    [ANALYTICS_CONVERSION_EPISODES_FETCH_ERROR]: fetchConversionEpisodesError,
}