import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { AppstoreAddOutlined,BgColorsOutlined, CopyOutlined, GlobalOutlined, LinkOutlined, ScissorOutlined } from '@ant-design/icons'
import { Col, Divider, Input, message, Row, Select, Switch, Typography } from "antd"
import { identity, map, compose, evolve, prop, find, propEq, join, toPairs, pathEq } from "ramda"
import { unescapeHtml } from "../Utils"
import { Description, OptionsSectionHeader } from "../../Typography"
import UpgradeButton from "../../Components/Upgrades"
import { withCustomer } from "../Customer"
import { withAuth } from "../Auth"
import ClipUploader from "../Clip/ClipUploader"
import Links from "../Link/Links"
import { ColorSelection, withPodcasts } from "../Podcast"
import FollowallAppOrder from "../Followall/FollowallAppOrder"
import withLoading from "../App/withLoading"
import withEpisodes from "../Episode/withEpisodes"
import { getClipUrl } from "../Clip"
import PodcardUrl from "./PodcardUrl"
import { withCampaigns } from "../Campaign"
import CampaignSelect from "../Campaign/CampaignSelect"

const OptionsSection = styled(Col)`
    margin: 0px 0px 25px 0px;
`

const PodcardOptionsConatiner = styled.div`
    overflow: hidden;
    padding: 20px;
`

const LoadingPodcardOptionsConatiner = withLoading(PodcardOptionsConatiner)

const OptionsFormRow = styled(Row)`
    margin: 15px 0px;
`

const DescriptionContainer = styled(Col)`
    
`

const EpisodeSelectSwitch = styled(Switch)`
    margin: 5px 10px;
`

const PodcardOptions = ({
    Podcasts: { fetchingPodcast = true, podcast = {} },
    Episodes: { episodes = [], fetchingEpisodes = true },
    Campaigns: { campaigns = [], fetchingCampaigns = false },
    clip = {},
    onSelectEpisode = identity,
    activeEpisodeGuid = '',
    activeEpisode = '',
    onSelectCampaign = identity,
    campaignId = ''
}) => {
    const BASE_URL = `${process.env.PODCARDS_URL}/${podcast.slug}`

    const [url, setUrl] = useState(BASE_URL)
    const [episodeSelect, setEpisodeSelect] = useState(activeEpisodeGuid ? true : false)

    useEffect(() => {
        let params = [BASE_URL]

        if (activeEpisodeGuid) {
            params.push(`episodeGuid=${activeEpisodeGuid}`)
        }

        if (campaignId) {
            
            const campaign = find(pathEq(['utm', 'campaign'], campaignId))(campaigns)
            const getUtmParams = compose(join('&'), map(([k, v]) => `utm_${k}=${v}`), toPairs, prop('utm'))
            const utmParams = getUtmParams(campaign)

            params.push(utmParams)
        }

        const newUrl = params.reduce((prev, qs, i) => 
            i === 0 ? prev + qs : i > 1 ? prev + `&${qs}` : prev + `?${qs}`
        , '')

        setUrl(newUrl)

    }, [activeEpisodeGuid, campaignId, campaigns])

    const handleSelectEpisodeSwitch = () => {
        setEpisodeSelect(!episodeSelect)
        onSelectEpisode(null)
    }

    const createEpisodesSelectItems = compose(
        map((episode) => <Select.Option key={episode.guid} value={episode.guid}>{episode.title}</Select.Option>),
        map(evolve({ title: unescapeHtml }))
    )

    return (
        <LoadingPodcardOptionsConatiner predicate={fetchingPodcast || fetchingEpisodes}>
            <OptionsFormRow>
                <PodcardUrl url={url} />
                <OptionsSection span={24}>
                    <Col span={24}>
                        <Typography.Text>Select Episode</Typography.Text>
                        <EpisodeSelectSwitch checked={episodeSelect} onChange={handleSelectEpisodeSwitch} size="small" />
                    </Col>
                    <Col span={24}>
                        <Select
                            disabled={!episodeSelect}
                            allowClear={true}
                            onChange={onSelectEpisode}
                            data-testid='episode-select'
                            value={activeEpisodeGuid || null}
                            style={{ width: '100%' }}
                            placeholder='None'
                        >
                            {createEpisodesSelectItems(episodes)}
                        </Select>
                    </Col>
                </OptionsSection>
                <CampaignSelect onSelectCampaign={onSelectCampaign} campaignId={campaignId} />
                <Divider />
                <OptionsSection span={24}>
                    <Row>
                        <Col flex="auto">
                            <OptionsSectionHeader><BgColorsOutlined /> Appearance</OptionsSectionHeader>
                        </Col>
                        <Col flex='110px'>
                            <UpgradeButton type='primary' location='appearance' upgradePath='basic' />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <ColorSelection />
                        </Col>
                    </Row>
                </OptionsSection>
                <Divider />
                <OptionsSection span={24}>
                    <Row>
                        <Col flex="auto">
                            <OptionsSectionHeader><ScissorOutlined /> Clips</OptionsSectionHeader>
                        </Col>
                        <Col flex='110px'>
                            <UpgradeButton type='primary' location='clips' upgradePath='basic'/>
                        </Col>
                        <DescriptionContainer span={24}>
                            <Description>Use clips to engage your audience right away and draw them in. Pick an interesting or energetic moment from your episode and give people a reason to follow you.</Description>
                        </DescriptionContainer>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <ClipUploader episodeId={prop('id', activeEpisode)}/>
                        </Col>
                    </Row>
                    <Row style={{ display: clip.id ? '' : 'none', margin: '20px 0px 0px 0px' }}>
                        <Col span={24}>
                            { clip.id && <audio controls src={getClipUrl(clip)}><a href={getClipUrl(clip)}>Download clip</a></audio>}
                        </Col>
                    </Row>
                </OptionsSection>
                <Divider />
                <OptionsSection span={24}>
                    <Row>
                        <Col flex="auto">
                            <OptionsSectionHeader><AppstoreAddOutlined /> Apps</OptionsSectionHeader>
                            <Description>Drag and drop apps to change the order in which they appear on your Podcard</Description>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <FollowallAppOrder />
                        </Col>
                    </Row>
                </OptionsSection>                
                <Divider />
                <OptionsSection span={24}>
                    <Row>
                        <Col flex="auto">
                            <OptionsSectionHeader><LinkOutlined /> Links</OptionsSectionHeader>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Links />
                        </Col>
                    </Row>
                </OptionsSection>

            </OptionsFormRow>
        </LoadingPodcardOptionsConatiner>
    )
}

export default compose(
    withCustomer,
    withAuth,
    withPodcasts,
    withEpisodes,
    withCampaigns
)(PodcardOptions)