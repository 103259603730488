import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { createPromo, fetchPromos, selectPromo, selectPromoCreating, selectPromoCreatingError, selectPromos, selectPromosFetching, selectPromosFetchingError } from "./promoSlice"
import { compose } from "ramda"
import { withConnection } from "../Connection"

const withPromos = (Component = React.Fragment) => withConnection((props = {}) => {
    const { Connection: { connected = false }} = props
    const dispatch = useDispatch()

    const Promos = {
        promo: useSelector(selectPromo),
        promos: useSelector(selectPromos),
        creatingPromo: useSelector(selectPromoCreating),
        creatingPromoError: useSelector(selectPromoCreatingError),
        fetchingPromos: useSelector(selectPromosFetching),
        fetchingPromoError: useSelector(selectPromosFetchingError),

        dispatchCreatePromo: compose(dispatch, createPromo),
        dispatchFetchPromoss: compose(dispatch, fetchPromos),
    }

    useEffect(() => {
        const fetchedPromos = Promos.promos.length
        const isFetchingPromos = Promos.fetchingPromos

        if (connected && !fetchedPromos && !isFetchingPromos) {
            dispatch(fetchPromos())
        }

    }, [])

    return <Component {...props} Promos={Promos} />
})

export default withPromos