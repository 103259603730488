import { fetchUser, fetchUserError, fetchUserSuccess } from "./userSlice"

export const USER_FETCH = 'user:get:id'
export const USER_FETCH_SUCCESS = 'user:get:id:success'
export const USER_FETCH_FAILED = 'user:get:id:failed'

export const USER_UPDATE_SUCCESS = 'user:update:success'

export const events = {
    [fetchUser().type]: USER_FETCH
}

export const handlers = {
    [USER_FETCH_SUCCESS]: fetchUserSuccess,
    [USER_FETCH_FAILED]: fetchUserError,
    [USER_UPDATE_SUCCESS]: fetchUserSuccess
}