import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { PodcastTitle } from '../Typography';
import theme from '../theme';
import { escapeAndStripHtml } from '../Utils/string.utils';

const TitleContainer = styled.div`
    text-wrap: nowrap;
    overflow: hidden;
    white-space: nowrap;
`

const Title = styled(PodcastTitle)`
    font-size: 1rem;
    color: ${theme['text-color']};
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    justify-content: flex-start;
    transform: translateX(0);
    animation: ${({ isOverflowing = false, textWidth = 0 }) => isOverflowing ? `move-rtl ${textWidth / 30}s linear infinite`: 'none'};
    @keyframes move-rtl {
    0% {
        transform: translateX(0);
    }
    10% {
        transform: translateX(0);
    }
    90% {
        transform: ${({ textWidth = 0 }) => `translateX(-${textWidth + 50}px)`};
    }
    100% {
        transform: ${({ textWidth = 0 }) => `translateX(-${textWidth + 50}px)`};
    }
}
`

const ScrollingTitle = ({
    title = '',
    style = {}
}) => {

    const text = escapeAndStripHtml(title)

    const [isOverflowing, setIsOverflowing] = useState(false);
    const [textWidth, setTextWidth] = useState(0);
    const titleText = useRef(null)


    useEffect(() => {
        const checkOverflow = (element) => {
            if (element) {
                const isOverflowingHorizontally = element.scrollWidth > element.clientWidth;

                setIsOverflowing(isOverflowingHorizontally);
                setTextWidth(element.scrollWidth)
            }
        };

        checkOverflow(titleText.current);
    }, [title]);

    return (
        <TitleContainer>
            <Title ref={titleText} isOverflowing={isOverflowing} textWidth={textWidth} style={{ ...style }}>
                <span>{text}</span>
                {isOverflowing && <span style={{ paddingLeft: '50px' }}>{text}</span>}
            </Title>
        </TitleContainer>
    )
}

export default ScrollingTitle