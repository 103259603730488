import React from 'react'
import { Col, Select, Tooltip } from "antd"
import Typography from "antd/es/typography/Typography"
import { compose, identity, map } from "ramda"
import styled from "styled-components"
import withCampaigns from "./withCampaigns"
import { withCustomer } from '../Customer'

const OptionsSection = styled(Col)`
    margin: 0px 0px 25px 0px;
`

const CampaignSelect = ({
    Customer: { hasMarketerSubscription = false },
    Campaigns: { fetchingCampaigns = true, campaigns },
    onSelectCampaign = identity,
    campaignId = null,
    title = 'Select Campaign',
}) => {
    const createCampaignSelectItems = map((campaign) =>
        <Select.Option key={campaign.id} value={campaign.utm.campaign}>{campaign.name}</Select.Option>
    )

    return (
        <OptionsSection span={24}>
            <Col span={24}>
                <Typography.Text>{title}</Typography.Text>
            </Col>
            <Col span={24}>
                <Tooltip title='Upgrade to create campaigns' overlayStyle={{ display: hasMarketerSubscription ? 'none' : '' }}>
                    <Select
                        disabled={fetchingCampaigns || !hasMarketerSubscription}
                        allowClear={true}
                        onChange={onSelectCampaign}
                        data-testid='campaign-select'
                        value={campaignId || null}
                        style={{ width: '100%' }}
                        placeholder='None'
                    >
                        {createCampaignSelectItems(campaigns)}
                    </Select>
                </ Tooltip>
            </Col>
        </OptionsSection>
    )
}

export default compose(
    withCampaigns,
    withCustomer
)(CampaignSelect)