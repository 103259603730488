import React from 'react';
import styled from 'styled-components'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import { compose, identity, keys, map, prop, values, __ } from 'ramda';
import { withPodcasts } from '../../Podcast';
import { DATE_FORMAT } from '../Constants';
import withPlayer from './withPlayer';
import theme from '../../../theme';
import Card from '../../../Components/Card';
import { useFetchAnalytics } from '../useFetchAnalytics';
import dayjs from 'dayjs';
import withSearchParams from '../../App/withSearchParams';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

const ChartConatiner = styled.div`
    margin: 50px 0px;
`

const ChartCard = styled(Card)`
    margin: 50px 0px;
`

const ProgressChart = ({
    Player: { progress = {}, dispatchFetchProgress = identity } = {},
    Podcasts: { podcast: { id: podcastId, slug: podcastSlug } = {} } = {},
    SearchParams: {
        startDate = dayjs().subtract(7, 'day').startOf('day').format(DATE_FORMAT),
        endDate = dayjs().endOf('day').format(DATE_FORMAT),
        campaignId = null
    },
    interval = 'day',
    episodeGuid = ''
}) => {

    useFetchAnalytics({
        handler: dispatchFetchProgress,
        startDate,
        endDate,
        interval,
        resourceId: podcastId,
        resourceSlug: podcastSlug,
        episodeGuid,
        campaignId
    })

    return podcastId && (
        <ChartCard title="Playthrough" >
            <ChartConatiner>
                <Line
                    options={{
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top',
                                display: false
                            },
                            title: {
                                display: false,
                                text: 'Progress',
                            },
                            tooltip: {
                                callbacks: {
                                    label: prop('formattedValue')
                                }
                            }
                        },
                        elements: {
                            line: {
                                tension: 0.2
                            }
                        }
                    }}
                    data={{
                        labels: compose(map(n => `${n}%`), keys)(progress),
                        datasets: [{
                            fill: true,
                            label: 'Progress',
                            data: values(progress),
                            backgroundColor: theme['plays-graph-color'],
                            pointRadius: 0,
                            cubicInterpolationMode: 'monotone'
                        }]
                    }}
                />
            </ChartConatiner>
        </ChartCard>
    )
}


export default compose(
    withPlayer,
    withPodcasts,
    withSearchParams
)(ProgressChart)