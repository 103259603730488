import React from 'react';
import FollowallContainer from './FollowallContainer';


export default [
    {
        label: 'Followall',
        path: '/:id/followall',
        element: <FollowallContainer />
    }
]
