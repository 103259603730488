import { DatePicker as AntDatePicker } from "antd"
import dayjs from "dayjs"
import { compose, equals, identity, not } from "ramda"
import React from "react"
import { DATE_FORMAT } from "../Analytics/Constants"

const DatePicker = ({
    onSubmitted = identity,
    interval = 'day',
    disabled = false,
    onChange = identity,
    startDate = dayjs().startOf('day').subtract(7, 'days').format(DATE_FORMAT),
    endDate = dayjs().endOf('day').format(DATE_FORMAT),
}) => {
    const formatRanges = ([startDate, endDate]) => ([
        dayjs(startDate).startOf(interval).format(DATE_FORMAT),
        dayjs(endDate).endOf(interval).format(DATE_FORMAT)
    ])

    const handleDateSelect = compose(
        onChange,
        formatRanges
    )

    const compareRanges = compose(
        onSubmitted,
        not,
        equals([startDate, endDate]),
        formatRanges
    )

    return (
        <AntDatePicker.RangePicker
            onChange={handleDateSelect}
            onCalendarChange={compareRanges}
            defaultValue={[dayjs(startDate), dayjs(endDate)]}
            allowClear={false}
            disabledDate={date => dayjs(date).isAfter(dayjs())}
            disabled={disabled}
        />
    )
}

export default DatePicker

