import { all } from 'redux-saga/effects'
import { authSaga } from '../Auth'
import { builderSaga } from '../Builder'
import { podcastSaga } from '../Podcast'
import { customerSaga } from '../Customer'

export default function* rootSaga() {
    yield all([
        authSaga(),
        builderSaga(),
        podcastSaga(),
        customerSaga()
    ])
}