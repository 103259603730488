import React  from 'react'
import { compose, includes, intersection, isEmpty, length, lt, not, prop, propSatisfies, tap, union } from 'ramda'
import { useDispatch, useSelector } from "react-redux"
import { fetchCheckoutLink, fetchCustomer, fetchCustomerByCheckoutId, selectCheckoutLink, selectCustomer, selectCustomerFetching } from './customerSlice'

const withCustomer = (Component = React.Fragment) => (props = {}) => {
    const dispatch = useDispatch()

    const hasAnyOfPlans = plans => compose(
        not,
        isEmpty,
        intersection(plans),
        prop('plans')
    )

    const Customer = {
        customer: useSelector(selectCustomer),
        fetchingCustomer: useSelector(selectCustomerFetching),
        checkoutLink: useSelector(selectCheckoutLink),

        hasSubscription: compose(propSatisfies(compose(lt(0), length), 'plans'), useSelector)(selectCustomer),
        hasBasicSubscription: compose(hasAnyOfPlans(['basic', 'marketer']), useSelector)(selectCustomer),
        hasMarketerSubscription: compose(hasAnyOfPlans(['marketer']), useSelector)(selectCustomer),
    
        dispatchFetchCustomer: compose(dispatch, fetchCustomer),
        dispatchFetchCustomerByCheckoutId: compose(dispatch, fetchCustomerByCheckoutId),
        dispatchFetchCheckoutLink: compose(dispatch, fetchCheckoutLink)
    }

    return <Component {...props} Customer={Customer} />
}

export default withCustomer
