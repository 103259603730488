import axios from "axios"

export default {
    fetch: async (podcastId = '', episodeId = '', token = '') => {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${process.env.FLYWHEEL_ENDPOINT}/clips/${podcastId}/${episodeId}`,
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
    
            return data
        } catch (e) {
            console.error('ERR', e)
        }
    },
    upload: async (file = '', podcastId = '', episodeId = '', token = '') => {
        const form = new FormData()
        form.append('file', file)

        try {
            await axios({
                method: 'POST',
                url: `${process.env.FLYWHEEL_ENDPOINT}/clips/${podcastId}/${episodeId}/upload`,
                data: form,
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                }
            })
        } catch (error) {
            console.error(error)
        }
    }
}