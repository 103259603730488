import dayjs from "dayjs"
import { compose, identity } from "ramda"
import React, { useEffect } from "react"
import { withConnection } from "../../Connection"
import { withPodcasts } from "../../Podcast"
import { getTopEpisodeTitleByGuid, unescapeHtml } from "../../Utils"
import SummaryBox from "../Charts/SummaryBox"
import { DATE_FORMAT } from "../Constants"
import withPlayer from "./withPlayer"
import { useFetchAnalytics } from "../useFetchAnalytics"
import withEpisodes from "../../Episode/withEpisodes"
import withSearchParams from "../../App/withSearchParams"

const PlayerSummary = ({
    Player: { fetchingPlayerSummary, playerSummary, dispatchFetchPlayerSummary = identity } = {},
    Podcasts: { podcast = {} } = {},
    Episodes: { episodes = [] },
    SearchParams: {
        startDate = dayjs().subtract(7, 'day').startOf('day').format(DATE_FORMAT),
        endDate = dayjs().endOf('day').format(DATE_FORMAT),
        campaignId = null
    },
    interval = 'day'
}) => {

    const { id: podcastId, slug: podcastSlug } = podcast
    const { total: { count: playsCount = 0 } = {}, playthrough: { avg_progress = '-' } = {}, episode: { episode_guid = '-' } = {}  } = playerSummary

    useFetchAnalytics({
        handler: dispatchFetchPlayerSummary,
        startDate,
        endDate,
        interval,
        resourceId: podcastId,
        resourceSlug: podcastSlug,
        campaignId,
        limit: 1
    })

    const Statistics = [
        {
            title: 'Total Sessions',
            statistic: playsCount
        },
        {
            title: 'Average Playthrough',
            statistic: `${Math.round(avg_progress * 100, 0)}%`
        },
        {
            title: 'Top Episode',
            statistic: compose(unescapeHtml, getTopEpisodeTitleByGuid(episodes))(episode_guid)
        }        
    ]

    return (
        <SummaryBox
            Statistics={Statistics}
            loadingPredicate={fetchingPlayerSummary}
        />
    )

}

export default compose(
    withPlayer,
    withSearchParams,
    withPodcasts,
    withEpisodes
)(PlayerSummary)
