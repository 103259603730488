import React, { useEffect, useState } from 'react'
import { Button, Grid, Layout, Row } from 'antd'
import styled from 'styled-components'

import { createMediaQuery } from '../Utils/style.utils'
import Header from './Header'
import CoverArt from './CoverArt'
import { PodcastTitle } from '../Typography'
import theme from '../theme'
import { escapeAndStripHtml } from '../Utils/string.utils'
import PlayPill from './PlayPill'
import Episodes from './Episodes'
import { FollowDrawer, MoreDrawer, MenuDrawer } from './Drawer'
import ScrollingTitle from './ScrollingTitle'

const AppContent = styled(Layout.Content)`
    ${createMediaQuery({
    breakpoint: 'sm', properties: `
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    `})}
    position: relative;
`

const CoverArtContainer = styled(Row)`
    ${createMediaQuery({
    breakpoint: 'sm', properties: `
        margin-top: 20px;
    `})}
    margin-top: 40px;
`

const Container = styled.div`
    ${createMediaQuery({
    breakpoint: 'sm', properties: `
        width: 425px;
        height: 700px;
        position: relative;
        border-radius: 45px;
    `})}
    border-radius: 45px 45px 0px 0px;
    background: ${({ settings: { gradientTopColor = [] } }) => `radial-gradient(circle at center, rgba(${gradientTopColor.join(',')}, 0.4) 70%, white 100%)`};
    height: 100%;
    width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    box-shadow: ${({ settings: { gradientBottomColor = [] } }) => `0px 0px 35px 25px rgba(${gradientBottomColor.join(',')}, 1)`};
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: space-between;
`

const PodcardContainer = styled(Container)`
    background: white;
`

const HeaderContainer = styled.div`
    display: ${({ display = true }) => display ? 'block' : 'none'};
    ${createMediaQuery({
    breakpoint: 'xs', properties: `
        right: 0;
        top: 10px;
        margin: 0px 15px 0px 0px;
    `})}
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0px 0px 0px 7px;
    z-index: 1000;
`

const PodcastDeatilsContainer = styled.div`
    margin: 20px 0px 10px;
    max-width: 300px;
`

const PodcardPodcastTitle = styled(PodcastTitle)`
    font-size: 1rem;
    color: ${theme['text-color']};
    font-weight: 600;
    text-align: center;
`

const PodcastDescription = styled.p`
    font-size: 0.8rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
`

const FollowButton = styled(Button)`
    border-radius: 50px;
    font-size: 18px;
    background-color: ${({ settings: { accentColor = [] } }) => `rgba(${accentColor.join(',')}, 1)`};
    height: 50px;
    padding: 10px 60px;
    color: ${theme['bright-white']};
    border: none;
    margin: 0px 0px 10px 0px;
    :hover {
        color: ${theme['bright-white']} !important;
    }
`

const Podcard = ({
    podcast = {},
    sessionId = null,
    episodeGuid = '',
    displayLogo = true
}) => {
    const { title = '', settings = {}, description = '' } = podcast
    const [activeEpisodeGuid, setActiveEpisodeGuid] = useState(episodeGuid)
    const [openFollow, setOpenFollow] = useState(false)
    const [openMore, setOpenMore] = useState(false)
    const [openMenu, setOpenMenu] = useState(false)

    const { useBreakpoint } = Grid
    const { sm } = useBreakpoint()
    
    useEffect(() => {
        if (sessionId === null) {
            window.location.reload()
        }
    }, [sessionId])

    const handleFollowClick = () => setOpenFollow(true)
    const handleAboutClick = () => setOpenMore(true)
    const handeMenuClick = () => setOpenMenu(!openMenu)

    const coverSize = sm ? 250 : 150;

    return (
        <AppContent>
            <HeaderContainer onClick={handeMenuClick}>
                <Header podcast={podcast} />
            </HeaderContainer>
            <PodcardContainer settings={settings}>
                <Container settings={settings}>
                    <CoverArtContainer>
                        <CoverArt podcast={podcast} activeEpisodeGuid={activeEpisodeGuid} coverSize={coverSize}/>
                    </CoverArtContainer>
                    <PodcastDeatilsContainer>
                        <ScrollingTitle title={title}/>
                        <PodcastDescription dangerouslySetInnerHTML={{ __html: escapeAndStripHtml(description)}} onClick={handleAboutClick}></PodcastDescription>
                    </PodcastDeatilsContainer>
                    <FollowButton settings={settings} onClick={handleFollowClick}>Follow</FollowButton>
                    <Episodes podcast={podcast} episodeGuid={activeEpisodeGuid} setActiveEpisode={setActiveEpisodeGuid}/>
                    <PlayPill podcast={podcast} activeEpisodeGuid={activeEpisodeGuid} sessionId={sessionId}/>
                    <FollowDrawer podcast={podcast} episodeGuid={activeEpisodeGuid} setActiveEpisode={setActiveEpisodeGuid} sessionId={sessionId} open={openFollow} setOpen={setOpenFollow}/>
                    <MoreDrawer podcast={podcast} episodeGuid={activeEpisodeGuid} setActiveEpisode={setActiveEpisodeGuid} sessionId={sessionId} open={openMore} setOpen={setOpenMore}/>
                    <MenuDrawer podcast={podcast} episodeGuid={activeEpisodeGuid} sessionId={sessionId} open={openMenu} setOpen={setOpenMenu}/>
                </Container>
            </PodcardContainer>
        </AppContent>

    )
}

export default Podcard