import React from "react"
import styled from "styled-components"
import { Col, Row } from "antd"

const IframeContainer = styled(Col)`
    overflow: 'hidden';
    margin: 20px 0px 20px 0px;
`

const EmbedPreview = ({
    style= {},
    iframeSource = ''
}) => {
    return (
            <Row align="center">
                    <IframeContainer span={16}>
                        <iframe
                            allowtransparency="true"
                            title="flywheel-embed"
                            frameBorder="0"
                            scrolling="no"
                            src={iframeSource}
                            data-embed="true"
                            style={style}
                        />
                    </IframeContainer>
            </Row>

    )
}

export default EmbedPreview