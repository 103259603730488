import { Col, Row, Tooltip } from "antd"
import { compose, identity } from "ramda"
import React from "react"
import styled from "styled-components"
import { DatePicker } from "../../Utils"
import Card from "../../../Components/Card"
import { withCustomer } from "../../Customer"
import withLoading from "../../App/withLoading"
import CampaignSelect from "../../Campaign/CampaignSelect"
import withSearchParams from "../../App/withSearchParams"
import Typography from "antd/es/typography/Typography"
import dayjs from "dayjs"
import { DATE_FORMAT } from "../Constants"
import PromoSelect from "../../Promo/PromoSelect"

const OptionsCard = styled(Card)`
    margin: 0px 0px 50px 0px;
    height: 100%;
`

const Container = styled.div`
    width: 100%;
`

const Options = ({
    Customer: { hasBasicSubscription = false, fetchingCustomer = true },
    SearchParams: {
        campaignId = null,
        setSearchParamsCampaignId = identity,
        startDate = dayjs().startOf('day').subtract(7, 'days').format(DATE_FORMAT),
        setSearchParamsStartDate  = identity,
        endDate = dayjs().endOf('day').format(DATE_FORMAT),
        setSearchParamsEndDate  = identity,
        setSearchParamsStartEndDate = identity,
        setSearchParamsPromoId = identity,
        promoId = null
    },
    setSubmitDate = identity,
    disablePromos = true,
    disableCampaigns = false
}) => {
    
    const LoadingContainer = withLoading(Container)

    return (
        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} >
            <OptionsCard>
                <LoadingContainer predicate={fetchingCustomer}>
                    <Row gutter={12}>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 7 }} >
                            <Tooltip title='Upgrade to see all historical data' overlayStyle={{ display: hasBasicSubscription ? 'none' : '' }}>
                                <div>
                                    <Col span={24}>
                                        <Typography.Text>Date</Typography.Text>
                                    </Col>
                                    <DatePicker
                                        onSubmitted={setSubmitDate}
                                        interval='day'
                                        disabled={!hasBasicSubscription}
                                        onChange={setSearchParamsStartEndDate}
                                        startDate={startDate}
                                        setStartDate={setSearchParamsStartDate}
                                        endDate={endDate}
                                        setEndDate={setSearchParamsEndDate}
                                    />
                                </div>
                            </Tooltip>
                        </Col>
                        <Col xs={{ span: disableCampaigns ? 0 : 24 }} sm={{ span: disableCampaigns ? 0 : 12 }} md={{ span: disableCampaigns ? 0 : 6 }} lg={{ span: disableCampaigns ? 0 : 6 }} >
                            <CampaignSelect
                                title='Filter by campaign'
                                campaignId={campaignId}
                                onSelectCampaign={setSearchParamsCampaignId}
                            />
                        </Col>
                        <Col xs={{ span: disablePromos ? 0 : 24 }} sm={{ span: disablePromos ? 0 : 12 }} md={{ span: disablePromos ? 0 : 6 }} lg={{ span: disablePromos ? 0 : 6 }} >
                            <PromoSelect
                                title='Filter by promo'
                                promoId={promoId}
                                onSelectPromo={setSearchParamsPromoId}
                                disabled={disablePromos}
                            />
                        </Col>
                    </Row>
                </LoadingContainer>
            </OptionsCard>
        </Col>
    )
}

export default compose(
    withCustomer,
    withSearchParams
)(Options)