import React from 'react'
import AnalyticsPodcard from "./AnalyticsPodcard";
import AnalyticsSummary from './AnalyticsSummary';
import AnalyticsPromos from './AnalyticsPromos';
import AnalyticsFollowall from './AnalyticsFollowall';

export default [
    {
        label: 'Summary',
        path: '/:id/summary',
        element: <AnalyticsSummary />
    },
    {
        label: 'Podcard',
        path: '/:id/podcard/analytics',
        element: <AnalyticsPodcard />
    },
    {
        label: 'Promos',
        path: '/:id/promos/analytics',
        element: <AnalyticsPromos />
    },
    {
        label: 'Followall',
        path: '/:id/followall/analytics',
        element: <AnalyticsFollowall />
    }
]