import { createSlice } from "@reduxjs/toolkit"
import { append, compose, evolve, filter, mergeDeepLeft, path } from "ramda"
import { ALERTS } from "./Constants"

const initialState = {
    alerts: [],
}

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        createAlert: (state = {}, action = {}) => evolve({
            alerts: compose(append(ALERTS[action.payload.id](action.payload.podcastId)), filter(({ id }) => id !== action.payload.id ))
        }, state),
        clearAlert: (state = {}, action = {}) => evolve({
            alerts: filter(({ id }) => id !== action.payload.id )
        }, state)
    }
})

export const {
    createAlert,
    clearAlert
} = notificationSlice.actions

const selectNotificationPath = (props = []) => path(['notification', ...props])

export const selectAlerts = selectNotificationPath(['alerts'])

export default notificationSlice.reducer