import { compose } from "ramda"
import withCustomer from "./withCustomer"
import withLoading from "../App/withLoading"
import styled from "styled-components"
import React, { useEffect } from "react"
import { BASIC_PLAN } from "./Constants"
import { useNavigate } from "react-router-dom"
import { withPodcasts } from "../Podcast"

const Container = styled.div`
    width: 100%;
    overflow: hidden;
`

const Checkout = ({
    Customer: { customer, checkoutLink, dispatchFetchCheckoutLink },
    Podcasts: { podcast }
}) => {

    const navigate = useNavigate()

    useEffect(() => {
        if (customer.id && customer.plans.length === 0) {
            dispatchFetchCheckoutLink({ customerId: customer.id, planId: BASIC_PLAN })
        } else if (customer.id && podcast.id) {
            navigate(`/${podcast.id}/dashboard`)
        }
    }, [customer, podcast])

    useEffect(() => {
        if (checkoutLink) {
            location.href = checkoutLink
        }
    }, [checkoutLink])
    
    const LoadingContainer = withLoading(Container)

    return <LoadingContainer predicate={true} />
}

export default compose(
    withCustomer,
    withPodcasts
)(Checkout)