export const APPS = [
    'apple',
    'google',
    'spotify',
    'overcast',
    'pocketcasts',
    'podcastaddict',
    'castbox',
    'podbean',
    'iheartradio',
    'podcastrepublic',
    'castro',
    'radiopublic',
    'rss',
]