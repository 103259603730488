import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { router } from './rootRouter';
import store from './store'
import App from './App'
import { ConfigProvider } from 'antd';
import theme from '../../theme';
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://c3a55e45e78cf288242903d520e9f1bc@o4506106213695488.ingest.sentry.io/4506106215530496",
    integrations: [
        new Sentry.BrowserTracing({
            tracePropagationTargets: ["localhost", process.env.FLYWHEEL_ENDPOINT],
        }),
        new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

const app = ReactDOM.createRoot(document.getElementById('app'));

app.render(
    <ConfigProvider theme={theme.ant}>
        <Provider store={store}>
            <App>
                <RouterProvider router={router}/>
            </App>
        </Provider>
    </ConfigProvider>
);