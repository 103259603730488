import { createSlice } from "@reduxjs/toolkit"
import { mergeDeepLeft, path } from "ramda"

const initialState = {
    followall: {
        apps: []
    },
    fetchingFollowall: true,
    fetchFollowallError: null,
    unsavedChanges: false,
    updatingFollowall: false,
    updateFollowallError: null
}

export const followallSlice = createSlice({
    name: 'followall',
    initialState,
    reducers: {
        fetchFollowall: (state) => mergeDeepLeft({
            fetchingFollowall: true,
            followall: {},
            fetchFollowallError: null
        }, state),
        fetchFollowallSuccess: (state, action) => mergeDeepLeft({
            fetchingFollowall: false,
            followall: action.payload,
            fetchFollowallError: null
        }, state),
        fetchFollowallError: (state, action) => mergeDeepLeft({
            fetchingFollowall: false,
            followall: {},
            fetchFollowallError: action.payload
        }, state),
        updateFollowallSettings: (state, action) => mergeDeepLeft({
            unsavedChanges: true,
            followall: mergeDeepLeft(action.payload, state.followall)
        }, state),
        updateFollowall: (state) => mergeDeepLeft({
            unsavedChanges: false,
            updatingFollowall: true,
            updateFollowallError: null
        }, state),
        updateFollowallSuccess: (state, action) => mergeDeepLeft({
            updatingFollowall: false,
            followall: action.payload,
            updateFollowallError: null
        }, state),
        updateFollowallError: (state, action) => mergeDeepLeft({
            updatingFollowall: false,
            updateFollowallError: action.payload
        }, state),
    }
})

export const {
    fetchFollowall,
    fetchFollowallSuccess,
    fetchFollowallError,
    updateFollowallSettings,
    updateFollowall,
    updateFollowallSuccess,
    updateFollowallError
} = followallSlice.actions

const selectFollowallPath = (props = []) => path(['followall', ...props])

export const selectFollowall = selectFollowallPath(['followall'])
export const selectFetchingFollowall = selectFollowallPath(['fetchingFollowall'])
export const selectFetchFollowallError = selectFollowallPath(['fetchFollowallError'])
export const selectUnsavedChanges = selectFollowallPath(['unsavedChanges'])
export const selectUpdatingFollowall = selectFollowallPath(['updatingFollowall'])
export const selectUpdatingFollowallError = selectFollowallPath(['updateFollowallError'])

export default followallSlice.reducer

