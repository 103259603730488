import React from "react"
import utc from "dayjs/plugin/utc"
import dayjs from "dayjs"
import { compose, flatten, head, identity, last, map } from "ramda"
import { withPodcasts } from "../../Podcast"
import { DATE_FORMAT } from "../Constants"
import withSession from "./withSession"
import TimeseriesChart from "../Charts/TimeseriesChart"
import theme from "../../../theme"
import { useFetchAnalytics } from "../useFetchAnalytics"
import withSearchParams from "../../App/withSearchParams"

dayjs.extend(utc)

const SessionsTimeseriesChart = ({
    Session: { sessions = {}, dispatchFetchSessions = identity } = {},
    Podcasts: { podcast: { id: podcastId, slug: podcastSlug } = {} } = {},
    SearchParams: {
        startDate = dayjs().subtract(7, 'day').startOf('day').format(DATE_FORMAT),
        endDate = dayjs().endOf('day').format(DATE_FORMAT),
        campaignId = null
    },
    interval = 'day'
}) => {

    useFetchAnalytics({
        handler: dispatchFetchSessions,
        startDate,
        endDate,
        interval,
        resourceId: podcastId,
        resourceSlug: podcastSlug,
        campaignId
    })

    return <TimeseriesChart
        datasets={[{
            label: 'Visitors',
            data: compose(flatten, map(last))(sessions),
            backgroundColor: theme['sessions-graph-color']
        }]}
        labels={compose(flatten, map(head))(sessions)}
        tooltipLabel='Visitors'
        interval={interval}
    /> 

}

export default compose(
    withSession,
    withPodcasts,
    withSearchParams
)(SessionsTimeseriesChart)