import { createSlice } from "@reduxjs/toolkit"
import { mergeDeepLeft, path } from "ramda"

const initialState = {
    activeEpisode: null,
    feedUrl: null,
    parsingPodcast: false,
    podcast: {},
    error: null
}

export const builderSlice = createSlice({
    name: 'builder',
    initialState,
    reducers: {
        parsePodcastFeed: (state, action) => mergeDeepLeft({ fetchingPodcast: true, feedUrl: action.payload }, state),
        parsePodcastFeedSuccess: (state, action) => mergeDeepLeft({ fetchingPodcast: false, podcast: action.payload }, state),
        parsePodcastFeedFailure: (state, action) => mergeDeepLeft({ fetchingPodcast: false, error: action.payload }, state),
        setActiveEpisode: (state, action) => mergeDeepLeft({ activeEpisode: action.payload }, state),
    }
})

export const { setActiveEpisode, parsePodcastFeed, parsePodcastFeedSuccess, parsePodcastFeedFailure } = builderSlice.actions

export const selectActiveEpisode = path(['builder', 'activeEpisode'])
export const selectActiveEpisodeGuid = path(['builder', 'activeEpisode', 'guid'])
export const selectFeedUrl = path(['builder', 'feedUrl'])
export const selectPodcastParsing = path(['builder', 'parsingPodcast'])
export const selectPodcast = path(['builder', 'podcast'])
export const selectPodcastChannel = path(['builder', 'podcast', 'channel'])
export const selectPodcastEpisodes = path(['builder', 'podcast', 'episodes'])
export const selectPodcastError = path(['builder', 'error'])

export default builderSlice.reducer