import dayjs from "dayjs"
import { compose, identity } from "ramda"
import React from "react"
import { withPodcasts } from "../../Podcast"
import { getTopEpisodeTitleByGuid } from "../../Utils"
import SummaryBox from "../Charts/SummaryBox"
import { DATE_FORMAT } from "../Constants"
import withConversion from "./withConversion"
import { useFetchAnalytics } from "../useFetchAnalytics"
import withEpisodes from "../../Episode/withEpisodes"
import withSearchParams from "../../App/withSearchParams"

const ConversionSummary = ({
    Conversion: { fetchingConversionSummary, conversionSummary, dispatchFetchConversionSummary = identity } = {},
    Podcasts: { podcast = {} } = {},
    Episodes: { episodes = [] },
    SearchParams: {
        startDate = dayjs().subtract(7, 'day').startOf('day').format(DATE_FORMAT),
        endDate = dayjs().endOf('day').format(DATE_FORMAT),
        campaignId = null
    },
    interval = 'day'
}) => {

    const { id: podcastId, slug: podcastSlug } = podcast
    const {
        app_clicks: { count = 0 },
        conversion_ratios: { session_count = 0, conversion_count = 0 },
        conversions: { total_conversions = 0 },
        total_listens: { listens = 0 },
        total_listens_from_visits: { converted_listens = 0 },
     } = conversionSummary

    useFetchAnalytics({
        handler: dispatchFetchConversionSummary,
        startDate,
        endDate,
        interval,
        resourceId: podcastId,
        resourceSlug: podcastSlug,
        campaignId,
        limit: 1
    })

    const getConversionPercentage = (con, ses) => con > 0 ? `${(con / ses * 100).toFixed(2)}%` : '0%'

    const Statistics = [
        {
            title: 'Click Throughs',
            statistic: count || 0
        },
        {
            title: 'Conversions',
            statistic:  total_conversions || 0,
        },
        {
            title: 'Converted Listens',
            statistic:  converted_listens || 0,
        },
    ]

    const SubStatistics = [
        {
            title: 'Click Through Rate',
            statistic: getConversionPercentage(count, session_count)
        },
        {
            title: 'Conversion Rate',
            statistic: getConversionPercentage(conversion_count, session_count)
            ,
        },
        {
            title: 'Total Listens',
            statistic: listens || 0
        }
    ]

    return (
        <SummaryBox
            Statistics={Statistics}
            SubStatistics={SubStatistics}
            loadingPredicate={fetchingConversionSummary}
        />
    )

}

export default compose(
    withConversion,
    withPodcasts,
    withEpisodes,
    withSearchParams
)(ConversionSummary)
