import React from "react";
import { Avatar, Input, List } from "antd";
import { identity } from "ramda";
import styled from "styled-components";

const SearchBox = styled(Input.Search)`
    margin: 25px 0px;
`

const SearchResults = styled(List)`
    max-height: 250px;
    overflow-y: scroll;;
`

export const PodcastSearch = ({
    handleSearch = identity,
    handleSubmit = identity,
    submitting = false,
    searching = false,
    searchResults = []
}) => {
    return (
        <>
            <SearchBox
                placeholder='search for your podcast title'
                disabled={searching}
                onSearch={handleSearch}
            />
            <SearchResults
                itemLayout='horizontal'
                dataSource={searchResults}
                size='large'
                renderItem={(podcast, index) => (
                    <SearchResults.Item key={index} dis>
                        <SearchResults.Item.Meta
                            avatar={<Avatar shape="square" size="large" src={podcast.itunes.image} />}
                            title={podcast.title}
                            description={podcast.author}
                            onClick={() => submitting ? identity() : handleSubmit(podcast)}
                        />
                    </SearchResults.Item>
                )}
                style={{ display: searchResults.length ? 'block' : 'none' }}
            />
        </>
    )
}