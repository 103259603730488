import { identity, replace } from "ramda"
import { selectAuthenticationToken, selectUser } from "../Auth/authSlice"
import { connect, connectClosed, connectFailure, connectSuccess, selectConnectingStatus, selectConnectionStatus, selectSocket } from "./connectionSlice"
import rootEvent from "./rootEvent"
import rootHandler from "./rootHandler"
import * as Sentry from '@sentry/react'

// TODO dont mutate this state
let websocket = {
    send: identity
}

let connectionErrorCount = 0

export const connectionMiddleWare = store => next => action => {
    const state = store.getState()
    const accessToken = selectAuthenticationToken(state)
    

    const emitter = store.dispatch

    if (!connect.match(action)) return next(action)

    websocket = new WebSocket(`${process.env.WEBSOCKET_ENDPOINT}?token=${accessToken}`)

    websocket.onopen = () => {
        emitter(connectSuccess())

        // Keep the websocket open with intermittent ping
        // This prevents AWS from closing the connection
        // And forcing the client to reconnect
        setInterval(() => {
            if (websocket.readyState === 1) {
                websocket.send(JSON.stringify({
                    action: 'heartbeat',
                    type: 'heartbeat'
                }))
            }
        }, 60000)
    }

    websocket.onerror = error => {
        emitter(connectFailure(error.message || 'Connection Erorr'))
        Sentry.captureException(error)
    }

    websocket.onmessage = message => {
        const { data } = message
        const { type, body } = JSON.parse(data)
        const eventHandler = rootHandler[type]

        if (eventHandler) {
            emitter(eventHandler(body))
        }
    }

    websocket.onclose = (event) => {
        const { code } = event

        websocket = {}

        emitter(connectClosed())

        if (code === 1001 || code === 1006) {
            ++connectionErrorCount
            
            emitter(connect())

            if (connectionErrorCount >= 5) {
                location.reload()
            }
        } else {
            ++connectionErrorCount

            emitter(connectFailure(event.reason || 'Connection Erorr'))

            if (connectionErrorCount >= 5) {
                location.reload()
            }
        }
    }

    return next(action)
}

export const eventHandlerMiddleware = store => next => action => {
    const state = store.getState()
    const { sub: userId } = selectUser(state)
    const connected = selectConnectionStatus(state)

    if (!connected) return next(action)

    if (rootEvent[action.type]) {

        const event = {
            action: replace(/:/g, '.', rootEvent[action.type]),
            type: rootEvent[action.type],
            body: action.payload,
            userId
        }

        websocket.send(JSON.stringify(event))
    }

    return next(action)
}

export default connectionMiddleWare