import React, { useEffect, useState } from "react"
import { getFwSessionIdFromDocument } from "../Utils"
import EventLogger from "./EventLogger"

export default (Component = React.Fragment) => (props = {}) => {
    const [sessionId, setSessionId] = useState(null)

    useEffect(() => {
        const sessionId = getFwSessionIdFromDocument(document)
        setSessionId(sessionId)
    }, [sessionId])

    return <Component {...props} SessionEventLogger={EventLogger({ sessionId })}/> 
}
