import React, { useEffect, useState } from "react"
import { compose, identity } from "ramda"
import { Col, Image, Row, Tabs } from "antd"
import styled from 'styled-components'

import SessionsTimeseriesChart from "./Session/SessionsTimeseriesChart"
import ProgressChart from "./Player/ProgressChart"
import dayjs from "dayjs"
import ReferersChart from "./Session/ReferersChart"
import PlayerSummary from "./Player/PlayerSummary"
import SessionsSummary from "./Session/SessionsSummary"
import ConversionSummary from "./Conversion/ConversionSummary"
import withLoading from "../App/withLoading"
import { withPodcasts } from "../Podcast"
import ReferersByProgressChart from "./Session/ReferersByProgressChart"
import EpisodesByProgressChart from "./Player/EpisodesByProgressChart"
import ConversionsTimeseriesChart from "./Conversion/ConversionsTimeseriesChart"
import Options from "./Charts/Options"
import LocationsChart from "./Session/LocationsChart"
import ConversionReferersChart from "./Conversion/ConversionReferersChart"
import ConversionEpisodesChart from "./Conversion/ConversionEpisodesChart"
import Card from "../../Components/Card"
import theme from "../../theme"
import Notification from "../Notification/Notification"
import withEpisodes from "../Episode/withEpisodes"
import withSearchParams from "../App/withSearchParams"

import { NotificationConstants } from "../Notification"
const { ALERTS, ALERT_NO_PREFIX } = NotificationConstants

const Container = styled.div`
    width: 100%;
    overflow: hidden;
`

const ChartConatiner = styled.div`
    margin: 50px 0px;
`

const ChartCard = styled(Card)`
    margin: 50px 0px;
`

const SummaryCard = styled(Card)`
    margin: 0px 0px 50px 0px;
`

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ'

const LoadingContainer = withLoading(Container)

const TabGraphic = styled(Image)`
    height: 15px;
    width: 15px;
    margin: 0px 5px 0px 0px;
`

const NotificationContainer = styled(Col)`
    padding: 10px 0px;
`

const Tab = ({ title = '', elm = React.Fragment }) => (
    <React.Fragment>{elm} {title}</React.Fragment>
)

const AnalyticsPodcard = ({
    Podcasts: { podcast, fetchingPodcasts },
    Episodes: { hasPrefixInstalled },
    SearchParams: {
        tab = 'awareness',
        setSearchParamsTab = identity,
        startDate = dayjs().startOf('day').subtract(7, 'days').format(DATE_FORMAT),
        endDate = dayjs().endOf('day').format(DATE_FORMAT)
    }
}) => {
    const [alert, setAlert] = useState(null)
    
    useEffect(() => {
        const alert = hasPrefixInstalled ? null :ALERTS[ALERT_NO_PREFIX](podcast.id)

        setAlert(alert)
    }, [podcast, hasPrefixInstalled])

    const interval = dayjs(startDate).diff(endDate, 'day') === 0 ? 'hour' : 'day'

    return (
        <LoadingContainer predicate={fetchingPodcasts}>
            <Options disablePromos={tab !== 'promos'} />
            <Row justify="center">
                <NotificationContainer span={24}>
                    <Notification display={alert} alert={alert} />
                </NotificationContainer>
                <Col span={24}>
                    <Tabs defaultActiveKey="awareness" activeKey={tab} onChange={setSearchParamsTab} 
                        tabBarStyle={{
                            borderRadius: '10px',
                            marginBottom: '20px',
                            padding: '20px',
                            background: theme['bright-white'],
                        }}
                    >
                        <Tabs.TabPane tab={<Tab title="Awareness" />} key='awareness'>
                            <Row>
                                <Col span={24}>
                                    <SessionsSummary interval={interval} />
                                </Col>
                                <Col span={24}>
                                    <ChartCard size="default" title="Visitors">
                                        <ChartConatiner>
                                            <SessionsTimeseriesChart
                                                startDate={startDate}
                                                endDate={endDate}
                                                interval={interval}
                                            />
                                        </ChartConatiner>
                                    </ChartCard>
                                </Col>
                                <Col span={11}>
                                    <ReferersChart
                                        startDate={startDate}
                                        endDate={endDate}
                                        interval={interval}
                                    />
                                </Col>
                                <Col span={11} offset={2}>
                                    <LocationsChart
                                        startDate={startDate}
                                        endDate={endDate}
                                        interval={interval}
                                    />
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<Tab title="Engagement" />} key='engagement'>
                            <Row>
                                <Col span={24}>
                                    <PlayerSummary
                                        startDate={startDate}
                                        endDate={endDate}
                                        interval={interval}
                                    />
                                </Col>
                                <Col span={24}>
                                    <ProgressChart
                                        startDate={startDate}
                                        endDate={endDate}
                                        interval={interval}
                                    />
                                </Col>
                                <Col span={11}>
                                    <ReferersByProgressChart
                                        startDate={startDate}
                                        endDate={endDate}
                                        interval={interval}
                                    />
                                </Col>
                                <Col span={11} offset={2}>
                                    <EpisodesByProgressChart
                                        startDate={startDate}
                                        endDate={endDate}
                                        interval={interval}
                                    />
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<Tab title="Conversion" />} key='conversion'>
                            <Row>
                                <Col span={24}>
                                    <ConversionSummary
                                        startDate={startDate}
                                        endDate={endDate}
                                        interval={interval}
                                    />
                                </Col>
                                <Col span={24}>
                                    <ChartCard size="default" title="Conversions">
                                        <ChartConatiner>
                                            <ConversionsTimeseriesChart
                                                startDate={startDate}
                                                endDate={endDate}
                                                interval={interval}
                                            />
                                        </ChartConatiner>
                                    </ChartCard>
                                </Col>
                                <Col span={11}>
                                    <ConversionReferersChart
                                        startDate={startDate}
                                        endDate={endDate}
                                        interval={interval}
                                    />
                                </Col>
                                <Col span={11} offset={2}>
                                    <ConversionEpisodesChart
                                        startDate={startDate}
                                        endDate={endDate}
                                        interval={interval}
                                    />
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
            </Row>
        </LoadingContainer>
    )
}

export default compose(
    withPodcasts,
    withEpisodes,
    withSearchParams
)(AnalyticsPodcard)