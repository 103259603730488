import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFollowall, selectFetchFollowallError, selectFetchingFollowall, selectFollowall, selectUnsavedChanges, selectUpdatingFollowall, selectUpdatingFollowallError, updateFollowall, updateFollowallSettings } from './followallSlice';
import { compose } from 'ramda';

const withFollowall = (Component = React.Fragment) => (props = {}) => {
    const dispatch = useDispatch()

    const Followall = {
        followall: useSelector(selectFollowall),
        fetchingFollowall: useSelector(selectFetchingFollowall),
        fetchFollowallError: useSelector(selectFetchFollowallError),
        unsavedChanges: useSelector(selectUnsavedChanges),
        updatingFollowall: useSelector(selectUpdatingFollowall),
        updateFollowallError: useSelector(selectUpdatingFollowallError),


        dispatchFetchFollowall: compose(dispatch, fetchFollowall),
        dispatchUpdateFollowallSettings: compose(dispatch, updateFollowallSettings),
        dispatchUpdateFollowall: compose(dispatch, updateFollowall),
    }

    return <Component {...props} Followall={Followall} />
}

export default withFollowall