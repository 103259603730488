import { Col, Image } from 'antd'
import { hasPath, identity, path } from 'ramda'
import React from 'react'
import styled from 'styled-components'
import theme from '../theme'
import dayjs from 'dayjs'
import { escapeAndStripHtml } from '../Utils/string.utils'
import { createMediaQuery } from '../Utils/style.utils'

const EpisodesContainer = styled.div`
    linear-gradient(#ff8a00, #e52e71)
    display: flex;
    flex-direction: column;
    padding: 20px 10px 90px 10px;
    margin: 0px 0px 10px 0px;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-bar-width: none;
    -ms-overflow-style: none;
    min-height: 220px;
    ::-webkit-scrollbar {
        display: none;
    }
`

const EpisodeContainer = styled.div`
    ${createMediaQuery({
    breakpoint: 'md', properties: `
        min-width: 365px;
    `})}
    display: flex;
    flex-direction: row;
    height: 75px;
    padding: 10px;
    margin: 0px 10px;
    border-radius: 10px;
    color: ${theme['bright-white']};
    font-weight: 600;
    background: ${({ isSelected = false, settings: { accentColor = [] } }) => isSelected ? `rgba(${accentColor.join(',')}, 0.2)` : `none` };
`

const EpisodeCoverContainer = styled.div`
    margin: 0px 10px 0px 0px;
`

const EpisodeCover = styled(Image)`
    border-radius: 5px;
    max-width: 50px;
    box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.15);
`

const EpisodeTitleContainer = styled.div`
    ${createMediaQuery({
    breakpoint: 'md', properties: `
        min-width: 308px;
    `})}
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const EpisodeMeta = styled.div`
    display: flex;
    color: ${theme["primary-black"]}
`

const EpisodeTitle = styled.p`
    margin: 0px;
    font-weight: 300;
    line-height: 1.1rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${theme["primary-black"]}
`

const EpisodeMetadata = styled(EpisodeTitle)`
    font-size: 0.75rem;
    margin-right: 5px;
`

const Episode = ({
    podcast = {},
    episode = {},
    handleClick = identity,
    isSelected = false
}) => {
    const { settings = {} } = podcast
    const image = hasPath(['itunes', 'image'], episode) ? path(['itunes', 'image'], episode) : path(['itunes', 'image'], podcast)
    const duration = path(['itunes', 'duration'], episode)

    return (
        <EpisodeContainer settings={settings} key={episode.title} onClick={handleClick} isSelected={isSelected}>
            <EpisodeCoverContainer>
                <EpisodeCover height={50} width={50} src={image} preview={false} />
            </EpisodeCoverContainer>
            <EpisodeTitleContainer span={18}>
                <EpisodeTitle>{escapeAndStripHtml(episode.title)}</EpisodeTitle>
                <EpisodeMeta>
                    <EpisodeMetadata>{dayjs(episode.pubdate).format('MMM DD YYYY')}</EpisodeMetadata> <EpisodeMetadata>•</EpisodeMetadata> <EpisodeMetadata>{duration}</EpisodeMetadata>
                </EpisodeMeta>
            </EpisodeTitleContainer>
        </EpisodeContainer>    
    )
}

const Episodes = ({
    podcast = {},
    episodeGuid = '',
    setActiveEpisode = identity
}) => {
    const { episodes = [], settings = {} } = podcast

    return (
        <EpisodesContainer gutter={16} align="middle" settings={settings} wrap={false}>
            {episodes.map(episode => (
                <Episode
                    episode={episode}
                    podcast={podcast}
                    isSelected={episodeGuid === episode.guid}
                    handleClick={() => setActiveEpisode(episode.guid)}
                />
            ))}
        </EpisodesContainer>
    )
}

export default Episodes