import { compose } from "ramda"
import React from "react"
import SummaryBox from "../Charts/SummaryBox"
import withSearchParams from "../../App/withSearchParams"
import PromoAnalytics from "./withPromoAnalytics"

const PromoSummary = ({
    PromoAnalytics: { fetchingPromoSummary, promoSummary } = {},
}) => {

    const {
        total_conversions,
        impressions,
        converted_listens
     } = promoSummary

    const getConversionPercentage = (con, ses) => con > 0 ? `${(con / ses * 100).toFixed(2)}%` : '0%'

    const Statistics = [
        {
            title: 'Impressions',
            statistic: impressions.count || 0
        },
        {
            title: 'Reach',
            statistic:  impressions.reach || 0,
        },
        {
            title: 'Frequency',
            statistic:  (impressions.count / impressions.reach || 0).toFixed(2),
        },
    ]

    const SubStatistics = [
        {
            title: 'Conversions',
            statistic: total_conversions.count || 0
        },
        {
            title: 'Converted Listens',
            statistic: converted_listens.count || 0
        },
        {
            title: 'Conversion Rate',
            statistic: getConversionPercentage(total_conversions.count, impressions.count)
        }
    ]

    return (
        <SummaryBox
            Statistics={Statistics}
            SubStatistics={SubStatistics}
            loadingPredicate={fetchingPromoSummary}
        />
    )

}

export default compose(
    PromoAnalytics,
    withSearchParams
)(PromoSummary)
