import React from 'react'
import { compose, prop } from "ramda"
import withPromos from "./withPromos"
import Card from '../../Components/Card'
import styled from 'styled-components'
import { Table } from 'antd'
import { Link } from 'react-router-dom'
import { withPodcasts } from '../Podcast'
import { PodCardsButton } from '../../Typography'
import PromoUrl from './PromoUrl'
import { StockOutlined } from '@ant-design/icons'

const PromosCard = styled(Card)``

const Promos = ({
    Podcasts: {
        podcast = {},
        podcasts = []
    },
    Promos: {
        promos = []
    }
}) => {

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name > b.name
        },
        {
            title: 'Podcast',
            dataIndex: ['podcastId'],
            key: 'podcast',
            render: compose(title => <p>{title}</p>, prop('title'), podcastId => podcasts.find(({ id }) => id === podcastId))
        },
        {
            title: 'Provider',
            dataIndex: 'provider',
            key: 'provider',
            sorter: (a, b) => a.provider > b.provider
        },
        {
            title: 'Tracking Code',
            dataIndex: 'trackingUrl',
            key: 'trackingUrl',
            render: text => <PromoUrl url={text} />
        },
        {
            key: 'analytics',
            render: (_, promo) => (
                <div>
                    <Link to={`/${podcast.id}/promos/analytics?promoId=${promo.id}`}>
                        <StockOutlined />
                    </Link>
                </div>
            )

        }
    ]

    const NewPromo = (
        <Link to={'new'}>
            <PodCardsButton>New Promo</PodCardsButton>
        </Link>
    )

    return (
        <PromosCard extra={NewPromo}>
            <Table columns={columns} dataSource={promos} />
        </PromosCard>
    )

}

export default compose(
    withPodcasts,
    withPromos
)(Promos)