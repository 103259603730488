import React  from 'react'
import { useDispatch, useSelector } from "react-redux"
import { selectLinks, selectFetchingLinks, selectFetchingLinksError, selectCreateLink, selectCreateLinkError, getLinks, createLink, deleteLink, selectCreatingLink, selectDeletingLink } from "./linkSlice"
import { compose } from 'ramda'

const withLinks = (Component = React.Fragment) => (props = {}) => {
    const dispatch = useDispatch()

    const Links = {
        links: useSelector(selectLinks),
        fetchingLinks: useSelector(selectFetchingLinks),
        fetchingLinksError: useSelector(selectFetchingLinksError),
        creatingLink: useSelector(selectCreatingLink),
        deletingLink: useSelector(selectDeletingLink),
    
        dispatchGetLinks: compose(dispatch, getLinks),
        dispatchCreateLink: compose(dispatch, createLink),
        dispatchDeleteLink: compose(dispatch, deleteLink)
    }

    return <Component {...props} Links={Links} />
}

export default withLinks