import { combineReducers } from "@reduxjs/toolkit";
import { analyticsReducers } from "../Analytics";
import { authReducer } from "../Auth";
import { builderReducer } from "../Builder";
import { connectionReducer } from "../Connection";
import { podcastReducer } from "../Podcast";
import { followallReducer } from "../Followall";
import { clipReducer } from "../Clip";
import { customerReducer } from "../Customer";
import { userReducer } from "../User";
import { linkReducer } from "../Link";
import { episodeReducer } from "../Episode";
import { notificationReducer } from "../Notification";
import { campaignReducer } from "../Campaign";
import { promoReducer  } from "../Promo";

const rootReducer = combineReducers({
    auth: authReducer,
    connection: connectionReducer,
    builder: builderReducer,
    podcast: podcastReducer,
    followall: followallReducer,
    clip: clipReducer,
    customer: customerReducer,
    user: userReducer,
    link: linkReducer,
    episode: episodeReducer,
    notification: notificationReducer,
    campaign: campaignReducer,
    promo: promoReducer,
    ...analyticsReducers
})

export default rootReducer