import { createSlice } from '@reduxjs/toolkit';
import { append, filter, mergeDeepLeft, path, prepend, reduce } from 'ramda';

const initialState = {
    links: [],
    getLinks: false,
    getLinksError: null,
    creatingLink: false,
    creatingLinkError: null,
    deleteLink: false,
    deletingLink: false,
    deletingLinkError: null
};

const linkSlice = createSlice({
    name: 'link',
    initialState,
    reducers: {
        getLinks: (state) => mergeDeepLeft({
            fetchingLinks: true,
            links: [],
            getLinksError: null
        }, state),
        getLinksSuccess: (state, action) => mergeDeepLeft({
            fetchingLinks: false,
            links: action.payload,
            getLinksError: null
        }, state),
        getLinksError: (state, action) => mergeDeepLeft({
            fetchingLinks: false,
            links: [],
            getLinksError: action.payload
        }, state),
        createLink: (state, action) => mergeDeepLeft({
            creatingLink: true,
            // links: append(action.payload, state.links),
            createLinkError: null
        }, state),
        createLinkSuccess: (state, action) => mergeDeepLeft({
            creatingLink: false,
            links: append(action.payload, state.links),
            createLinkError: null
        }, state),
        createLinkError: (state, action) => mergeDeepLeft({
            creatingLink: false,
            createLinkError: action.payload
        }, state),
        deleteLink: (state, action) => mergeDeepLeft({
            deletingLink: true,
            deleteLinkError: null
        }, state),
        deleteLinkSuccess: (state, action) => mergeDeepLeft({
            deletingLink: false,
            links: filter(link => link.id !== action.payload.id, state.links),
            deleteLinkError: null
        }, state),
        deleteLinkError: (state, action) => mergeDeepLeft({
            deletingLink: false,
            deleteLinkError: action.payload
        }, state),
    },
});

export const {
    getLinks,
    getLinksSuccess,
    getLinksError,
    createLink,
    createLinkSuccess,
    createLinkError,
    deleteLink,
    deleteLinkSuccess,
    deleteLinkError
} = linkSlice.actions;

const selectLinksPath = (props = []) => path(['link', ...props])

export const selectLinks = selectLinksPath(['links'])
export const selectFetchingLinks = selectLinksPath(['fetchingLinks'])
export const selectFetchingLinksError = selectLinksPath(['fetchingLinksError'])
export const selectCreatingLink = selectLinksPath(['creatingLink'])
export const selectCreateLinkError = selectLinksPath(['createLinkError'])
export const selectDeletingLink = selectLinksPath(['deletingLink'])
export const selectDeleteLinkError = selectLinksPath(['deleteLinkError'])

export default linkSlice.reducer;

