import { assoc, compose, dissoc, filter, isEmpty, map, reduce, tap } from "ramda";

// undefinedFilter Object -> Object
export const undefinedFilter = compose(
    filter(x => x && !isEmpty(x)),
    map(x => typeof x === 'object' && !Array.isArray(x) && x !== null && x instanceof Date !== true
        ? undefinedFilter(x) 
        : x
    ),
)

export const getNewSearch = compose(
    undefinedFilter,
    reduce((searchObject = {}, item = {}) =>
        item.value ? assoc(item.key, item.value, searchObject) : dissoc(item.key, searchObject)
    , {}),
)