import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { createCampaign, fetchCampaign, fetchCampaigns, selectCampaign, selectCampaignCreating, selectCampaignCreatingError, selectCampaignFetching, selectCampaignFetchingError, selectCampaigns, selectCampaignsFetching, selectCampaignsFetchingError } from "./campaignSlice"
import { compose } from "ramda"
import { withConnection } from "../Connection"

const withCampaigns = (Component = React.Fragment) => withConnection((props = {}) => {
    const { Connection: { connected = false }} = props
    const dispatch = useDispatch()

    const Campaigns = {
        campaign: useSelector(selectCampaign),
        campaigns: useSelector(selectCampaigns),
        creatingCampaign: useSelector(selectCampaignCreating),
        creatingCampaignError: useSelector(selectCampaignCreatingError),
        fetchingCampaign: useSelector(selectCampaignFetching),
        fetchingCampaignError: useSelector(selectCampaignFetchingError),
        fetchingCampaigns: useSelector(selectCampaignsFetching),
        fetchingCampaignError: useSelector(selectCampaignsFetchingError),

        dispatchCreateCampaign: compose(dispatch, createCampaign),
        dispatchFetchCampaign: compose(dispatch, fetchCampaign),
        dispatchFetchCampaigns: compose(dispatch, fetchCampaigns),
    }

    useEffect(() => {
        const fetchedCampaigns = Campaigns.campaigns.length
        const isFetchingCampaigns = Campaigns.fetchingCampaigns

        if (connected && !fetchedCampaigns && !isFetchingCampaigns) {
            dispatch(fetchCampaigns())
        }

    }, [connected])

    return <Component {...props} Campaigns={Campaigns} />
})

export default withCampaigns