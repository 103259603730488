import { Col, Card } from 'antd'
import React from 'react'
import PromoSummary from './Promo/PromoSummary'
import styled from 'styled-components'
import { compose } from 'ramda'
import withSearchParams from '../App/withSearchParams'
import { DATE_FORMAT } from './Constants'
import PromoTimeseriesChart from './Promo/PromoTimeseriesChart'
import Options from './Charts/Options'

const Container = styled.div`
    width: 100%;
    overflow: hidden;
`

const ChartConatiner = styled.div`
    margin: 50px 0px;
`

const ChartCard = styled(Card)`
    margin: 50px 0px;
`

const AnalyticsPromos = ({
    SearchParams: {
        startDate = dayjs().startOf('day').subtract(7, 'days').format(DATE_FORMAT),
        endDate = dayjs().endOf('day').format(DATE_FORMAT)
    }
}) => {
    const interval = 'day'

    return (
        <Container>
            <Options disablePromos={false} disableCampaigns={true}/>
            <Col span={24}>
                <PromoSummary
                    startDate={startDate}
                    endDate={endDate}
                    interval={interval}
                />
            </Col>
            <Col span={24}>
                <ChartCard size="default" title="Promos">
                    <ChartConatiner>
                        <PromoTimeseriesChart
                            startDate={startDate}
                            endDate={endDate}
                            interval={interval}
                        />
                    </ChartConatiner>
                </ChartCard>
            </Col>
        </Container>
    )
}

export default compose(
    withSearchParams
)(AnalyticsPromos)