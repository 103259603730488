import dayjs from 'dayjs'
import { Alert, Col, Image, Row, Typography } from "antd"
import React, { useEffect, useState } from "react"
import withPodcasts from './withPodcasts'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import theme from '../../theme'
import withLoading from '../App/withLoading'
import { PodcastSearch } from '../../Components/PodcastSearch'
import { compose } from 'ramda'
import withConnection from '../Connection/withConnection'
import { SmallBodyText } from '../../Typography'
import withSearchParams from '../App/withSearchParams'

const RegisterContainer = styled(Row)`
    margin-top 10%;
`

const RegisterBox = styled(Col)`
    padding: 50px 25px;
    background-color: ${theme['bright-white']};
    border-radius: ${theme['border-radius-base']};
    box-shadow: ${theme['card-shadow']};
`

const RegisterTitle = styled(Typography.Title)`
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
`

const LoadingRegistrationContainer = styled(Row)`
`

const LoadingRegistrationContent = styled(Col)`
    text-align: center;
`

const LoadingRegistrationImage = styled(Image)`
    margin: 20px 0px;
`

const LoadingRegistrationSpinnerContainer = styled.div`
    margin: 20px 0px;
`

const LoadingRegistration = ({
    image = ''
}) => {
    const Spinner = withLoading(React.Fragment)

    return (
        <LoadingRegistrationContainer align='middle' justify='center'>
            <LoadingRegistrationContent xs={{ span: 24 }} md={{ span: 18 }}>
                <LoadingRegistrationImage src={image} preview={false} width={200}/>
                <SmallBodyText>We're getting everything setup for you. This should take just a few minutes 🎉</SmallBodyText>
                <LoadingRegistrationSpinnerContainer>
                    <Spinner predicate={true} />
                </LoadingRegistrationSpinnerContainer>
            </LoadingRegistrationContent>
        </LoadingRegistrationContainer>
    )
}

const RegisterPodcast = ({
    SearchParams: { partner },
    Connection: {
        connected
    },
    Podcasts: {
        searchingPodcast,        
        dispatchSearchPodcast,
        searchingPodcastResults,
        registeringPodcast,
        registeringPodcastError,
        dispatchRegisterPodcast
    }
}) => {
    const navigate = useNavigate()
    const [ searchParams ] = useSearchParams()

    const [submitted, setSubmitted] = useState(false)
    const [feedUrl, setFeedUrl] = useState(decodeURIComponent(searchParams.get('feedUrl')))
    const [title, setTitle] = useState(decodeURIComponent(searchParams.get('title')))
    const [image, setImage] = useState(decodeURIComponent(searchParams.get('image')))

    useEffect(() => {
        if (feedUrl !== 'null' && connected) {
            handleSubmit({ itunes: { image }, title, feedUrl })
        } 
    }, [connected])

    useEffect(() => {
        if (!registeringPodcast && submitted && !registeringPodcastError) {
            navigate('/')
        }
    }, [registeringPodcast, submitted])

    const handleSearch = (query) => {
        dispatchSearchPodcast({ q: query })
    }

    const handleSubmit = (podcast) => {
        const { feedUrl, title, itunes: { image } } = podcast
        
        setTitle(title)
        setImage(image)
        setSubmitted(true)

        dispatchRegisterPodcast({ feedUrl, lastCheck: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS'), partner })
    }

    const SearchResultsWithLoading = withLoading(PodcastSearch)

    const header = registeringPodcast ? 'Welcome!' : 'Search for your show'

    const RegisterWithLoading = withLoading(Col)

    return (
        <RegisterContainer align="middle" justify="space-around">
            <RegisterBox xs={{ span: 24 }} md={{ span: 18 }} lg={{ span: 16 }}>
                <Col span={24}>
                    <RegisterTitle>{header}</RegisterTitle>
                    <Alert message={registeringPodcastError} type="error" style={{ display: !registeringPodcastError && 'none' }} />
                </Col>
                <RegisterWithLoading predicate={!connected} xs={{ span: 24 }} md={{ span: 16, push: 4 }}>
                    {registeringPodcast ? <LoadingRegistration title={title} image={image} /> : <SearchResultsWithLoading
                        predicate={registeringPodcast}
                        handleSearch={handleSearch}
                        handleSubmit={handleSubmit}
                        submitting={registeringPodcast}
                        searching={searchingPodcast}
                        searchResults={searchingPodcastResults}
                    />}
                </RegisterWithLoading>
            </RegisterBox>
        </RegisterContainer>
    )
}

export default compose(
    withPodcasts,
    withConnection,
    withSearchParams
)(RegisterPodcast)