import { createGlobalStyle } from 'styled-components'

import NeueHaasRoman from './NeueHaasDisplayRoman.ttf'

export default createGlobalStyle`
    @font-face {
        font-family: 'NeueHaasRoman';
        src: local('Neue Haas Roman'), local('NeueHaasRoman'),
        url(${NeueHaasRoman}) format('ttf'),
        font-weight: 400;
        font-style: normal;
    }
    * {
        font-family: 'NeueHaasRoman', sans-serif;
    }
`