import dayjs from "dayjs"
import { compose, flatten, map, prop } from "ramda"
import React from "react"
import styled from "styled-components"
import { withPodcasts } from "../../Podcast"
import RankedChart from "../Charts/RankedChart"
import { DATE_FORMAT } from "../Constants"
import withSession from "./withSession"
import Card from "../../../Components/Card"
import theme from "../../../theme"
import { useFetchAnalytics } from "../useFetchAnalytics"
import withSearchParams from "../../App/withSearchParams"

const ChartCard = styled(Card)`
    margin: 50px 0px;
`

const LocationsChart = ({
    Session: { locations = [], dispatchFetchLocations = identity } = {},
    Podcasts: { podcast: { id: podcastId, slug: podcastSlug } = {} } = {},
    SearchParams: {
        startDate = dayjs().subtract(7, 'day').startOf('day').format(DATE_FORMAT),
        endDate = dayjs().endOf('day').format(DATE_FORMAT),
        campaignId = null
    },
    interval = 'day',
    episodeGuid = ''
}) => {

    useFetchAnalytics({
        handler: dispatchFetchLocations,
        startDate,
        endDate,
        interval,
        resourceId: podcastId,
        resourceSlug: podcastSlug,
        episodeGuid,
        campaignId,
        limit: 5
    })

    return (
        <ChartCard title="Top Visitor Locations">
                <RankedChart
                    labels={compose(flatten, map(prop('country_name')))(locations)}
                    datasets={[{
                        label: 'Visitors',
                        data: compose(flatten, map(prop('count')))(locations),
                        backgroundColor: theme['sessions-graph-color']
                    }]}
                />
        </ChartCard>
    )
}

export default compose(
    withSession,
    withPodcasts,
    withSearchParams
)(LocationsChart)