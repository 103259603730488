import React from 'react'
import styled from "styled-components";
import { escapeHtml } from "../Utils/string.utils";
import theme from '../theme';
import { PodcastTitle } from '../Typography';

const PodcastDescriptionContainer = styled.div`
    padding: 0px 20px 0px 20px;
    max-width: 100vw;
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`

const PodcastDescription = styled.p`
    color: ${theme['bright-white']};
    font-size: 0.8rem;
    a {
        color: ${theme['bright-white']};
        text-decoration: underline;
    }
`

const About = ({
    podcast = {}
}) => {
    return (
        <PodcastDescriptionContainer>
            <PodcastDescription dangerouslySetInnerHTML={{ __html: escapeHtml(podcast.description) }} ></PodcastDescription>
        </PodcastDescriptionContainer>
    )
}

export default About;