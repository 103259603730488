import { getLinks, getLinksError, getLinksSuccess, createLinkError, createLinkSuccess, createLink, deleteLink, deleteLinkSuccess, deleteLinkError } from "./linkSlice"

export const LINK_GET = 'link:get:podcast:id'
export const LINK_GET_SUCCESS = 'link:get:podcast:id:success'
export const LINK_GET_FAILED = 'link:get:podcast:id:failed'

export const LINK_CREATE = 'link:create'
export const LINK_CREATE_SUCCESS = 'link:create:success'
export const LINK_CREATE_FAILED = 'link:create:failed'

export const LINK_DELETE = 'link:delete'
export const LINK_DELETE_SUCCESS = 'link:delete:success'
export const LINK_DELETE_FAILED = 'link:delete:failed'

export const events = {
    [getLinks().type]: LINK_GET,
    [createLink().type]: LINK_CREATE,
    [deleteLink().type]: LINK_DELETE
}

export const handlers = {
    [LINK_GET_SUCCESS]: getLinksSuccess,
    [LINK_GET_FAILED]: getLinksError,
    [LINK_CREATE_SUCCESS]: createLinkSuccess,
    [LINK_CREATE_FAILED]: createLinkError,
    [LINK_DELETE_SUCCESS]: deleteLinkSuccess,
    [LINK_DELETE_FAILED]: deleteLinkError
}