import { createSlice } from '@reduxjs/toolkit';
import { compose, last, mergeDeepLeft, path, prepend, prop } from 'ramda';

const initialState = {
    clips: [],
    listingClips: false,
    listingClipsError: null,
    file: null,
    uploadingClip: false,
    uploadingClipError: null,
};

const clipSlice = createSlice({
    name: 'clip',
    initialState,
    reducers: {
        listClips: (state) => mergeDeepLeft({
            listingClips: true,
            clips: [],
            listClipsError: null
        }, state),
        listClipsSuccess: (state, action) => mergeDeepLeft({
            listingClips: false,
            clips: action.payload,
            listClipsError: null
        }, state),
        listClipsError: (state, action) => mergeDeepLeft({
            listingClips: false,
            clips: [],
            listClipsError: action.payload
        }, state),
        uploadClip: (state) => mergeDeepLeft({
            uploadingClip: true,
            uploadClipError: null
        }, state),
        uploadClipSuccess: (state, action) => mergeDeepLeft({
            uploadingClip: false,
            clips: prepend(action.payload, state.clips),
            uploadClipError: null
        }, state),
        uploadClipError: (state, action) => mergeDeepLeft({
            uploadingClip: false,
            uploadClipError: action.payload
        }, state),
    },
});

export const {
    listClips,
    listClipsSuccess,
    listClipsError,
    uploadClip,
    uploadClipSuccess,
    uploadClipError
} = clipSlice.actions;

const selectClipsPath = (props = []) => path(['clip', ...props])

export const selectClips = selectClipsPath(['clips'])
export const selectlistingClips = selectClipsPath(['listingClips'])
export const selectlistingClipsError = selectClipsPath(['listingClipsError'])
export const selectFile = selectClipsPath(['file'])
export const selectuploadingClip = selectClipsPath(['uploadingClip'])
export const selectuploadingClipError = selectClipsPath(['uploadingClipError'])

export default clipSlice.reducer;

