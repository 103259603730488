import { createSlice } from "@reduxjs/toolkit"
import { mergeDeepLeft, path } from "ramda"

const initialState = {
    campaign: {},
    campaigns: [],
    creatingCampaign: false,
    creatingCampaignError: null,
    fetchingCampaign: true,
    fetchingCampaignError: null,
    fetchingCampaigns: false,
    fetchingCampaignsError: null,
}

const campaignSlice = createSlice({
    name: 'campaign',
    initialState,
    reducers: {
        createCampaign: (state) => mergeDeepLeft({
            creatingCampaign: true,
            creatingCampaignError: null
        }, state),
        createCampaignError: (state, action) => mergeDeepLeft({
            creatingCampaign: false,
            creatingCampaignError: action.payload.message
        }, state),
        createCampaignSuccess: (state, action) => mergeDeepLeft({
            creatingCampaign: false,
            campaign: action.payload,
            campaigns: [...state.campaigns, action.payload],
            creatingCampaignError: null
        }, state),
        fetchCampaign: (state) => mergeDeepLeft({
            fetchingCampaign: true,
            campaign: {},
            fetchCampaignError: null
        }, state),
        fetchCampaignSuccess: (state, action) => mergeDeepLeft({
            fetchingCampaign: false,
            campaign: action.payload,
            fetchCampaignError: null
        }, state),
        fetchCampaignError: (state, action) => mergeDeepLeft({
            fetchingCampaign: false,
            campaign: {},
            fetchCampaignError: action.payload
        }, state),
        fetchCampaigns: (state) => mergeDeepLeft({
            fetchingCampaigns: true,
            campaigns: [],
            fetchCampaignsError: null
        }, state),
        fetchCampaignsSuccess: (state, action) => mergeDeepLeft({
            fetchingCampaigns: false,
            campaigns: action.payload,
            fetchCampaignError: null
        }, state),
        fetchCampaignsError: (state, action) => mergeDeepLeft({
            fetchingCampaigns: false,
            campaigns: [],
            fetchCampaignsError: action.payload
        }, state)
    }
})

export const {
    createCampaign,
    createCampaignError,
    createCampaignSuccess,
    fetchCampaign,
    fetchCampaignSuccess,
    fetchCampaignError,
    fetchCampaigns,
    fetchCampaignsSuccess,
    fetchCampaignsError
} = campaignSlice.actions

const selectCampaignPath = (props = []) => path(['campaign', ...props])

export const selectCampaign = selectCampaignPath(['campaign'])
export const selectCampaigns = selectCampaignPath(['campaigns'])
export const selectCampaignCreating = selectCampaignPath(['creatingCampaign'])
export const selectCampaignCreatingError = selectCampaignPath(['creatingCampaignError'])
export const selectCampaignFetching = selectCampaignPath(['fetchingCampaign'])
export const selectCampaignFetchingError = selectCampaignPath(['fetchingCampaignError'])
export const selectCampaignsFetching = selectCampaignPath(['fetchingCampaigns'])
export const selectCampaignsFetchingError = selectCampaignPath(['fetchingCampaignsError'])

export default campaignSlice.reducer