// COLORS
const background = "#F4F4F2"
const cream = '#FEFDFE'
// const black = '#181815'
const blackOpacity = 'rgb(24, 24, 21, 0.5)'
const light_gray = 'rgb(232, 232, 232)'
const off_white = '#E1E1DC'
const beige = '#D2C9BE'
// const white = '#FFFFFF'
const gray = '#F3F3F1'
const dark_gray = '#979797'
const warning = '#EA6D5D'
const green = '#AEC8B9'
const dusty_blue = '#7A899E'
const gold = '#FDCA49'

const cerulean_blue = 'rgba(0, 145, 255, 1)'
const cerulean_blue_65 = 'rgba(0, 145, 255, 0.65)'
const yellow_green = 'rgba(170, 200, 100, 1)'
const orange = 'rgba(255, 140, 50, 1)'
const coral = 'rgba(255, 180, 180, 1)'
const light_green = 'rgba(0, 255, 160, 1)'
const light_blue = 'rgba(135, 230, 255, 1)'
const light_blue_50 = 'rgba(135, 230, 255, 0.5)'
const dark_blue = 'rgba(45, 45, 185, 1)'
const dark_red = 'rgba(175, 0, 0, 1)'
const dark_green = 'rgba(0, 100, 15, 1)'
const white = 'rgba(255, 255, 255, 1)'
const black = 'rgba(0, 0, 0, 1)'


const blue = '#0091ff'
const blueOpacity = 'rgba(0, 145, 255, 0.5)'
const red = '#ff8c32'
const redOpacity = 'rgba(0, 145, 255, 0.85)'
const yellow = '#a7e6ff'
const yellowOpacity = 'rgba(45, 45, 185, 0.95)'
const yellowLightOpacity = 'rgba(45, 45, 185, 0.4)'

const twitter = '#1DA1F2'
const facebook = '#1877f2'
const youtube = '#FF0000'
const instagram = '#7232bd'

module.exports = {
    ant: {
        token: {
            colorPrimary: blue,
            colorLink: blue,
            colorTextBase: black,
            colorPrimaryBorderHover: white
        },
        components: {
            Layout: {
                bodyBg: white,
                headerBg: white,
                headerPadding: '0px 25px',
                siderBg: white,
                triggerBg: white,
                triggerColor: blue
            },
            Slider: {
                handleColor: white,
                handleActiveColor: white
            },
            Input: {
                activeBg: white,
                hoverBg: white,
                activeBorderColor: blue,
                hoverBorderColor: blue
            },
            Menu: {
                itemHoverBg: white,
                itemSelectedBg: white,
                itemSelectedColor: blue,
                itemHoverColor: blueOpacity,
                subMenuItemBg: white
            }
        },
    },
    'primary-color': black,
    'primary-black': black,
    'primary-black-opacity': blackOpacity,
    'primary-blue': blue,
    'primary-blue-opacity': blueOpacity,
    'primary-red': red,
    'primary-yellow': yellow,
    'primary-white': off_white,
    'gold': gold,
    'bright-white': white,
    'light-gray': light_gray,

    'primary-text': black,
    'dark-text': dark_blue,
    'text-contrast': white,

    'link-color': black,
    'success-color': green,
    'error-color': warning,
    'text-color': black,
    'text-color-secondary': dark_gray,

    'disabled-color': dark_gray,
    'contrast-background': gray,
    
    'slider-rail-background-color': off_white,
    'slider-track-background-color': black,
    
    'layout-body-background': white,
    'layout-header-background': white,
    
    'border-color': black,
    
    'sessions-graph-color': light_blue_50,
    'plays-graph-color': light_blue,
    'subscriptions-graph-color': cerulean_blue_65,
    'conversions-graph-color': cerulean_blue,

    'twitter-color': twitter,
    'facebook-color': facebook,
    'youtube-color': youtube,
    'instagram-color': instagram,

    'border-radius-base': '10px',
    'card-shadow': '2px 2px 15px 0px rgba(0,0,0,0.25)'
}