import { compose } from "ramda"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchConversionEpisodes, fetchConversionReferers, fetchConversionSummary, fetchConversionTimeseries, selectConversionEpisodes, selectConversionReferers, selectConversionSummary, selectConversionTimeseries, selectFetchingConversionEpisodes, selectFetchingConversionEpisodesError, selectFetchingConversionReferers, selectFetchingConversionReferersError, selectFetchingConversionSummary, selectFetchingConversionSummaryError, selectFetchingConversionTimeseries, selectFetchingConversionTimeseriesError } from "./conversionSlice"

const withConversion = (Component = React.Fragment) => (props = {}) => {
    const dispatch = useDispatch()

    const Conversion = {
        conversionSummary: useSelector(selectConversionSummary),
        fetchingConversionSummary: useSelector(selectFetchingConversionSummary),
        fetchingConversionSummaryError: useSelector(selectFetchingConversionSummaryError),

        dispatchFetchConversionSummary: compose(dispatch, fetchConversionSummary),

        conversionTimeseries: useSelector(selectConversionTimeseries),
        fetchingConversionTimeseries: useSelector(selectFetchingConversionTimeseries),
        fetchingConversionTimeseriesError: useSelector(selectFetchingConversionTimeseriesError),
        
        dispatchFetchConversionTimeseries: compose(dispatch, fetchConversionTimeseries),

        conversionReferers: useSelector(selectConversionReferers),
        fetchingConversionReferers: useSelector(selectFetchingConversionReferers),
        fetchingConversionReferersError: useSelector(selectFetchingConversionReferersError),
        
        dispatchFetchConversionReferers: compose(dispatch, fetchConversionReferers),

        conversionEpisodes: useSelector(selectConversionEpisodes),
        fetchingConversionEpisodes: useSelector(selectFetchingConversionEpisodes),
        fetchingConversionEpisodesError: useSelector(selectFetchingConversionEpisodesError),
        
        dispatchFetchConversionEpisodes: compose(dispatch, fetchConversionEpisodes),
    }

    return <Component {...props} Conversion={Conversion}/>
}

export default withConversion