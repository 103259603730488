import { compose } from "ramda"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchLocations, fetchReferers, fetchReferersByProgress, fetchSessions, fetchSessionsSummary, selectFetchingLocations, selectFetchingLocationsError, selectFetchingReferers, selectFetchingReferersByProgress, selectFetchingReferersByProgressError, selectFetchingReferersError, selectFetchingSessions, selectFetchingSessionsError, selectFetchingSessionsSummary, selectFetchingSessionsSummaryError, selectLocations, selectReferers, selectReferersByProgress, selectSessions, selectSessionsSummary } from "./sessionSlice"

const withSession = (Component = React.Fragment) => (props = {}) => {
    const dispatch = useDispatch()

    const Session = {
        sessions: useSelector(selectSessions),
        fetchingSessions: useSelector(selectFetchingSessions),
        fetchingSessionsError: useSelector(selectFetchingSessionsError),

        dispatchFetchSessions: compose(dispatch, fetchSessions),

        referers: useSelector(selectReferers),
        fetchingReferers: useSelector(selectFetchingReferers),
        fetchingReferersError: useSelector(selectFetchingReferersError),

        dispatchFetchReferers: compose(dispatch, fetchReferers),

        locations: useSelector(selectLocations),
        fetchingLocations: useSelector(selectFetchingLocations),
        fetchingLocationsError: useSelector(selectFetchingLocationsError),

        dispatchFetchLocations: compose(dispatch, fetchLocations),

        referersByProgress: useSelector(selectReferersByProgress),
        fetchingReferersByProgress: useSelector(selectFetchingReferersByProgress),
        fetchingReferersByProgressError: useSelector(selectFetchingReferersByProgressError),

        dispatchFetchReferersByProgress: compose(dispatch, fetchReferersByProgress),

        sessionsSummary: useSelector(selectSessionsSummary),
        fetchingSessionsSummary: useSelector(selectFetchingSessionsSummary),
        fetchingSessionsSummaryError: useSelector(selectFetchingSessionsSummaryError),

        dispatchFetchSessionsSummary: compose(dispatch, fetchSessionsSummary),
    }

    return <Component {...props} Session={Session}/>
}

export default withSession
