import React from 'react'
import styled from "styled-components";
import theme from '../theme';


const MenuContainer = styled.div`
    padding: 0px 20px 0px 20px;
    max-width: 100vw;
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`

const MenuHeader = styled.h2`
    color: ${theme['bright-white']};
    font-size: 1.2rem;
    font-weight: 600;
    a {
        color: ${theme['bright-white']};
        text-decoration: underline;
    }
`

const MenuText = styled.p`
    color: ${theme['bright-white']};
    font-size: 0.8rem;
    a {
        color: ${theme['bright-white']};
        text-decoration: underline;
    }
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    max-width: 100%;
    margin: 10px 0px;
`

const MoreButton = styled.a`
    display: flex;
    align-items: center;
    border-radius: 50px;
    font-size: 1rem;
    background-color: ${({ settings: { gradientTopColor = [] } }) => `rgba(${gradientTopColor.join(',')}, 0.5)`};
    height: 50px;
    padding: 10px 20px;
    color: ${theme['bright-white']};
    border: 1px solid ${theme['bright-white']};
    margin: 0px 0px 10px 0px;
    :hover {
        color: ${theme['bright-white']} !important;
    }
`

const SignupButton = styled.a`
    display: flex;
    align-items: center;
    border-radius: 50px;
    font-size: 1rem;
    background-color: ${theme['bright-white']};
    height: 50px;
    padding: 10px 20px;
    color: ${theme['text-color']};
    border: none;
    margin: 0px 20px 10px 0px;
    :hover {
        color: ${theme['text-color']} !important;
    }
`

const Footer = styled.div`
    display: flex;
    align-self: bottom;
    text-align: center;
    font-size: 14px;
    bottom: 0;
    padding: 5px;
    a {
        color: ${theme['disabled-color']};
    }
`

const Menu = ({
    podcast = {}
}) => {
    const { settings = {} } = podcast;

    return (
        <MenuContainer>
            <MenuHeader>Claim Your Podcard</MenuHeader>
            <MenuText>Podcards are an easy to share, customizable media card, designed to help you grow.</MenuText>
            <ButtonContainer>
                <SignupButton href={process.env.PODCARDS_URL} target='_blank'>Sign up for Free</SignupButton>
                <MoreButton href={process.env.PODCARDS_URL} target='_blank' settings={settings}>Learn More</MoreButton>
            </ButtonContainer>
            <Footer>
                <a href={`${process.env.PODCARDS_URL}/privacy`} target='_blank'>Privacy</a>
            </Footer>
        </MenuContainer>
    )
}

export default Menu;