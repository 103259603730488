import React, { useEffect } from "react"
import { Col, Grid, Image, Layout, Menu, Row, Spin } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, ExportOutlined, SettingOutlined, SyncOutlined } from '@ant-design/icons'
import { compose, evolve, map, prop, __, pathOr } from "ramda"
import { Link, useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { withConnection } from "../Connection"
import { withPodcasts } from "../Podcast"
import theme from '../../theme'
import { withAuth } from "../Auth"
import { Logo } from "../../Components/Logo"
import { withUser } from "../User"
import UpgradeButton from "../../Components/Upgrades"
import Notifications from "../Notification/Notifications"
import { withCustomer } from "../Customer"
import AnalyticsSidebar from "../Analytics/AnalyticsSidebar"
import { PodCardsButton } from "../../Typography"
import withSearchParams from "./withSearchParams"

const AppBodyLayout = styled(Layout)`
    font-family: 'NeueHaasRoman', sans-serif;
    background-color: ${({ bg }) => bg};
    margin: 0px;
`

const NavigationMenu = styled(Menu)`
    border-bottom: 0px;
    display: ${({ display = true }) => display ? '' : 'none'};
`

const Content = styled(Layout.Content)`
    min-height: calc(100vh - 62px);
    margin-top: 0px !important;
`

const ContentContainer = styled.div`
    margin: 50px auto;
    max-width: 1200px;
`

const Avatar = styled(Image)`
    border: none;
`

const ConnectionIndicator = ({ Connection: { dispatchConnect, connecting, connected, connectionError } }) => {
    return (
        <div>
            {connecting && <SyncOutlined onClick={() => dispatchConnect()} spin size="small" style={{ color: theme['primary-color'] }} />}
            {connected && <CheckCircleOutlined onClick={() => dispatchConnect()} size="small" style={{ color: theme['success-color'] }} />}
            {connectionError && <CloseCircleOutlined onClick={() => dispatchConnect()} size="small" style={{ color: theme['error-color'] }} />}
        </div>
    )
}

const ConnectedIndicator = withConnection(ConnectionIndicator)

const BrandingContainer = styled.div`
    cursor: pointer;
`

const MenuHeader = styled(Layout.Header)`
    border-radius: 0px 0px ${theme['border-radius-base']} 0px;
    box-shadow: ${theme['card-shadow']};
`

export const AppLayout = ({
    Auth: { user, dispatchLogout },
    Customer: { customer },
    Podcasts = {},
    SearchParams: { partner },
    children
}) => {
    const navigate = useNavigate()
    const location = useLocation()

    const { useBreakpoint } = Grid

    const { md } = useBreakpoint()

    const { podcast = {}, podcasts, fetchingPodcasts } = Podcasts
    const { settings: { gradientTopColor = [] } = {} } = podcast

    useEffect(() => {
        if (!podcasts.length && !fetchingPodcasts) {
            navigate(partner ? `/podcast/new?partner=${partner}` : '/podcast/new')
        }
    }, [podcasts, fetchingPodcasts])

    const activeKey = prop('pathname', location)

    const bg = activeKey === `/${podcast.id}/podcard` ? `rgba(${gradientTopColor.join(',')}, 0.75)` : theme['primary-blue']

    return (
        <AppBodyLayout className="layout" bg={bg}>
            <MenuHeader>
                <Row>
                    <Col sm={{ flex: '100%' }} lg={{ flex: '100%' }}>
                        <BrandingContainer>
                            <Logo size="1.25rem" href="/" />
                        </BrandingContainer>
                    </Col>
                    <Col sm={15} lg={15} />
                    <Col sm={3}>
                        <UpgradeButton type='secondary' location='header' upgradePath='marketer' />
                    </Col>
                    <Col sm={2}>
                        {
                            podcast.id && (
                                <a href={`${process.env.PODCARDS_URL}/${podcast.slug}`} target="_blank">
                                    <PodCardsButton>
                                        Podcard <ExportOutlined />
                                    </PodCardsButton>
                                </a>
                            )
                        }
                    </Col>
                    <Col sm={2}>
                        <NavigationMenu
                            mode='horizontal'
                            items={[
                                {
                                    label: <Avatar
                                        src={pathOr(user.picture, ['itunes', 'image'], podcast)}
                                        preview={false}
                                        height={30}
                                        width={30}
                                    />,
                                    key: 'menu',
                                    children: [
                                        {
                                            label: 'Podcasts',
                                            key: 'podcasts',
                                            icon: fetchingPodcasts ? <Spin spinning /> : null,
                                            showonempty: false,
                                            show: podcasts.length > 1,
                                            activekey: podcast.id,
                                            onClick: () => navigate('/')
                                        },
                                        {
                                            label: 'Account',
                                            key: 'account',
                                            showonempty: true,
                                            icon: <ExportOutlined />,
                                            onClick: () => window.open(`${process.env.STRIPE_ACCOUNT_URL}?prefilled_email=${encodeURIComponent(customer.email)}`)
                                        },
                                        {
                                            label: 'Settings',
                                            key: `${podcast.id}/podcast/settings`,
                                            showonempty: false,
                                            icon: <SettingOutlined />,
                                            onClick: () => navigate(`/${podcast.id}/podcast/settings`)
                                        },
                                        {
                                            type: 'divider',
                                        },
                                        {
                                            label: 'Logout',
                                            key: 'logout',
                                            showonempty: true,
                                            onClick: dispatchLogout
                                        },

                                    ].filter(({ showonempty, show = true }) =>
                                        !podcasts.length && !fetchingPodcasts
                                            ? showonempty
                                            : show
                                    ),
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </MenuHeader>
            <AppBodyLayout bg={bg}>
                <AnalyticsSidebar display={activeKey !== '/'} />
                <AppBodyLayout bg={bg}>
                    <Content style={{ margin: md ? '100px' : '20px' }}>
                        <ContentContainer>
                            <Notifications />
                            {children}
                        </ContentContainer>
                    </Content>
                </AppBodyLayout>
            </AppBodyLayout>
        </AppBodyLayout>
    )
}

export const ConnectedAppLayout = compose(
    withAuth,
    withCustomer,
    withUser,
    withPodcasts,
    withSearchParams
)(AppLayout)

const layoutRoutes = (routes = []) => map(evolve({
    element: Component => <ConnectedAppLayout>{Component}</ConnectedAppLayout>,
    children: layoutRoutes
}), routes)

export default layoutRoutes