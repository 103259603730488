import React, { useEffect, useState } from "react"
import { MediaPlayer } from "./MediaPlayer"
import { compose, identity, includes, map, reverse, split, tap } from "ramda"
import styled from "styled-components"
import { PlayerActionButton } from "./PlayerActionButton"
import { PlayerProgressSlider } from "./PlayerProgressSlider"
import { Col } from "antd"
import { withEventLogger } from "../../HOC/EventLogger"
import { PLAYER_EVENT_TYPES } from "../../HOC/EventLogger"

const Container = styled.div`
`

const PlayerControls = ({
    podcast = {},
    episode = {},
    SessionEventLogger = identity,
    displaySlider = true
}) => {
    const { settings = {} } = podcast
    const [media, setMedia] = useState(episode.enclosure)
    const [autoPlay, setAutoPlay] = useState(false)
    const [playing, setPlaying] = useState(false)
    const [buffering, setBuffering] = useState(false)
    const [duration, setDuration] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)
    const [seekTime, seekTo] = useState(0)
    const [audioType, setAudioType] = useState('episode')

    useEffect(() => {
        const audioType = episode.clip ? 'clip' : 'episode'
        
        setAudioType(audioType) 
    }, [episode])

    useEffect(() => {
        const { itunes = {}, clip } = episode

        if (itunes.duration && !clip) {
            const calcDurationSeconds = compose(
                ([seconds = 0, minutes = 0, hours = 0]) => (hours * 60 * 60) + (minutes * 60) + seconds,
                reverse,
                map(Number),
                split(':')
            )

            const durationInSeconds = includes(':',itunes.duration) ? calcDurationSeconds(itunes.duration) : itunes.duration

            setDuration(durationInSeconds)
        }
    }, [episode])

    useEffect(() => {
        if (episode.id) {
            setMedia(audioType === 'clip' ? episode.clip : episode.enclosure)
        }
    }, [audioType, episode])

    const sessionEvent = (type = '') => SessionEventLogger({
        type,
        clipId: audioType === 'clip' ? episode.clip : null,
        episodeGuid: episode.guid,
        podcastId: podcast.id,
        progress: currentTime,
        duration: duration
    })

    const handleAudioPlay = () => {
        setPlaying(true)

        sessionEvent(PLAYER_EVENT_TYPES.PLAY)
    }

    const handleAudioPause = () => {
        setPlaying(false)

        sessionEvent(PLAYER_EVENT_TYPES.PAUSE)
    }

    const handleProgress = () => {
        sessionEvent(PLAYER_EVENT_TYPES.PROGRESS)
    }

    return (
        <Container xs={24}>
            <PlayerProgressSlider
                settings={settings}
                currentTime={currentTime}
                seekTo={seekTo}
                seekTime={seekTime}
                duration={duration}
                display={displaySlider}
            />
            <PlayerActionButton
                isBuffering={buffering}
                playing={playing}
                currentTime={currentTime}
                onPlay={() => setPlaying(true)}
                onPause={() => setPlaying(false)}
                audioType={audioType}
                handleQueueEpisode={() => {
                    setBuffering(true)
                    setCurrentTime(0)
                    seekTo(0)
                    setMedia(episode.enclosure)
                    setAudioType('episode')
                    setAutoPlay(true)
                }}
            />
            <MediaPlayer
                preload={audioType === 'clip' ? 'auto' : 'none'}
                autoPlay={autoPlay}
                url={media}
                progressInterval={15000}
                playing={playing}
                seekTime={seekTime}
                onPlay={handleAudioPlay}
                onPause={handleAudioPause}
                onBuffer={() => setBuffering(true)}
                onBufferEnd={() => setBuffering(false)}
                onDuration={setDuration}
                onProgress={handleProgress}
                onTimeUpdate={setCurrentTime}
                height='10px'
                width='10px'
            />
        </Container>
    )
}

export default compose(
    withEventLogger
)(PlayerControls)