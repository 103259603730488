import auth0 from 'auth0-js'
import JWT from 'jwt-decode'

const AuthClient = new auth0.WebAuth({
    domain: process.env.AUTH0_DOMAIN,
    clientID: process.env.AUTH0_CLIENT_ID,
    responseType: 'token id_token',
    redirectUri: `${process.env.BASE_URL}/`
})

const AuthApi = {
    parse: async (hash) => {
        const parseHash = ({ hash }) => new Promise((resolve, reject) => {
            AuthClient.parseHash({ hash }, (err, res) => {
                if (err) reject(err)
                resolve(res)
            })
        })

        const { accessToken, idTokenPayload } = await parseHash({ hash })
        
        const user = {
            ...idTokenPayload,
            accessToken
        }
        
        return user
    },
    signin: async (redirectPath = '/') => {
        try {
            const authoriseUser = () => new Promise((resolve, reject) => {
                AuthClient.authorize({
                    scope: 'openid profile email offline_access',
                    redirectUri: `${process.env.BASE_URL}/auth/reponse`,
                    state: JSON.stringify({ redirectPath: encodeURIComponent(redirectPath) }),
                    audience: process.env.FLYWHEEL_ENDPOINT
                }, (err, res) => {
                    if (err) reject(err)
                    resolve(res)
                })
            })

            await authoriseUser()
        } catch (error) {
            throw new Error(error)
        }
    },
    register: async ({ name, email, password }) => {
        try {
            const signupAndAuthorize = ({ name, email, password }) => new Promise((resolve, reject) => {
                AuthClient.signupAndAuthorize({
                    email,
                    username: email,
                    password,
                    connection: 'Username-Password-Authentication',
                    metadata: { name }
                }, (err, res) => {
                    if (err) reject(err)
                    resolve(res)
                })
            })
    
            const authResult = await signupAndAuthorize({ name, email, password })
            
            const { accessToken, idToken } = authResult
            
            const user = {
                accessToken,
                ...JWT(idToken)
            }
    
            return user;
        } catch (error) {
            throw new Error(error)
        }
    },
    logout: async () => AuthClient.logout({
        returnTo: `${process.env.BASE_URL}/`
    })
}

export default AuthApi