import { compose, head, match, path } from "ramda"
import React, { useEffect } from "react"
import { withConnection } from "../Connection"
import { withPodcasts } from "../Podcast"
import { withUser } from '../User'
import { withAuth } from "../Auth"
import { withCustomer } from "../Customer"
import { withFollowall } from "../Followall"
import withEpisodes from "../Episode/withEpisodes"
import { getPodcastIdFromWindow } from "../../Utils/document.utils"
import GlobalFonts from '../../assets/fonts/fonts'
import Intercom from "@intercom/messenger-js-sdk"

const App = ({
    Podcasts: { dispatchFetchPodcasts, dispatchFetchPodcast, podcasts, podcast, fetchingPodcast },
    Episodes: { dispatchFetchEpisodes, fetchingEpisodes },
    Connection: { dispatchConnect, connected },
    Auth: { user: authUser },
    User: { user, dispatchFetchUser },
    Customer: { dispatchFetchCustomer },
    Followall: { dispatchFetchFollowall },
    children
}) => {
    useEffect(() => {
        if (authUser.email) {
            dispatchConnect()
        }
    }, [authUser])

    useEffect(() => {
        if (connected && !podcasts.length) {
            dispatchFetchPodcasts()
        }
    }, [connected])

    useEffect(() => {
        const podcastId = getPodcastIdFromWindow(window)

        if (connected && !podcast.id && !fetchingPodcast && podcastId) {
            dispatchFetchPodcast({ id: podcastId })
        }

    }, [connected])

    useEffect(() => {
        if (podcast.id && !fetchingEpisodes) {
            dispatchFetchEpisodes({ podcastId: podcast.id, limit: 50, fields: ['title', 'guid', 'itunes_duration'] })
        }
    }, [podcast.id])

    useEffect(() => {
        if (connected && podcast.id) {
            dispatchFetchFollowall({ resourceId: podcast.id, })
        }
    }, [connected, podcast.id])

    useEffect(() => {
        if (connected) {
            dispatchFetchUser(authUser.sub)
        }
    }, [connected])

    useEffect(() => {
        if (connected && user.customerId) {
            dispatchFetchCustomer({ customerId: user.customerId })
        }
    }, [connected, user])

    Intercom({
        app_id: 'kapjz76o',
        user_id: user.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        name: user.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        email: user.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    });

    return (
        <React.Fragment>
            <GlobalFonts />
            {children}
        </React.Fragment>
    )
}

export default compose(
    withFollowall,
    withPodcasts,
    withEpisodes,
    withConnection,
    withUser,
    withAuth,
    withCustomer
)(App)