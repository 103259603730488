import React from "react"
import utc from "dayjs/plugin/utc"
import dayjs from "dayjs"
import { compose, map, flatten, last, head, prop } from "ramda"
import { Bar } from "react-chartjs-2"

dayjs.extend(utc)

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const FORMATS = {
    hour: 'HH:00',
    day: 'MM/DD',
    month: 'YYYY-MM'
}

const TimeseriesChart = ({
    labels = [],
    datasets = [],
    interval = 'day',
    options = {}
}) => (
    <Bar
        options={{
            responsive: true,
            plugins: {
                legend: {
                    position: 'bottom',
                    display: true
                },
            },
            animation: {
                delay: 0,
                duration: 0
            },
            ...options
        }}
        data={{
            labels: map(date => interval === 'hour'
                ? dayjs(date).format(FORMATS[interval])
                : dayjs.utc(date).format(FORMATS[interval])
                , labels),
            datasets
        }}
    />
)

export default TimeseriesChart