import React, { useState, useEffect } from 'react'
import withFollowall from './withFollowall'
import { withPodcasts } from '../Podcast'
import withLoading from '../App/withLoading'
import styled from 'styled-components'
import { Col, Row, Skeleton } from 'antd'
import { renderToStaticMarkup } from 'react-dom/server'
import FollowallOptions from './FollowallOptions'
import { Followall } from '../../followall'
import { compose, path } from 'ramda'
import Card from '../../Components/Card'


const Container = styled.div`
    overflow: hidden;
`

const FollowallPreviewContainer = styled.div`
    padding: 0px;
`

const FollowallCard = styled(Card)`
    margin: 0px 0px 50px 0px;
`

const FollowallContainer = ({
    Podcasts: {
        podcast
    },
    Followall: {
        followall,
        fetchingFollowall,
        dispatchFetchFollowall,
    },
}) => {
    const [iconSize, setIconSize] = useState('md')
    const [iconType, setIconType] = useState('icon')


    useEffect(() => {
        if (podcast.id) {
            dispatchFetchFollowall({ resourceId: podcast.id })
        }
    }, [podcast.id])

    const LoadingContainer = withLoading(Container)

    const FollowallComponent = <Followall
        property='followall'
        podcastId={podcast.id}
        followall={followall}
        iconSize={iconSize}
        iconType={iconType}
        margin='0px 20px 20px 0px'
        wrapBehavior='wrap'
    />

    return (
        <LoadingContainer predicate={fetchingFollowall}>
            <Row justify="space-between">
                <Col span={24} md={12}>
                    <FollowallCard>
                        <FollowallOptions
                            followall={followall}
                            followallCode={renderToStaticMarkup(FollowallComponent)}
                            iconSize={iconSize}
                            handleSelectIconSize={setIconSize}
                            iconType={iconType}
                            handleIconSetChange={compose(setIconType, path(['target', 'value']))}
                        />
                    </FollowallCard>
                </Col>
                <Col span={24} md={12}>
                    <FollowallPreviewContainer>
                        <Row align="center">
                            <Col span={20}>
                                <Skeleton size='small' />
                            </Col>
                        </Row>
                        <Row align="center">
                            <Col span={20}>
                                {FollowallComponent}
                            </Col>
                        </Row>
                        <Row align="center">
                            <Col span={20}>
                                <Skeleton size='small' />
                            </Col>
                        </Row>
                    </FollowallPreviewContainer>
                </Col>
            </Row>
        </LoadingContainer>
    )
}

export default compose(
    withFollowall,
    withPodcasts
)(FollowallContainer)