import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearAlert, createAlert, selectAlerts } from './notificationSlice'
import { compose } from 'ramda'

const withNotifications = (Component = React.Fragment) => (props = {}) => {
    const dispatch = useDispatch()

    const Notifications = {
        alerts: useSelector(selectAlerts),

        dispatchCreateAlert: compose(dispatch, createAlert),
        dispatchClearAlert: compose(dispatch, clearAlert)
    }

    return <Component {...props} Notifications={Notifications} />
}

export default withNotifications