import { Bar } from 'react-chartjs-2';
import styled from "styled-components"

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import React from 'react';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const ChartConatiner = styled.div`
    margin: 50px 0px;
`

export default ({
    labels = [],
    datasets = [],
    options = {}
}) => {
    return (
        <ChartConatiner>
            <Bar
                options={{
                    indexAxis: 'y',
                    elements: {
                        bar: {
                            borderWidth: 2,
                        },
                    },
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false,
                            position: 'right',
                        },
                        title: {
                            display: false,
                            text: 'Ranked',
                        },
                    },
                    scales: {
                        x: {
                            type: 'linear'
                        }
                    },
                    ...options
                }}
                data={{
                    labels,
                    datasets
                }}
            />
        </ChartConatiner>
    )
}