import React from 'react'
import styled from 'styled-components'
import withLoading from '../App/withLoading'
import { Col, Row } from 'antd'
import Card from '../../Components/Card'
import { BlueListDot, BodyText, BodyTextContainer, Bold, Header, ListContainer, ListItem, SmallBodyText } from '../../Typography'
import { compose } from 'ramda'
import UpgradeButton from '../../Components/Upgrades'
import withCustomer from './withCustomer'

const Container = styled.div`
    overflow: hidden;
`

const WelcomeHeader = styled(Header)`
    text-align: center;
`

const ThankYouContainer = styled.div`
    margin: 25px 0px;
    text-align: center;
`

const FeaturesContainer = styled(Row)`
    margin: 25px 0px;
    text-align: left;
`

const ButtonContainer = styled(Col)`
    justify-content: center;
    display: flex;
`

const CampaignPaywall = ({
    Customer: { fetchingCustomer = true }
}) => {
    const LoadingContainer = withLoading(Container)

    return (
        <LoadingContainer predicate={fetchingCustomer}>
            <Row justify='center' align='middle'>
                <Col sm={{ span: 24 }} lg={{ span: 16 }}>
                    <Card>
                        <WelcomeHeader>Campaigns</WelcomeHeader>
                        <ThankYouContainer>
                            <BodyText>
                                Campaigns are the perfect way to gain deeper insights into how each of your marketing strategies are performing.
                            </BodyText>
                            <FeaturesContainer justify='center'>
                                <Col sm={{ span: 24 }} lg={{ span: 18 }}>
                                    <ListContainer>
                                        <ListItem>
                                            <BlueListDot></BlueListDot>
                                            <BodyTextContainer>
                                                <SmallBodyText>
                                                    <Bold>Fine grained stat segmentation:</Bold> Create campaigns for organic or paid strategies and see how they compare
                                                </SmallBodyText>
                                            </BodyTextContainer>
                                        </ListItem>
                                        <ListItem>
                                            <BlueListDot></BlueListDot>
                                            <BodyTextContainer>
                                                <SmallBodyText>
                                                    <Bold>Compare performance across campaigns:</Bold> Understand how your campaigns are performing against eachother and adjust your strategy in real time
                                                </SmallBodyText>
                                            </BodyTextContainer>
                                        </ListItem>
                                    </ListContainer>
                                </Col>
                                <ButtonContainer flex='100%'>
                                    <UpgradeButton upgradePath='marketer' location='campaign_paywall' />
                                </ButtonContainer>
                            </FeaturesContainer>
                        </ThankYouContainer>
                    </Card>
                </Col>
            </Row>
        </LoadingContainer>
    )
}

export default compose(
    withCustomer
)(CampaignPaywall)