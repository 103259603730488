import { Col } from "antd"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { EpisodeTitle, PodcastTitle } from "../../Typography"
import theme from "../../theme"

const Container = styled(Col)`
    text-align: center;
    text-overflow: ellipses;
`
const TitleContainer = styled.div`
    overflow: hidden;
    white-space: nowrap;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 600;
    margin: 0px 0px 0px 0rem;
    color: ${theme['text-contrast']};
    mask: linear-gradient(270deg, #0000, #000 10px);
    #marquee {
        color: ${theme['text-contrast']};
        animation: marquee ${({ transformWidth }) => `${Math.abs(transformWidth) / 5}s`} linear infinite;
        @keyframes marquee {
            0% { transform: translateX(0); }
            50% { transform: translateX(${({ transformWidth }) => `${transformWidth}px`}); }
            100% { transform: translateX(0); }
        }
    }
`

const Title = styled(EpisodeTitle)``

export const EpisodeDetails = ({
    podcast = {},
    episode = {}
}) => {

    const titleRef = useRef(null)
    const titleContainerRef = useRef(null)

    const [transformWidth, setTransformWidth] = useState(0)
    
    const calcTextWidth = (txt = '', font = 'italic 12pt verdana') => {
        const canvas = document.createElement("canvas")
        const context = canvas.getContext("2d")
        context.font = font;
        const metrics = context.measureText(txt);

        return metrics.width
    }

    useEffect(() => {
        if (titleRef.current) {
            const textWidth = calcTextWidth(episode.title)
            const textContainerWidth = titleContainerRef.current.clientWidth
            
            if (textWidth > textContainerWidth) {
                setTransformWidth(textContainerWidth - textWidth)

                titleRef.current.id = 'marquee'
            }
        }
    }, [titleRef])

    return (
        <Container xs={24} sm={22}>
            <TitleContainer ref={titleContainerRef} transformWidth={transformWidth}>
                <Title ref={titleRef} dangerouslySetInnerHTML={{ __html: episode.title }} />
            </TitleContainer>
            <PodcastTitle dangerouslySetInnerHTML={{ __html: podcast.title }} />
        </Container>
    )
}