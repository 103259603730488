import { compose } from 'ramda'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { connect, selectConnectingStatus, selectConnectionError, selectConnectionStatus } from './connectionSlice'

const withConnection = (Component = React.Fragment) => (props) => {
    const dispatch = useDispatch()

    const Connection = {
        connected: useSelector(selectConnectionStatus),
        connecting: useSelector(selectConnectingStatus),
        connectionError: useSelector(selectConnectionError),
        
        dispatchConnect: compose(dispatch, connect)
    }

    return <Component {...props} Connection={Connection} />
}

export default withConnection