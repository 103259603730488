import React from 'react';
import Welcome from './Welcome';
import Checkout from './Checkout';

export default [
    {
        path: '/welcome',
        element: <Welcome />
    },
    {
        path: '/checkout',
        element: <Checkout />
    }
]
