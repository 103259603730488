import React  from 'react'
import { useDispatch, useSelector } from "react-redux"
import { fetchEpisodes, selectEpisodes, selectFetchingEpisodes, selectFetchingEpisodesError } from "./episodeSlice"
import { all, compose, includes, map, prop, tap } from 'ramda'

const withEpisodes = (Component = React.Fragment) => (props = {}) => {
    const dispatch = useDispatch()

    const testEpisodesForPrefix = compose(
        all(includes(process.env.PREFIX_DOMAIN)),
        map(prop('enclosure'))
    )

    const Episodes = {
        episodes: useSelector(selectEpisodes),
        fetchingEpisodes: useSelector(selectFetchingEpisodes),
        fetchingEpisodesError: useSelector(selectFetchingEpisodesError),
        hasPrefixInstalled: compose(testEpisodesForPrefix, useSelector)(selectEpisodes),

        dispatchFetchEpisodes: compose(dispatch, fetchEpisodes)
    }

    return <Component {...props} Episodes={Episodes} />
}

export default withEpisodes