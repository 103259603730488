import dayjs from "dayjs"
import { compose, identity } from "ramda"
import React from "react"
import { withPodcasts } from "../../Podcast"
import { truncateReferrer } from "../../Utils"
import SummaryBox from "../Charts/SummaryBox"
import { DATE_FORMAT } from "../Constants"
import withSession from "./withSession"
import { useFetchAnalytics } from "../useFetchAnalytics"
import withSearchParams from "../../App/withSearchParams"

const SessionsSummary = ({
    Session: { fetchingSessionsSummary, sessionsSummary, dispatchFetchSessionsSummary = identity } = {},
    Podcasts: { podcast: { id: podcastId, slug: podcastSlug } = {} } = {},
    SearchParams: {
        startDate = dayjs().subtract(7, 'day').startOf('day').format(DATE_FORMAT),
        endDate = dayjs().endOf('day').format(DATE_FORMAT),
        campaignId = null
    },
    interval = 'day' 
}) => {
    const { total: { count: sessionsCount = 0 } = {}, referer: { referer = '-' } = {}, location: { country_name = '-' } = {}  } = sessionsSummary

    useFetchAnalytics({
        handler: dispatchFetchSessionsSummary,
        startDate,
        endDate,
        interval,
        resourceId: podcastId,
        resourceSlug: podcastSlug,
        campaignId,
        limit: 1
    })

    const Statistics = [
        {
            title: 'Total Visitors',
            statistic: sessionsCount
        },
        {
            title: 'Top Source',
            statistic:  <a href={referer} target='blank'>{truncateReferrer(referer)}</a>
            ,
        },
        {
            title: 'Top Location',
            statistic: country_name
        }
    ]

    return (
        <SummaryBox
            Statistics={Statistics}
            loadingPredicate={fetchingSessionsSummary}
        />
    )

}

export default compose(
    withSession,
    withPodcasts,
    withSearchParams
)(SessionsSummary)
