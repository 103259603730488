import dayjs from "dayjs"
import { addIndex, compose, flatten, head, identity, last, map, reverse } from "ramda"
import React from "react"
import theme from "../../../theme"
import { withPodcasts } from "../../Podcast"
import TimeseriesChart from "../Charts/TimeseriesChart"
import { DATE_FORMAT } from "../Constants"
import withCombined from "./withCombined"
import { useFetchAnalytics } from "../useFetchAnalytics"
import withSearchParams from "../../App/withSearchParams"

const CombinedTimeseriesChart = ({
    Combined: { combinedTimeseries = [[]], dispatchFetchCombinedTimeseries = identity } = {},
    Podcasts: { podcast: { id: podcastId, slug: podcastSlug } = {} } = {},
    SearchParams: {
        startDate = dayjs().subtract(7, 'day').startOf('day').format(DATE_FORMAT),
        endDate = dayjs().endOf('day').format(DATE_FORMAT),
        campaignId = null
    },
    interval = 'day'
}) => {

    useFetchAnalytics({
        handler: dispatchFetchCombinedTimeseries,
        startDate: startDate,
        endDate: endDate,
        interval,
        resourceId: podcastId,
        resourceSlug: podcastSlug,
        campaignId,
    })

    const COLORS = [
        theme['sessions-graph-color'],
        theme['plays-graph-color'],
        theme['subscriptions-graph-color'],
        theme['conversions-graph-color']
    ]

    const TITLES = [
        'Visitors',
        'Plays',
        'Click Through Rate',
        'Conversions'
    ]

    const mapIdx = addIndex(map)

    const getDatasets = compose(
        reverse,
        mapIdx((data = [], idx = 0) => ({
            label: TITLES[idx],
            data: compose(flatten, map(last))(data),
            backgroundColor: COLORS[idx],
            // stack: `stack_${idx}`
        }))
    )

    const datasets = getDatasets(combinedTimeseries)

    return <TimeseriesChart
        datasets={datasets}
        labels={compose(flatten, map(head), head)(combinedTimeseries)}
        interval={interval}
        options={{
            scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true
                }
              }
        }}
    /> 

}

export default compose(
    withCombined,
    withPodcasts,
    withSearchParams
)(CombinedTimeseriesChart)