import { compose } from 'ramda'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authenticate, logout, selectAuthenticating, selectAuthenticationToken, selectUser } from './authSlice'

const withAuth = (Component = React.Fragment) => (props = {}) => {
    const dispatch = useDispatch()

    const Auth = {
        authenticating: useSelector(selectAuthenticating),
        authenticationToken: useSelector(selectAuthenticationToken),
        user: useSelector(selectUser),
        
        dispatchAuthenticate: compose(dispatch, authenticate),
        dispatchLogout: compose(dispatch, logout)
    }

    return <Component {...props} Auth={Auth} />
}

export default withAuth