import { fetchPodcast, fetchPodcastError, fetchPodcasts, fetchPodcastsError, fetchPodcastsSuccess, fetchPodcastSuccess, registerPodcast, registerPodcastError, registerPodcastSuccess, updatePodcast, updatePodcastError, updatePodcastSuccess } from "./podcastSlice"

export const PODCAST_CREATE = 'podcast:create'
export const PODCAST_CREATE_SUCCESS = 'podcast:create:success'
export const PODCAST_CREATE_FAILED = 'podcast:create:failed'

export const PODCAST_FETCH = 'podcast:get:id'
export const PODCAST_FETCH_SUCCESS = 'podcast:get:id:success'
export const PODCAST_FETCH_FAILED = 'podcast:get:id:failed'

export const PODCAST_FETCH_ALL = 'podcast:get'
export const PODCAST_FETCH_ALL_SUCCESS = 'podcast:get:success'
export const PODCAST_FETCH_ALL_FAILED = 'podcast:get:failed'

export const PODCAST_UPDATE = 'podcast:update'
export const PODCAST_UPDATE_SUCCESS = 'podcast:update:success'
export const PODCAST_UPDATE_FAILED = 'podcast:update:failed'

export const events = {
    [fetchPodcast().type]: PODCAST_FETCH,
    [fetchPodcasts().type]: PODCAST_FETCH_ALL,
    [registerPodcast().type]: PODCAST_CREATE,
    [updatePodcast().type]: PODCAST_UPDATE
}

export const handlers = {
    [PODCAST_FETCH_SUCCESS]: fetchPodcastSuccess,
    [PODCAST_FETCH_FAILED]: fetchPodcastError,
    [PODCAST_FETCH_ALL_SUCCESS]: fetchPodcastsSuccess,
    [PODCAST_FETCH_ALL_FAILED]: fetchPodcastsError,
    [PODCAST_CREATE_SUCCESS]: registerPodcastSuccess,
    [PODCAST_CREATE_FAILED]: registerPodcastError,
    [PODCAST_UPDATE_SUCCESS]: updatePodcastSuccess,
    [PODCAST_UPDATE_FAILED]: updatePodcastError,
}