import { fetchCustomer, fetchCustomerError, fetchCustomerSuccess } from "./customerSlice"

export const CUSTOMER_FETCH = 'customer:get:id'
export const CUSTOMER_FETCH_SUCCESS = 'customer:get:id:success'
export const CUSTOMER_FETCH_FAILED = 'customer:get:id:failed'

export const CUSTOMER_UPDATE_SUCCESS = 'customer:update:success'

export const events = {
    [fetchCustomer().type]: CUSTOMER_FETCH
}

export const handlers = {
    [CUSTOMER_FETCH_SUCCESS]: fetchCustomerSuccess,
    [CUSTOMER_FETCH_FAILED]: fetchCustomerError,
    [CUSTOMER_UPDATE_SUCCESS]: fetchCustomerSuccess
}