import { createSlice } from "@reduxjs/toolkit"
import { mergeDeepLeft, path } from "ramda"

const initialState = {
    promoSummary: {
        total_conversions: { total_conversions: 0 },
        impressions: { count: 0 },
        converted_listens: { converted_listens: 0 }
    },
    fetchingPromoSummary: false,
    fetchingPromoSummaryError: null,
    promoTimeseries: [[]],
    fetchingPromoTimeseries: false,
    fetchingPromoTimeseriesError: null,
}

export const promoSlice = createSlice({
    name: 'promoAnalytics',
    initialState,
    reducers: {
        fetchPromoSummary: mergeDeepLeft({
            fetchingPromoSummary: true,
            fetchingPromoSummaryError: null
        }),
        fetchPromoSummarySuccess: (state, action) => ({
            ...state,
            fetchingPromoSummary: false,
            promoSummary: action.payload    
        }),
        fetchPromoSummaryError: (state, action) => mergeDeepLeft({
            fetchingPromoSummary: false,
            fetchingPromoSummaryError: action.payload    
        }, state),
        fetchPromoTimeseries: mergeDeepLeft({
            fetchingPromoTimeseries: true,
            fetchingPromoTimeseriesError: null
        }),
        fetchPromoTimeseriesSuccess: (state, action) => ({
            ...state,
            fetchingPromoTimeseries: false,
            promoTimeseries: action.payload    
        }),
        fetchPromoTimeseriesError: (state, action) => mergeDeepLeft({
            fetchingPromoTimeseries: false,
            fetchingPromoTimeseriesError: action.payload    
        }, state)
    }
})

export const {
    fetchPromoSummary,
    fetchPromoSummarySuccess,
    fetchPromoSummaryError,
    fetchPromoTimeseries,
    fetchPromoTimeseriesSuccess,
    fetchPromoTimeseriesError,
} = promoSlice.actions

const selectPromoPath = (props = []) => path(['promoAnalytics', ...props])

export const selectPromoSummary = selectPromoPath(['promoSummary'])
export const selectFetchingPromoSummary = selectPromoPath(['fetchingPromoSummary'])
export const selectFetchingPromoSummaryError = selectPromoPath(['fetchingPromoSummaryError'])
export const selectPromoTimeseries = selectPromoPath(['promoTimeseries'])
export const selectFetchingPromoTimeseries = selectPromoPath(['fetchingPromoTimeseries'])
export const selectFetchingPromoTimeseriesError = selectPromoPath(['fetchingPromoTimeseriesError'])

export default promoSlice.reducer