import { createPromo, createPromoError, createPromoSuccess, fetchPromos, fetchPromosError, fetchPromosSuccess } from "./promoSlice"

export const PROMO_CREATE = 'promo:create'
export const PROMO_CREATE_SUCCESS = 'promo:create:success'
export const PROMO_CREATE_FAILED = 'promo:create:failed'

export const PROMO_FETCH_ALL = 'promo:get'
export const PROMO_FETCH_ALL_SUCCESS = 'promo:get:success'
export const PROMO_FETCH_ALL_FAILED = 'promo:get:failed'

export const events = {
    [fetchPromos().type]: PROMO_FETCH_ALL,
    [createPromo().type]: PROMO_CREATE
}

export const handlers = {
    [PROMO_CREATE_SUCCESS]: createPromoSuccess,
    [PROMO_CREATE_FAILED]: createPromoError,
    [PROMO_FETCH_ALL_SUCCESS]: fetchPromosSuccess,
    [PROMO_FETCH_ALL_FAILED]: fetchPromosError,
}