import React from 'react'
import { compose } from "ramda"
import styled from 'styled-components'
import { withPodcasts } from '../Podcast'

import { withCustomer } from '../Customer'
import withLoading from '../App/withLoading'
import withPaywall from '../Customer/withPaywall'
import Promos from './Promos'

const Container = styled.div`
    width: 100%;
`

const PromosContainer = ({
    Customer: { hasMarketerSubscription = false, fetchingCustomer = false },
}) => {
    const LoadingConatiner = withLoading(Container)
    const Content = withPaywall(Promos)

    return (
        <LoadingConatiner predicate={fetchingCustomer}>
            <Content paywall='promo' predicate={!hasMarketerSubscription} />
        </LoadingConatiner>
    )
}

export default compose(
    withPodcasts,
    withCustomer
)(PromosContainer)