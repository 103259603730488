import React, { useEffect } from 'react'
import { Col, Select, Tooltip } from "antd"
import Typography from "antd/es/typography/Typography"
import { compose, head, identity, map, prop } from "ramda"
import styled from "styled-components"
import { withCustomer } from '../Customer'
import withPromos from './withPromos'

const OptionsSection = styled(Col)`
    margin: 0px 0px 25px 0px;
`

const PromoSelect = ({
    Customer: { hasMarketerSubscription = false },
    Promos: { fetchingPromos = true, promos = [] },
    onSelectPromo = identity,
    promoId = null,
    title = 'Select Promos',
    disabled = false
}) => {

    useEffect(() => {
        if (promos.length && !promoId) {
            const setDefaultPromo = compose(
                onSelectPromo,
                prop('id'),
                head
            )
            
            setDefaultPromo(promos)
        }
    }, [promos])
    
    const createPromoSelectItems = map((promo) =>
        <Select.Option key={promo.id} value={promo.id}>{promo.name}</Select.Option>
    )

    return (
        <OptionsSection span={24}>
            <Col span={24}>
                <Typography.Text>{title}</Typography.Text>
            </Col>
            <Col span={24}>
                <Tooltip title='Upgrade to create promos' overlayStyle={{ display: hasMarketerSubscription ? 'none' : '' }}>
                    <Select
                        disabled={disabled || fetchingPromos || !hasMarketerSubscription}
                        allowClear={false}
                        onChange={onSelectPromo}
                        data-testid='promo-select'
                        value={promoId || null}
                        style={{ width: '100%' }}
                        placeholder='None'
                    >
                        {createPromoSelectItems(promos)}
                    </Select>
                </ Tooltip>
            </Col>
        </OptionsSection>
    )
}

export default compose(
    withPromos,
    withCustomer
)(PromoSelect)