import { createSlice } from "@reduxjs/toolkit"
import { mergeDeepLeft, path } from "ramda"

const initialState = {
    promo: {},
    promos: [],
    creatingPromo: false,
    creatingPromoError: null,
    fetchingPromo: true,
    fetchingPromoError: null,
    fetchingPromos: false,
    fetchingPromosError: null,
}

const promoSlice = createSlice({
    name: 'promo',
    initialState,
    reducers: {
        createPromo: (state) => mergeDeepLeft({
            creatingPromo: true,
            creatingPromoError: null
        }, state),
        createPromoError: (state, action) => mergeDeepLeft({
            creatingPromo: false,
            creatingPromoError: action.payload.message
        }, state),
        createPromoSuccess: (state, action) => mergeDeepLeft({
            creatingPromo: false,
            promo: action.payload,
            promos: [...state.promos, action.payload],
            creatingPromoError: null
        }, state),
        fetchPromos: (state) => mergeDeepLeft({
            fetchingPromos: true,
            promos: [],
            fetchPromosError: null
        }, state),
        fetchPromosSuccess: (state, action) => mergeDeepLeft({
            fetchingPromos: false,
            promos: action.payload,
            fetchPromoError: null
        }, state),
        fetchPromosError: (state, action) => mergeDeepLeft({
            fetchingPromos: false,
            promos: [],
            fetchPromosError: action.payload
        }, state)
    }
})

export const {
    createPromo,
    createPromoError,
    createPromoSuccess,
    fetchPromo,
    fetchPromoSuccess,
    fetchPromoError,
    fetchPromos,
    fetchPromosSuccess,
    fetchPromosError
} = promoSlice.actions

const selectPromoPath = (props = []) => path(['promo', ...props])

export const selectPromo = selectPromoPath(['promo'])
export const selectPromos = selectPromoPath(['promos'])
export const selectPromoCreating = selectPromoPath(['creatingPromo'])
export const selectPromoCreatingError = selectPromoPath(['creatingPromoError'])
export const selectPromosFetching = selectPromoPath(['fetchingPromos'])
export const selectPromosFetchingError = selectPromoPath(['fetchingPromosError'])

export default promoSlice.reducer