import React from "react"
import { compose, concat, flatten, identity, map, prop, __ } from 'ramda';
import styled from 'styled-components'
import { withPodcasts } from '../../Podcast';
import { Bar } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { DATE_FORMAT } from "../Constants";
import withPlayer from "./withPlayer";
import { getTopEpisodeTitleByGuid } from "../../Utils";
import Card from "../../../Components/Card";
import theme from "../../../theme";
import { useFetchAnalytics } from "../useFetchAnalytics";
import withEpisodes from "../../Episode/withEpisodes";
import withSearchParams from "../../App/withSearchParams";
import dayjs from "dayjs";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const ChartConatiner = styled.div`
    margin: 50px 0px;
`

const ChartCard = styled(Card)`
    margin: 50px 0px;
`

const EpisodesByProgressChart = ({
    Player: { episodesByProgress = [], dispatchFetchEpisodesByProgress = identity } = {},
    Podcasts: { podcast } = {},
    Episodes: { episodes = [] },
    SearchParams: {
        startDate = dayjs().subtract(7, 'day').startOf('day').format(DATE_FORMAT),
        endDate = dayjs().endOf('day').format(DATE_FORMAT),
        campaignId = null
    },
    interval = 'day',
    episodeGuid = ''
}) => {
    const { id: resourceId, slug: resourceSlug } = podcast

    useFetchAnalytics({
        handler: dispatchFetchEpisodesByProgress,
        startDate,
        endDate,
        interval,
        resourceId,
        resourceSlug,
        episodeGuid,
        campaignId
    })

    return resourceId && (
        <ChartCard title="Top Episodes by Playthrough">
            <ChartConatiner>
                <Bar
                    options={{
                        indexAxis: 'y',
                        elements: {
                            bar: {
                                borderWidth: 2,
                            },
                        },
                        responsive: true,
                        plugins: {
                            legend: {
                                display: false,
                                position: 'right',
                            },
                            title: {
                                display: false,
                                text: 'Episodes',
                            },
                            tooltip: {
                                callbacks: {
                                    label: compose(concat(__, '%'), prop('formattedValue'))
                                }
                            }
                        },
                        scales: {
                            x: {
                                type: 'linear',
                                ticks: {
                                    callback: compose(concat(__, '%'), x => `${x}`)
                                }
                            }
                        },
                        tooltips: {

                        }
                    }}
                    
                    data={{
                        labels: compose(flatten, map(getTopEpisodeTitleByGuid(episodes)), map(prop('episode_guid')))(episodesByProgress),
                        datasets: [{
                            label: 'Episodes',
                            data: compose(flatten, map(prop('progress')))(episodesByProgress),
                            backgroundColor: theme['plays-graph-color']
                        }],
                            
                    }}
                    
                />
            </ChartConatiner>
        </ChartCard>
    )
}

export default compose(
    withPlayer,
    withSearchParams,
    withPodcasts,
    withEpisodes
)(EpisodesByProgressChart)