import { AppstoreAddOutlined, CheckCircleOutlined, CopyOutlined, SettingOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Radio, Row, Select, message } from 'antd'
import { compose, identity } from 'ramda'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Label, OptionsSectionHeader, Description } from '../../Typography'
import FollowallApps from './FollowallApps'

const FollowallOptionsContainer = styled.div`
    overflow: hidden;
    padding: 20px;
`

const OptionsFormRow = styled(Row)`
    margin: 15px 0px;
`

const FormContainer = styled.div`
    margin: 0px 0px 15px 0px;
`


const FollowallOptions = ({
    followallCode = '',
    iconSize = 'md',
    handleSelectIconSize = identity,
    iconType = 'icon',
    handleIconSetChange = identity
}) => {
    const [copyActive, setCopyActive] = useState(false)

    const copyText = compose(
        () => setCopyActive(true),
        () => message.info('copied to clipboard', 1.5, () => setCopyActive(false)),
        async () => await navigator.clipboard.writeText(followallCode),
    )

    const Addon = copyActive ? <CheckCircleOutlined /> : <CopyOutlined onClick={copyText} />

    const ICON_OPTIONS = [
        { label: 'Icon', value: 'icon' },
        { label: 'Badge', value: 'badge' },
    ]

    const WRAP_OPTIONS = [
        { label: 'Wrap', value: 'wrap' },
        { label: 'Scroll', value: 'scroll' },
    ]

    return (
        <FollowallOptionsContainer>
            <OptionsFormRow>
                <Col span={24}>
                    <OptionsSectionHeader><SettingOutlined /> Settings</OptionsSectionHeader>
                    <FormContainer>
                        <Label>Select player icon set:</Label>
                        <Radio.Group defaultValue={iconType} options={ICON_OPTIONS} onChange={handleIconSetChange} />
                    </FormContainer>
                </Col>
                <Col span={24}>
                    <FormContainer>
                        <Label>Select icon size:</Label>
                        <Select defaultValue={iconSize} onSelect={handleSelectIconSize}>
                            <Select.Option value='sm'>Small</Select.Option>
                            <Select.Option value='md'>Medium</Select.Option>
                            <Select.Option value='lg'>Large</Select.Option>
                        </Select>
                    </FormContainer>
                </Col>
                <Col span={24}>
                    <Button icon={Addon} onClick={copyText}>
                        Copy Followall Code
                    </Button>
                </Col>
            </OptionsFormRow>
            <Divider />
            <OptionsFormRow>
                <Row>
                    <Col flex="auto">
                        <OptionsSectionHeader><AppstoreAddOutlined /> Apps</OptionsSectionHeader>
                        <Description>Select individual apps to copy their specific embed code or direct links for use on your website.</Description>
                    </Col>
                </Row>
                <Col span={24}>
                    <FollowallApps iconSize={iconSize} iconType={iconType} />
                </Col>
            </OptionsFormRow>
        </FollowallOptionsContainer>
    )
}

export default FollowallOptions