import { compose } from "ramda"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchCombinedTimeseries, selectCombinedTimeseries, selectFetchingCombinedTimeseries, selectFetchingCombinedTimeseriesError } from "./combinedSlice"


const withCombined = (Component = React.Fragment) => (props = {}) => {
    const dispatch = useDispatch()

    const Combined = {
        combinedTimeseries: useSelector(selectCombinedTimeseries),
        fetchingCombinedTimeseries: useSelector(selectFetchingCombinedTimeseries),
        fetchingCombinedTimeseriesError: useSelector(selectFetchingCombinedTimeseriesError),

        dispatchFetchCombinedTimeseries: compose(dispatch, fetchCombinedTimeseries),
    }

    return <Component {...props} Combined={Combined}/>
}

export default withCombined