import React from "react";
import Promos from "./PromosContainer";
import NewPromo from "./NewPromo";

export default [
    {
        label: 'Promos',
        path: '/:id/promos',
        element: <Promos />
    },
    {
        path: '/:id/promos/new',
        element: <NewPromo />
    }
]