
import dayjs from "dayjs"
import { compose, flatten, identity, map, prop } from "ramda"
import React from "react"
import styled from "styled-components"
import { withPodcasts } from "../../Podcast"
import { getTopEpisodeTitleById } from "../../Utils"
import RankedChart from "../Charts/RankedChart"
import { DATE_FORMAT } from "../Constants"
import withConversion from "./withConversion"
import Card from "../../../Components/Card"
import theme from "../../../theme"
import { useFetchAnalytics } from "../useFetchAnalytics"
import withEpisodes from "../../Episode/withEpisodes"
import withSearchParams from "../../App/withSearchParams"

const ChartCard = styled(Card)`
    margin: 50px 0px;
`

const ConversionEpisodesChart = ({
    Conversion: { conversionEpisodes = [], dispatchFetchConversionEpisodes = identity } = {},
    Podcasts: { podcast = {} } = {},
    Episodes: { episodes = [] },
    SearchParams: {
        startDate = dayjs().subtract(7, 'day').startOf('day').format(DATE_FORMAT),
        endDate = dayjs().endOf('day').format(DATE_FORMAT),
        campaignId = null
    },
    interval = 'day',
    episodeGuid = ''
}) => {
    const { id: resourceId, slug: resourceSlug } = podcast

    useFetchAnalytics({
        handler: dispatchFetchConversionEpisodes,
        startDate,
        endDate,
        interval,
        resourceId,
        resourceSlug,
        episodeGuid,
        campaignId,
        limit: 5
    })

    return (
        <ChartCard title="Top Episodes by Conversion">
                <RankedChart
                    labels={compose(flatten, map(getTopEpisodeTitleById(episodes)), map(prop('episode_id')))(conversionEpisodes)}
                    datasets={[{
                        label: 'Conversions',
                        data: compose(flatten, map(prop('count')))(conversionEpisodes),
                        backgroundColor: theme['conversions-graph-color']
                    }]}
                />
        </ChartCard>
    )
}

export default compose(
    withConversion,
    withPodcasts,
    withEpisodes,
    withSearchParams
)(ConversionEpisodesChart)