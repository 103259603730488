import { Col, Row, Space } from 'antd'
import React from 'react'
import { BodyText, Header, PodCardsButton } from '../../Typography'
import { Link } from 'react-router-dom'
import Card from '../../Components/Card'
import styled from 'styled-components'

const NotFoundCard = styled(Card)`
    text-align: center;
`

const NotFoundButton = styled(PodCardsButton)`
    margin-top: 50px;
`

const NotFound = () => {
    return (
        <Row justify='center' align='middle'>
            <Col sm={{span: 24 }}>
                <NotFoundCard>
                <Header>Not Found</Header>
                <BodyText>We're sorry, the page you are looking for does not exist</BodyText>
                    <NotFoundButton>
                        <Link to={'/'}>Home</Link>
                    </NotFoundButton>
                </NotFoundCard>
            </Col>
        </Row>
    )
}

export default NotFound