import { compose } from "ramda"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchEpisodesByProgress, fetchPlayerSummary, fetchProgress, selectEpisodesByProgress, selectFetchingEpisodesByProgress, selectFetchingEpisodesByProgressError, selectFetchingPlayerSummary, selectFetchingPlayerSummaryError, selectFetchingProgress, selectFetchingProgressError, selectPlayerSummary, selectProgress } from "./playerSlice"

const withPlayer = (Component = React.Fragment) => (props = {}) => {
    const dispatch = useDispatch()

    const Player = {
        progress: useSelector(selectProgress),
        fetchingProgress: useSelector(selectFetchingProgress),
        fetchingProgressError: useSelector(selectFetchingProgressError),

        dispatchFetchProgress: compose(dispatch, fetchProgress),

        playerSummary: useSelector(selectPlayerSummary),
        fetchingPlayerSummary: useSelector(selectFetchingPlayerSummary),
        fetchingPlayerSummaryError: useSelector(selectFetchingPlayerSummaryError),

        dispatchFetchPlayerSummary: compose(dispatch, fetchPlayerSummary),

        episodesByProgress: useSelector(selectEpisodesByProgress),
        fetchingEpisodesByProgress: useSelector(selectFetchingEpisodesByProgress),
        fetchingEpisodesByProgressError: useSelector(selectFetchingEpisodesByProgressError),

        dispatchFetchEpisodesByProgress: compose(dispatch, fetchEpisodesByProgress),
    }

    return <Component {...props} Player={Player}/>
}

export default withPlayer