import { compose } from "ramda";
import withCombined from "./Combined/withCombined";
import withConversion from "./Conversion/withConversion";
import withPlayer from "./Player/withPlayer";
import withSession from "./Session/withSession";

export default compose(
    withSession,
    withPlayer,
    withConversion,
    withCombined
)