import { fetchCombinedTimeseries, fetchCombinedTimeseriesError, fetchCombinedTimeseriesSuccess } from "./combinedSlice"

export const ANALYTICS_COMBINED_FETCH = 'analytics:combined:get'
export const ANALYTICS_COMBINED_FETCH_SUCCESS = 'analytics:combined:get:success'
export const ANALYTICS_COMBINED_FETCH_ERROR = 'analytics:combined:get:failed'

export const events = {
    [fetchCombinedTimeseries().type]: ANALYTICS_COMBINED_FETCH,
}

export const handlers = {
    [ANALYTICS_COMBINED_FETCH_SUCCESS]: fetchCombinedTimeseriesSuccess,
    [ANALYTICS_COMBINED_FETCH_ERROR]: fetchCombinedTimeseriesError
}