import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import { fetchPodcast, fetchPodcasts, registerPodcast, searchPodcast, selectPodcast, selectPodcastFetching, selectPodcastRegistering, selectPodcastRegisteringError, selectPodcastSearchResults, selectPodcastSearching, selectPodcastSearchingError, selectPodcastUpdating, selectPodcasts, selectPodcastsFetching, selectPodcastsFetchingError, selectUnsavedChanges, updatePodcast, updatePodcastSettings } from "./podcastSlice"
import { compose } from 'ramda'

const withPodcasts = (Component = React.Fragment) => (props = {}) => {
    const dispatch = useDispatch()

    const Podcasts = {
        podcast: useSelector(selectPodcast),
        podcasts: useSelector(selectPodcasts),
        fetchingPodcasts: useSelector(selectPodcastsFetching),
        fetchingPodcast: useSelector(selectPodcastFetching),
        fetchingPodcastsError: useSelector(selectPodcastsFetchingError),
        registeringPodcast: useSelector(selectPodcastRegistering),
        registeringPodcastError: useSelector(selectPodcastRegisteringError),
        updatingPodcast: useSelector(selectPodcastUpdating),
        unsavedChanges: useSelector(selectUnsavedChanges),
        searchingPodcast: useSelector(selectPodcastSearching),
        searchingPodcastError: useSelector(selectPodcastSearchingError),
        searchingPodcastResults: useSelector(selectPodcastSearchResults),
    
        dispatchFetchPodcasts: compose(dispatch, fetchPodcasts),
        dispatchFetchPodcast: compose(dispatch, fetchPodcast),
        dispatchRegisterPodcast: compose(dispatch, registerPodcast),
        dispatchUpdatePodcastSettings: compose(dispatch, updatePodcastSettings),
        dispatchUpdatePodcast: compose(dispatch, updatePodcast),
        dispatchSearchPodcast: compose(dispatch, searchPodcast)
    }

    return <Component {...props} Podcasts={Podcasts} />
}

export default withPodcasts