import React from 'react'
import styled from 'styled-components'
import withLoading from '../App/withLoading'
import { Col, Row } from 'antd'
import Card from '../../Components/Card'
import { BlueLink, BlueListDot, BodyText, BodyTextContainer, Bold, PodCardsButton, Header, ListContainer, ListItem, ParagraphHeader, SectionHeader, SmallBodyText } from '../../Typography'
import { compose } from 'ramda'
import { withPodcasts } from '../Podcast'
import { Link } from 'react-router-dom'
import { LineChartOutlined, ScissorOutlined } from '@ant-design/icons'

const Container = styled.div`
    overflow: hidden;
`

const WelcomeHeader = styled(Header)`
    text-align: center;
`

const ThankYouContainer = styled.div`
    margin: 25px 0px;
    text-align: center;
`

const FeaturesContainer = styled(Row)`
    margin: 25px 0px;
    text-align: left;
`

const ButtonContainer = styled(Col)`
    justify-content: center;
    display: flex;
`

const Welcome = ({
    Podcasts: { podcast, fetchingPodcast }
}) => {
    const LoadingContainer = withLoading(Container)

    return (
        <LoadingContainer predicate={fetchingPodcast}>
            <Row justify='center' align='middle'>
                <Col sm={{ span: 24 }} lg={{ span: 16 }}>
                    <Card>
                        <WelcomeHeader>Welcome to Podcards! 🎉</WelcomeHeader>
                        <ThankYouContainer>
                            <BodyText>
                                Here are some of the new features you have access to:
                            </BodyText>
                            <FeaturesContainer justify='center'>
                                <Col sm={{ span: 24 }} lg={{ span: 18 }}>
                                    <ListContainer>
                                        <ListItem>
                                            <BlueListDot></BlueListDot>
                                            <BodyTextContainer>
                                                <SmallBodyText>
                                                    <Bold>Unlimited Historical Data:</Bold> track your growth performance over an unlimited time horizon and gain deeper insights into what's working. <br/> <BlueLink to={`/${podcast.id}/dashboard`}>See all your data <LineChartOutlined /></BlueLink>
                                                </SmallBodyText>
                                            </BodyTextContainer>
                                        </ListItem>
                                        <ListItem>
                                            <BlueListDot></BlueListDot>
                                            <BodyTextContainer>
                                                <SmallBodyText>
                                                    <Bold>Podcard Clips:</Bold> introduce new listeners to your podcast through short clips to pull them in. <br/><BlueLink to={`/${podcast.id}/podcard`}>Create a clip now! <ScissorOutlined /></BlueLink>
                                                </SmallBodyText>
                                            </BodyTextContainer>
                                        </ListItem>
                                    </ListContainer>
                                </Col>
                                <ButtonContainer flex='100%'>
                                    <PodCardsButton>
                                        <Link to={`/${podcast.id}/dashboard`}>Go to your dashboard</Link>
                                    </PodCardsButton>
                                </ButtonContainer>
                            </FeaturesContainer>
                        </ThankYouContainer>
                    </Card>
                </Col>
            </Row>
        </LoadingContainer>
    )
}

export default compose(
    withPodcasts
)(Welcome)