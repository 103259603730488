import { compose } from "ramda"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchPromoSummary, fetchPromoTimeseries, selectFetchingPromoSummary, selectFetchingPromoSummaryError, selectFetchingPromoTimeseries, selectFetchingPromoTimeseriesError, selectPromoSummary, selectPromoTimeseries } from "./promoSlice"
import { withConnection } from "../../Connection"
import withSearchParams from "../../App/withSearchParams"
import { DATE_FORMAT } from "../Constants"
import dayjs from "dayjs"

const withSearchParamsAndConnection = compose(
    withSearchParams,
    withConnection
)

const withPromoAnalytics = (Component = React.Fragment) => withSearchParamsAndConnection((props = {}) => {
    const { 
        Connection: { 
            connected = false
        },
        SearchParams: {
            startDate = dayjs().subtract(7, 'day').startOf('day').format(DATE_FORMAT),
            endDate = dayjs().endOf('day').format(DATE_FORMAT),
            promoId = ''
        }
    } = props

    const dispatch = useDispatch()

    const PromoAnalytics = {
        promoSummary: useSelector(selectPromoSummary),
        fetchingPromoSummary: useSelector(selectFetchingPromoSummary),
        fetchingPromoSummaryError: useSelector(selectFetchingPromoSummaryError),

        dispatchFetchPromoSummary: compose(dispatch, fetchPromoSummary),

        promoTimeseries: useSelector(selectPromoTimeseries),
        fetchingPromoTimeseries: useSelector(selectFetchingPromoTimeseries),
        fetchingPromoTimeseriesError: useSelector(selectFetchingPromoTimeseriesError),

        dispatchFetchPromoTimeseries: compose(dispatch, fetchPromoTimeseries)
    }

    useEffect(() => {
        if (connected && promoId) {
            const params = {
                startDate,
                endDate,
                interval: 'day',
                resourceId: promoId
            }

            PromoAnalytics.dispatchFetchPromoSummary({ params })
            PromoAnalytics.dispatchFetchPromoTimeseries({ params })
        }
    }, [connected, startDate, endDate, promoId])

    return <Component {...props} PromoAnalytics={PromoAnalytics}/>
})

export default withPromoAnalytics