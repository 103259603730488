import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { undefinedFilter } from '../Utils/Search'
import { getSearchParams } from '../Utils'
import { compose } from 'ramda'
import dayjs from 'dayjs'
import { DATE_FORMAT } from '../Analytics/Constants'

const withSearchParams = (Component = React.Fragment) => (props = {}) => {
    const [ searchParams, setSearchParams ] = useSearchParams()
    const searchParamsObject = getSearchParams(searchParams.toString())
    
    const setSearchParamsItem = (key = '') => compose(
        setSearchParams,
        undefinedFilter,
        value => ({ ...searchParamsObject, [key]: value }),
    )

    const setStartEndDate = compose(
        setSearchParams,
        undefinedFilter,
        ([startDate, endDate]) => ({ ...searchParamsObject, startDate, endDate }),
    )

    const SearchParams = {
        setSearchParamsEpisodeId: setSearchParamsItem('episodeId'),
        setSearchParamsPromoId: setSearchParamsItem('promoId'),
        setSearchParamsCampaignId: setSearchParamsItem('campaignId'),
        setSearchParamsTab: setSearchParamsItem('tab'),
        setSearchParamsStartDate: setSearchParamsItem('startDate'),
        setSearchParamsEndDate: setSearchParamsItem('endDate'),
        setSearchParamsStartEndDate: setStartEndDate,
        startDate: dayjs().subtract(7, 'day').startOf('day').format(DATE_FORMAT),
        endDate: dayjs().endOf('day').format(DATE_FORMAT),
        ...searchParamsObject
    }

    return <Component {...props} SearchParams={SearchParams} />
}

export default withSearchParams