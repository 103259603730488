import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { connectionMiddleWare } from '../Connection'
import { eventHandlerMiddleware } from '../Connection/connection.middleware'
import rootReducer from './rootReducer'
import rootSaga from './rootSaga'

const sagaMiddleware = createSagaMiddleware()

export default configureStore({
  reducer: rootReducer,
  middleware: (
    getDefaultMiddleware = () => ([])) => ([
        ...getDefaultMiddleware({ thunk: false }),
        sagaMiddleware,
        connectionMiddleWare,
        eventHandlerMiddleware
    ])
})

sagaMiddleware.run(rootSaga)
