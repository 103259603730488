import React, { useEffect, useState } from "react"
import { compose } from "ramda"
import withLinks from "./withLinks"
import { Col, Input, List, Row } from "antd"
import { withPodcasts } from "../Podcast"
import { testUrl } from "../../Utils/string.utils"
import styled from "styled-components"
import { getLinkIcon } from "../../Utils/icon.utils"
import { CloseCircleFilled, PlusOutlined } from "@ant-design/icons"
import theme from "../../theme"
import ConnectedButton from "../Shared/ConnectedButton"

const Container = styled(Row)``

const InputContainer = styled(Col)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const LinkInput = styled(Input)`
    margin: 0px 20px 0px 0px;
`

const LinkList = styled(List)`

`

const LinksContainer = styled(Col)``

const LinkDeleteButton = styled(CloseCircleFilled)`
    color: ${theme['bright-white']}
`

const Links = ({
    Podcasts: { podcast: { id: podcastId } },
    Links: { links, creatingLink, deletingLink, dispatchGetLinks, dispatchCreateLink, dispatchDeleteLink }
}) => {

    const [link, setLink] = useState(null)

    useEffect(() => {
        if (podcastId) {
            dispatchGetLinks({ podcastId })
        }
    }, [podcastId])

    const handleLinkChange = ({ target: { value } }) => {
        setLink(value)
    }

    const handleCreateLink = () => {
        dispatchCreateLink({ link, podcastId })
        setLink(null)
    }

    const handleDeleteLink = (link) => {
        dispatchDeleteLink(link)
    }


    <LinkDeleteButton onClick={() => handleDeleteLink(link)} />
    return (
        <Container>
            <InputContainer xs={24}>
                <LinkInput
                    onChange={handleLinkChange}
                    value={link}
                />
                <ConnectedButton
                    content={<PlusOutlined />}
                    disabled={!testUrl(link)}
                    loading={creatingLink}
                    style={{
                        border: 'none',
                        background: 'transparent',
                        height: 'auto'
                    }}
                    handleClick={handleCreateLink}
                />
            </InputContainer>
            <LinksContainer xs={24}>
                <LinkList
                    itemLayout="horizontal"
                    dataSource={links}
                    renderItem={(link, index) => (
                        <List.Item actions={[
                            <ConnectedButton
                                content={<LinkDeleteButton onClick={() => handleDeleteLink(link)} />}
                                style={{
                                    border: 'none',
                                    background: 'transparent',
                                    height: 'auto'
                                }}
                                loading={deletingLink}
                            />
                        ]}>
                            <List.Item.Meta
                                avatar={getLinkIcon(link.link)}
                                title={<a href={link.link} target="_blank">{link.link}</a>}
                            />
                        </List.Item>
                    )}
                >
                </LinkList>
            </LinksContainer>
        </Container>
    )
}

export default compose(
    withLinks,
    withPodcasts
)(Links)