import { fetchEpisodes, fetchEpisodesError, fetchEpisodesSuccess } from "./episodeSlice"

export const EPISODE_FETCH_BY_PODCAST = 'episode:get:podcast:id'
export const EPISODE_FETCH_BY_PODCAST_SUCCESS = 'episode:get:podcast:id:success'
export const EPISODE_FETCH_BY_PODCAST_FAILED = 'episode:get:podcast:id:failed'

export const events = {
    [fetchEpisodes().type]: EPISODE_FETCH_BY_PODCAST
}

export const handlers = {
    [EPISODE_FETCH_BY_PODCAST_SUCCESS]: fetchEpisodesSuccess,
    [EPISODE_FETCH_BY_PODCAST_FAILED]: fetchEpisodesError,
}