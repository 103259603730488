import React from 'react'
import { Row, Col } from "antd"
import Card from "../../Components/Card"
import styled from 'styled-components'
import Options from './Charts/Options'
import CombinedTimeseriesChart from './Combined/CombinedTimeseriesChart'

const Container = styled.div`
    width: 100%;
    overflow: hidden;
`

const SummaryCard = styled(Card)`
    margin: 0px 0px 50px 0px;
`

const ChartConatiner = styled.div`
    margin: 50px 0px;
`

const AnalyticsSummary = () => {
    return (
        <Container>
            <Options disablePromos={true} />
            <Col span={24}>
                <ChartConatiner>
                    <SummaryCard title="Podcard Performance Summary">
                        <CombinedTimeseriesChart interval='day' />
                    </SummaryCard>
                </ChartConatiner>
            </Col>
        </Container>
    )
}

export default AnalyticsSummary