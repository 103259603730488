import React from "react"
import styled from "styled-components"
import { RedHeader } from "../Typography"
import theme from "../theme"
import PodcardsLogotype from '../app/assets/images/PodcardsLogotype.png'
import { Image } from "antd"

const HeaderTitle = styled.h1`
    font-size: ${({ size }) => size};
    font-weight: 800;
    margin: 0px;
`

const HeaderLink = styled.a`
    color: ${theme["primary-black"]}
`

export const Logo = ({ size = '1.5rem', type = 'standard', href = process.env.FLYWHEEL_WEBSITE }) => (
    <HeaderLink href={href}>
        <HeaderTitle size={size} >
            { type === 'dark' 
                ? 'Pod.Cards'
                : <Image src={PodcardsLogotype} height={24} width={108} preview={false} />}
        </HeaderTitle>
    </HeaderLink>
)

export const PodcardsLogo = ({ size = '1.5rem', weight = '600', type = 'standard', href = process.env.PODCARDS_WEBSITE }) => (
    <HeaderLink href={href} target="_blank">
        <HeaderTitle size={size} style={{ fontWeight: weight, color: type === 'dark' ? theme['primary-black'] : theme['bright-white']}}>
            Pod<RedHeader>.</RedHeader>Cards
        </HeaderTitle>
    </HeaderLink>
)