import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import FollowSection from './FollowSection'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { identity } from 'ramda'
import theme from '../theme'
import About from './About'
import Menu from './Menu'

const DrawerContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 0px;
    bottom: ${({ bottom }) => `${bottom}px`};
    transition-property: bottom;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
`

const DetailsContainer = styled.div`
    margin: 0px 0px 0px 0px;
    padding: 10px 0px 20px 0px;
    background: ${({ settings: { gradientTopColor = [] } }) => `rgba(${gradientTopColor.join(',')}, 0.95)`};
    overflow-y: scroll;
    height: 325px;
    scroll-bar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }

`

const MoreButton = styled.div`
    height: 25px;
    border-radius: 15px 15px 0px 0px;
    background: ${({ settings: { gradientTopColor = [] } }) => `rgba(${gradientTopColor.join(',')}, 1)`};
    color: ${theme['bright-white']};
    text-align: center;
    width: 100%;
`

const Drawer = ({
    podcast = {},
    open = false,
    setOpen = identity,
    children
}) => {

    const { settings = {} } = podcast
    const [drawerBottom, setDrawerBottom] = useState(0)

    useEffect(() => {
        const bottom = open ? 350 : 0;
        setDrawerBottom(bottom)
    }, [open])

    const icon = open ? <CaretDownOutlined style={{ fontSize: '1.5rem' }} /> : <CaretUpOutlined style={{ fontSize: '1.5rem' }} />

    return (
        <DrawerContainer bottom={drawerBottom}>
            <MoreButton settings={settings} onClick={() => setOpen(!open)}>{icon}</MoreButton>
            <DetailsContainer settings={settings}>
                {children}
            </DetailsContainer>
        </DrawerContainer>
    )
}

export const FollowDrawer = ({
    podcast = {},
    episodeGuid = '',
    sessionId = '',
    open = false,
    setOpen = identity
}) => {
    return (
        <Drawer open={open} setOpen={setOpen} podcast={podcast}>
            <FollowSection
                podcast={podcast}
                episodeGuid={episodeGuid}
                sessionId={sessionId}
                wrapBehavior='wrap'
            />
        </Drawer>
    )
}

export const MoreDrawer = ({
    podcast = {},
    open = false,
    setOpen = identity
}) => {

    return (
        <Drawer open={open} setOpen={setOpen} podcast={podcast}>
            <About podcast={podcast} />
        </Drawer>
    )
}

export const MenuDrawer = ({
    podcast = {},
    open = false,
    setOpen = identity
}) => {

    return (
        <Drawer open={open} setOpen={setOpen} podcast={podcast}>
            <Menu podcast={podcast} />
        </Drawer>
    )
}