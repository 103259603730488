import { fetchLocations, fetchLocationsError, fetchLocationsSuccess, fetchReferers, fetchReferersByProgress, fetchReferersByProgressError, fetchReferersByProgressSuccess, fetchReferersError, fetchReferersSuccess, fetchSessions, fetchSessionsError, fetchSessionsSuccess, fetchSessionsSummary, fetchSessionsSummaryError, fetchSessionsSummarySuccess } from "./sessionSlice"

export const ANALYTICS_SESSIONS_FETCH = 'analytics:sessions:get'
export const ANALYTICS_SESSIONS_FETCH_SUCCESS = 'analytics:sessions:get:success'
export const ANALYTICS_SESSIONS_FETCH_ERROR = 'analytics:sessions:get:failed'

export const ANALYTICS_SESSIONS_SUMMARY_FETCH = 'analytics:sessions:summaries:get'
export const ANALYTICS_SESSIONS_SUMMARY_FETCH_SUCCESS = 'analytics:sessions:summaries:get:success'
export const ANALYTICS_SESSIONS_SUMMARY_FETCH_ERROR = 'analytics:sessions:summaries:get:failed'

export const ANALYTICS_REFERERS_FETCH = 'analytics:referers:get'
export const ANALYTICS_REFERERS_FETCH_SUCCESS = 'analytics:referers:get:success'
export const ANALYTICS_REFERERS_FETCH_ERROR = 'analytics:referers:get:failed'

export const ANALYTICS_LOCATIONS_FETCH = 'analytics:locations:get'
export const ANALYTICS_LOCATIONS_FETCH_SUCCESS = 'analytics:locations:get:success'
export const ANALYTICS_LOCATIONS_FETCH_ERROR = 'analytics:locations:get:failed'

export const ANALYTICS_REFERERS_PROGRESS_FETCH = 'analytics:referers:progress:get'
export const ANALYTICS_REFERERS_PROGRESS_FETCH_SUCCESS = 'analytics:referers:progress:get:success'
export const ANALYTICS_REFERERS_PROGRESS_FETCH_ERROR = 'analytics:referers:progress:get:failed'

export const events = {
    [fetchSessions().type]: ANALYTICS_SESSIONS_FETCH,
    [fetchSessionsSummary().type]: ANALYTICS_SESSIONS_SUMMARY_FETCH,
    [fetchReferers().type]: ANALYTICS_REFERERS_FETCH,
    [fetchLocations().type]: ANALYTICS_LOCATIONS_FETCH,
    [fetchReferersByProgress().type]: ANALYTICS_REFERERS_PROGRESS_FETCH,
}

export const handlers = {
    [ANALYTICS_SESSIONS_FETCH_SUCCESS]: fetchSessionsSuccess,
    [ANALYTICS_SESSIONS_FETCH_ERROR]: fetchSessionsError,
    [ANALYTICS_SESSIONS_SUMMARY_FETCH_SUCCESS]: fetchSessionsSummarySuccess,
    [ANALYTICS_SESSIONS_SUMMARY_FETCH_ERROR]: fetchSessionsSummaryError,
    [ANALYTICS_REFERERS_FETCH_SUCCESS]: fetchReferersSuccess,
    [ANALYTICS_REFERERS_FETCH_ERROR]: fetchReferersError,
    [ANALYTICS_LOCATIONS_FETCH_SUCCESS]: fetchLocationsSuccess,
    [ANALYTICS_LOCATIONS_FETCH_ERROR]: fetchLocationsError,
    [ANALYTICS_REFERERS_PROGRESS_FETCH_SUCCESS]: fetchReferersByProgressSuccess,
    [ANALYTICS_REFERERS_PROGRESS_FETCH_ERROR]: fetchReferersByProgressError,
}