import React from "react";
import { Slider } from "antd"
import { identity } from "ramda"
import styled from "styled-components";

const ProgressSlider = styled(Slider)`
    display: ${({ display = true }) => display ? '' : 'none'};
    .ant-slider-dot {
        display: none !important;
    }
`

export const PlayerProgressSlider = ({
    settings = [],
    currentTime = 0,
    duration = 100,
    seekTo = identity,
    seekTime = 0,
    display = true
}) => {
    const { accentColor = [] } = settings;

    const sliderValue = seekTime > currentTime ? seekTime : currentTime

    const getFormattedTimeFromSeconds = (numerator = 0) => {
        const zeroPad = seconds => seconds < 10 ? `0${Math.round(seconds)}` : `${Math.round(seconds)}`

        const hour = numerator > 3600 ? Math.floor(numerator / 3600) : 0
        const minute = Math.floor((numerator % 3600) / 60)
        const second = Math.floor(Math.floor((numerator % 3600)) % 60)

        return `${hour > 0 ? zeroPad(hour) + ':' : ''}${zeroPad(minute)}:${zeroPad(second)}`
    }

    const marks = {
        0: {
            label: getFormattedTimeFromSeconds(sliderValue),
            style: {
                color: '#FFFFFF'
            }
        },
        [duration]: {
            label: getFormattedTimeFromSeconds(duration),
            style: {
                color: '#FFFFFF'
            }
        }
    }

    return (
        <ProgressSlider
            display={display}
            marks={marks}
            value={sliderValue}
            max={duration}
            step={1}
            onChange={(value) => seekTo(value)}
            tooltip={{
                open: false
            }}
            trackStyle={{
                backgroundColor: `rgba(${accentColor.join(',')}, 0.5)`
            }}
            handleStyle={{
                border: `#181815`,
                backgroundColor: `#181815`
            }}
        />
    )
}