import dayjs from 'dayjs'
import { evolve, map } from 'ramda'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { authenticate, selectUser } from './authSlice'

const WithAuth = ({ children }) => {
    const dispatch = useDispatch()
    const user = useSelector(selectUser)
    const { pathname, search } = useLocation()
    const expiration = dayjs.unix(user.exp)

    const isValidAccessToken = dayjs().isBefore(expiration)

    useEffect(() => {
        if (!isValidAccessToken) {
            dispatch(authenticate(`${pathname}${search}`))
        }
    }, [isValidAccessToken])

    return isValidAccessToken ? children : null
}

export const protectRoutes = map(route => evolve({
    element: Component => <WithAuth>{Component}</WithAuth>,
    children: protectRoutes
}, route))

export default WithAuth