import React from "react"
import Auth from "./Auth"
import AuthResponse from "./AuthReseponse"

export default [
    {
        path: '/auth',
        element: <Auth />
    },
    {
        path: '/auth/reponse',
        element: <AuthResponse />
    }
]