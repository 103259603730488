import React, { useEffect, useState } from 'react'
import { compose, identity } from "ramda"
import styled from "styled-components"
import Card from "../../Components/Card"
import { Form, Input, Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import { withPodcasts } from '../Podcast'
import ConnectedButton from '../Shared/ConnectedButton'
import withPromos from './withPromos'

const NewPromoCard = styled(Card)``

const NewPromo = ({
    Podcasts: { podcast, podcasts },
    Promos: {
        creatingPromo = false,
        dispatchCreatePromo = identity,
    }
}) => {
    const navigate = useNavigate()

    const [submitted, setSubmitted] = useState(false)

    useEffect(() => {
        if (submitted && !creatingPromo) {
            navigate(`/${podcast.id}/promos`)
        }
    }, [creatingPromo])

    const onSubmit = (values = {}) => {
        dispatchCreatePromo(values)
        setSubmitted(true)
    }

    return (
        <NewPromoCard title='New Promo'>
            <Form
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 8 }}
                onFinish={onSubmit}
            >
                <Form.Item label='Name' name='name' rules={[{ required: true }]}>
                    <Input placeholder='A memorable promo name'/>
                </Form.Item>
                <Form.Item label='Podcast' name='podcastId' rules={[{ required: true }]}>
                    <Select placeholder='The podcast you are promoting' allowClear>
                        {podcasts.map((podcast) => <Option value={podcast.id}>{podcast.title}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label='Provider' name='provider' rules={[{ required: true }]}>
                    <Select placeholder='Your DAI provider' allowClear>
                        <Option value='megaphone'>Megaphone</Option>
                    </Select>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 2, span: 8 }}>
                    <ConnectedButton
                        content='Submit'
                        htmlType="submit"
                        loading={creatingPromo}
                    />
                </Form.Item>
            </Form>
        </NewPromoCard>
    )
}

export default compose(
    withPromos,
    withPodcasts
)(NewPromo)