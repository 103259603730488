import React from 'react'
import styled from 'styled-components'
import withLoading from '../App/withLoading'
import { Col, Row } from 'antd'
import Card from '../../Components/Card'
import { BlueListDot, BodyText, BodyTextContainer, Bold, Header, ListContainer, ListItem, SmallBodyText } from '../../Typography'
import { compose } from 'ramda'
import UpgradeButton from '../../Components/Upgrades'
import withCustomer from './withCustomer'

const Container = styled.div`
    overflow: hidden;
`

const WelcomeHeader = styled(Header)`
    text-align: center;
`

const ThankYouContainer = styled.div`
    margin: 25px 0px;
    text-align: center;
`

const FeaturesContainer = styled(Row)`
    margin: 25px 0px;
    text-align: left;
`

const ButtonContainer = styled(Col)`
    justify-content: center;
    display: flex;
`

const PromoPaywall = ({
    Customer: { fetchingCustomer = true }
}) => {
    const LoadingContainer = withLoading(Container)

    return (
        <LoadingContainer predicate={fetchingCustomer}>
            <Row justify='center' align='middle'>
                <Col sm={{ span: 24 }} lg={{ span: 16 }}>
                    <Card>
                        <WelcomeHeader>Coming Soon: Promos!</WelcomeHeader>
                        <ThankYouContainer>
                            <BodyText>
                                Promote your podcast on other podcasts and track their impact on your audience development with Promos.
                            </BodyText>
                            <FeaturesContainer justify='center'>
                                <Col sm={{ span: 24 }} lg={{ span: 18 }}>
                                    <ListContainer>
                                        <ListItem>
                                            <BlueListDot></BlueListDot>
                                            <BodyTextContainer>
                                                <SmallBodyText>
                                                    <Bold>Deep audio insights:</Bold> Track promos on your own network or colloborate with others and promote your show anywhere
                                                </SmallBodyText>
                                            </BodyTextContainer>
                                        </ListItem>
                                        <ListItem>
                                            <BlueListDot></BlueListDot>
                                            <BodyTextContainer>
                                                <SmallBodyText>
                                                    <Bold>Compatible with any show:</Bold> Track promos on any show that supports the Podcards promo tracking pixel
                                                </SmallBodyText>
                                            </BodyTextContainer>
                                        </ListItem>
                                    </ListContainer>
                                </Col>
                            </FeaturesContainer>
                        </ThankYouContainer>
                    </Card>
                </Col>
            </Row>
        </LoadingContainer>
    )
}

export default compose(
    withCustomer
)(PromoPaywall)