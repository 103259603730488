import { fetchPromoSummary, fetchPromoSummaryError, fetchPromoSummarySuccess, fetchPromoTimeseries, fetchPromoTimeseriesError, fetchPromoTimeseriesSuccess } from "./promoSlice"

export const ANALYTICS_PROMO_SUMMARY_FETCH = 'analytics:promo:summaries:get'
export const ANALYTICS_PROMO_SUMMARY_FETCH_SUCCESS = 'analytics:promo:summaries:get:success'
export const ANALYTICS_PROMO_SUMMARY_FETCH_ERROR = 'analytics:promo:summaries:get:failed'

export const ANALYTICS_PROMO_TIMESERIES_FETCH = 'analytics:promo:conversion:timeseries:get'
export const ANALYTICS_PROMO_TIMESERIES_FETCH_SUCCESS = 'analytics:promo:conversion:timeseries:get:success'
export const ANALYTICS_PROMO_TIMESERIES_FETCH_ERROR = 'analytics:promo:conversion:timeseries:get:failed'

export const events = {
    [fetchPromoSummary().type]: ANALYTICS_PROMO_SUMMARY_FETCH,
    [fetchPromoTimeseries().type]: ANALYTICS_PROMO_TIMESERIES_FETCH
}

export const handlers = {
    [ANALYTICS_PROMO_SUMMARY_FETCH_SUCCESS]: fetchPromoSummarySuccess,
    [ANALYTICS_PROMO_SUMMARY_FETCH_ERROR]: fetchPromoSummaryError,
    [ANALYTICS_PROMO_TIMESERIES_FETCH_SUCCESS]: fetchPromoTimeseriesSuccess,
    [ANALYTICS_PROMO_TIMESERIES_FETCH_ERROR]: fetchPromoTimeseriesError
}