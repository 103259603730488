const { test, replace, compose, defaultTo, tap } = require("ramda");
const purify = require('isomorphic-dompurify')


export const testUrl = test(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/)

// String -> String
export const escapeHtml = compose(
    purify.sanitize,
    replace(/&lt;/g, "<"),
    replace(/&gt;/g, ">"),
    replace(/&quot;/g, "\""),
    replace(/&#39;/g, "\'"),
    replace(/&amp;/g, "&"),
    defaultTo('')
)

export const stripHtml = (html = '') => {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
}

export const escapeAndStripHtml = compose(
    replace(/(<([^>]+)>)/ig, ''),
    escapeHtml,
    defaultTo('')
)