import { createSlice } from "@reduxjs/toolkit"
import { mergeDeepLeft, path } from "ramda"

const initialState = {
    user: {},
    email: '',
    password: '',
    redirectPath: '',
    authenticationHash: '',
    authenticating: false,
    authenticationError: null
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authenticate: (state, action) => mergeDeepLeft({ 
            authenticating: true,
            redirectPath: action.payload
        }, state),
        register: (state, action) => mergeDeepLeft({
            authenticating: true,
            name: action.payload.name,
            email: action.payload.email,
            password: action.payload.password
        }, state),
        logout: (state) => mergeDeepLeft(initialState, state),
        parseAuthenticationHash: (state, action) => mergeDeepLeft({
            authenticationHash: action.payload
        }, state),
        authenticateSuccess: (state, action) => mergeDeepLeft({
            authenticating: false,
            user: action.payload,
            email: '',
            password: '',
            authenticationHash: ''
        }, state),
        authenticateError: (state, action) => mergeDeepLeft({
            authenticating: false,
            user: {},
            authenticationError: action.payload,
            email: '',
            password: '',
            authenticationHash: ''
        }, state)
    }
})

export const { authenticate, register, logout, parseAuthenticationHash, authenticateSuccess, authenticateError } = authSlice.actions

export const selectUser = path(['auth', 'user'])
export const selectRedirectPath = path(['auth', 'redirectPath'])
export const selectAuthenticating = path(['auth', 'authenticating'])
export const selectEmail = path(['auth', 'email'])
export const selectPassword = path(['auth', 'password'])
export const selectAuthenticationHash = path(['auth', 'authenticationHash'])
export const selectAuthenticationToken = path(['auth', 'user', 'accessToken'])

export default authSlice.reducer