import { createSlice } from "@reduxjs/toolkit"
import { mergeDeepLeft, path } from "ramda"

const initialState = {
    customer: {
        plans: []
    },
    fetchingCustomer: true,
    fetchingCustomerError: null,
    checkoutLink: null,
    fetchingCheckoutLink: false,
    fetchingCheckoutLinkError: null,
}

const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        fetchCustomer: (state = {}) => mergeDeepLeft({
            fetchingCustomer: true,
        }, state),
        fetchCustomerByCheckoutId: (state = {}) => mergeDeepLeft({
            fetchingCustomer: true,
        }, state),
        fetchCustomerSuccess: (state = {}, action = {}) => mergeDeepLeft({
            customer: action.payload,
            fetchingCustomer: false,
            fetchingCustomerError: null
        }, state),
        fetchCustomerError: (state = {}, action = {}) => mergeDeepLeft({
            customer: {},
            fetchingCustomer: false,
            fetchingCustomerError: action.payload
        }, state),
        fetchCheckoutLink: (state = {}) => mergeDeepLeft({
            fetchingCheckoutLink: true,
        }, state),
        fetchCheckoutLinkSuccess: (state = {}, action = {}) => mergeDeepLeft({
            checkoutLink: action.payload,
            fetchingCheckoutLink: false,
            fetchingCheckoutLinkError: null
        }, state),
        fetchCheckoutLinkError: (state = {}, action = {}) => mergeDeepLeft({
            checkoutLink: null,
            fetchingCheckoutLink: false,
            fetchingCheckoutLinkError: action.payload
        }, state),
    }
})

export const {
    fetchCustomer,
    fetchCustomerByCheckoutId,
    fetchCustomerSuccess,
    fetchCustomerError,
    fetchCheckoutLink,
    fetchCheckoutLinkSuccess,
    fetchCheckoutLinkError
} = customerSlice.actions

const selectCustomerPath = (props = []) => path(['customer', ...props])

export const selectCustomer = selectCustomerPath(['customer'])
export const selectCustomerFetching = selectCustomerPath(['fetchingCustomer'])
export const selectCustomerFetchingError = selectCustomerPath(['fetchingCustomerError'])
export const selectCheckoutLink = selectCustomerPath(['checkoutLink'])
export const selectCheckoutLinkFetching = selectCustomerPath(['fetchingCheckoutLink'])
export const selectCheckoutLinkFetchingError = selectCustomerPath(['fetchingCheckoutLinkError'])

export default customerSlice.reducer