import React from "react"
import { Button } from "antd"
import styled from "styled-components"
import { LoadingOutlined, PauseCircleFilled, PlayCircleFilled } from "@ant-design/icons"
import { identity } from "ramda"

const Container = styled.div` 
    text-align: center;
`

const LoadingButton = styled(LoadingOutlined)`
    font-size: 60px;
    line-height: 60px;
    font-weight: 100;
    color: #FFFFFF
`

const PlayButton = styled(PlayCircleFilled)`
    font-size: 60px;
    line-height: 60px;
    font-weight: 100;
    color: #FFFFFF
`

const PauseButton = styled(PauseCircleFilled)`
    font-size: 60px;
    line-height: 60px;
    font-weight: 100;
    color: #FFFFFF
`

const PlayEpisodeButton = styled(Button)`
    background: transparent;
    border-radius: 15px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 0.75rem;
    margin: 10px 0px;
    left: -50px;
`

const ButtonsContainer = styled.div`
`

export const PlayerActionButton = ({
    isBuffering = true,
    playing = false,
    onPlay = identity,
    onPause = identity,
    audioType = 'episode',
    handleQueueEpisode = identity,
    currentTime = 0
}) => {
    return (
        <Container>
            <ButtonsContainer align="center">
            {
                isBuffering 
                    ? <LoadingButton spin />
                        : audioType === 'clip' && currentTime > 0
                        ? <PlayEpisodeButton onClick={handleQueueEpisode}>Full Episode</PlayEpisodeButton>
                            : playing 
                                ? <PauseButton onClick={onPause} />
                                : <PlayButton onClick={onPlay} />
            }
            </ButtonsContainer>            
        </Container>
    )
}