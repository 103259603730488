import axios from "axios"

export default {
    fetch: async () => {
        const { data } = await axios({
            method: 'GET',
            url: `${process.env.FLYWHEEL_ENDPOINT}/feed/parse?feedUrl=${encodeURIComponent(feedUrl)}`
        })        
    },
    fetchPodcasts: async () => {
        const { data } = await axios({
            method: 'GET',
            url: `${process.env.FLYWHEEL_ENDPOINT}/podcasts`
        })

        return data
    },    
    register: () => {},
    search: async (q) => {
        const { data } = await axios({
            method: 'GET',
            url: `${process.env.FLYWHEEL_ENDPOINT}/podcasts/search?q=${encodeURIComponent(q)}`
        })

        return data
    }
}
