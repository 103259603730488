import { listClips, listClipsError, listClipsSuccess, uploadClipError, uploadClipSuccess } from "./clipSlice"

export const CLIP_LIST = 'clip:list:episode:id'
export const CLIP_LIST_SUCCESS = 'clip:list:episode:id:success'
export const CLIP_LIST_FAILED = 'clip:list:episode:id:failed'

export const CLIP_UPLOAD_SUCCESS = 'clip:create:success'
export const CLIP_UPLOAD_FAILED = 'clip:create:failed'

export const events = {
    [listClips().type]: CLIP_LIST
}

export const handlers = {
    [CLIP_LIST_SUCCESS]: listClipsSuccess,
    [CLIP_LIST_FAILED]: listClipsError,
    [CLIP_UPLOAD_SUCCESS]: uploadClipSuccess,
    [CLIP_UPLOAD_FAILED]: uploadClipError
}