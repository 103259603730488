import { createSlice } from "@reduxjs/toolkit"
import { mergeDeepLeft, path } from "ramda"


const initialState = {
    episodes: [],
    fetchingEpisodes: false,
    fetchingEpisodesError: null
}

const episodeSlice = createSlice({
    name: 'episode',
    initialState,
    reducers: {
        fetchEpisodes: (state) => mergeDeepLeft({
            fetchingEpisodes: true,
            episodes: [],
            fetchEpisodesError: null
        }, state),
        fetchEpisodesSuccess: (state, action) => mergeDeepLeft({
            fetchingEpisodes: false,
            episodes: action.payload,
            fetchEpisodesError: null
        }, state),
        fetchEpisodesError: (state, action) => mergeDeepLeft({
            fetchingEpisodes: false,
            episodes: [],
            fetchEpisodesError: action.payload
        }, state),
    }
})

export const {
    fetchEpisodes,
    fetchEpisodesSuccess,
    fetchEpisodesError
} = episodeSlice.actions

const selectEpisodePath = (props = []) => path(['episode', ...props])

export const selectEpisodes = selectEpisodePath(['episodes'])
export const selectFetchingEpisodes = selectEpisodePath(['fetchingEpisodes'])
export const selectFetchingEpisodesError = selectEpisodePath(['fetchingEpisodesError'])

export default episodeSlice.reducer