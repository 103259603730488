import React  from 'react'
import { useDispatch, useSelector } from "react-redux"
import { listClips, selectClips, selectlistingClips, selectlistingClipsError, selectuploadingClip, selectuploadingClipError, uploadClip } from "./clipSlice"
import { compose } from 'ramda'
import clipApi from './clip.api'

const withClips = (Component = React.Fragment) => (props = {}) => {
    const dispatch = useDispatch()

    const Clips = {
        clips: useSelector(selectClips),
        fetchingClips: useSelector(selectlistingClips),
        fetchingClipsError: useSelector(selectlistingClipsError),
        uploadingClip: useSelector(selectuploadingClip),
        uploadingClipError: useSelector(selectuploadingClipError),
    
        dispatchlistClips: compose(dispatch, listClips),
        uploadClip: (file, podcastId, episodeId, token) => {
            dispatch(uploadClip())
            clipApi.upload(file, podcastId, episodeId, token)
        },
    }

    return <Component {...props} Clips={Clips} />
}

export default withClips