import React, { useState } from "react"
import { compose, map } from "ramda"
import withPodcasts from "./withPodcasts"
import styled from "styled-components"
import Card from "../../Components/Card"
import withLoading from "../App/withLoading"
import { Button, Col, Form, Input, Row, message } from "antd"
import { Description, OptionsSectionHeader } from "../../Typography"
import { AppstoreAddOutlined, CopyOutlined, SettingOutlined } from "@ant-design/icons"
import { APPS } from "./Constants"


const SettingsContainer = styled.div`
    overflow: hidden;
`

const SettingsCard = styled(Card)`
    margin: 0px 0px 50px 0px;
`

const Prefix = styled(Input)`
    font-family: monospace, monospace;
    font-weight: 600;
    color: #000;
    cursor: pointer !important;
`

const Settings = ({
    Podcasts: { podcast, fetchingPodcast, dispatchUpdatePodcast, updatingPodcast }
}) => {
    const url = `https://${process.env.PREFIX_DOMAIN}/${podcast.shortId}`
    const [copyActive, setCopyActive] = useState(false)

    const copyText = compose(
        () => setCopyActive(true),
        () => message.info('Prefix url copied to clipboard', 1.5, () => setCopyActive(false)),
        async () => await navigator.clipboard.writeText(url),
    )

    const LoadingContainer = withLoading(SettingsContainer)

    const getAppLinkFormItems = map((app) => (
        <Form.Item key={app} label={app} name={['appLinks', `${app}Url`]} >
            <Input disabled={updatingPodcast} />
        </Form.Item>
    ))

    const handleSubmit = (values) => {
        dispatchUpdatePodcast({ id: podcast.id, ...values })
    }

    const Addon = (
        <React.Fragment>
            <CopyOutlined onClick={copyText} style={{ padding: '5px', fontSize: '1.25rem' }} />
        </React.Fragment>
    )

    return (
        <LoadingContainer predicate={fetchingPodcast}>
            <Row justify="space-between">
                <Col span={24}>
                    <SettingsCard>
                        <Form
                            name="settings"
                            initialValues={{
                                ...podcast,
                                prefix: url
                            }}
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 14 }}
                            layout="vertical"
                            onFinish={handleSubmit}
                        >
                            <OptionsSectionHeader><SettingOutlined /> General Settings</OptionsSectionHeader>
                            <Form.Item label="iTunes id" name="itunesId" rules={[{ required: true, message: 'iTunes id is required to generate app links' }]}>
                                <Input disabled={updatingPodcast} />
                            </Form.Item>
                            <Form.Item label="Prefix URL" name="prefix">
                                <Prefix disabled={true} addonAfter={Addon} />
                            </Form.Item>
                            <Description>Not sure how to setup your prefix? Use <a target="_blank" href={`${process.env.HELP_CENTER_URL}/articles/9631560-the-podcards-analytics-prefix`}>our handy guide</a> or chat with us using the messenger below.</Description>
                            <OptionsSectionHeader><AppstoreAddOutlined /> App Links</OptionsSectionHeader>
                            {getAppLinkFormItems(APPS)}
                            <Form.Item>
                                <Button type="primary" htmlType="submit" disabled={updatingPodcast}>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </SettingsCard>
                </Col>
            </Row>
        </LoadingContainer>
    )
}

export default compose(
    withPodcasts
)(Settings)