import React from "react"
import utc from "dayjs/plugin/utc"
import dayjs from "dayjs"
import { compose, flatten, head, last, map } from "ramda"
import { withPodcasts } from "../../Podcast"
import TimeseriesChart from "../Charts/TimeseriesChart"
import theme from "../../../theme"
import withSearchParams from "../../App/withSearchParams"
import withPromoAnalytics from "./withPromoAnalytics"

dayjs.extend(utc)

const PromoTimeseriesChart = ({
    PromoAnalytics: { promoTimeseries = [] } = {},
    interval = 'day'
}) => {

    return <TimeseriesChart
        datasets={[{
            label: 'Conversions',
            data: compose(flatten, map(last))(promoTimeseries),
            backgroundColor: theme['conversions-graph-color']
        }]}
        labels={compose(flatten, map(head))(promoTimeseries)}
        interval={interval}
    /> 

}

export default compose(
    withPromoAnalytics,
    withPodcasts,
    withSearchParams
)(PromoTimeseriesChart)