exports.ALERT_NO_ITUNES_ID = 'no-itunes-id'
exports.ALERT_NO_SPOTIFY_URL = 'no-spotify-url'
exports.ALERT_NO_PREFIX = 'no-prefix'

exports.ALERTS = {
    [exports.ALERT_NO_ITUNES_ID]: (podcastId = '') => ({
        id: exports.ALERT_NO_ITUNES_ID,
        message: 'We were not able to detect your iTunes id.',
        description: ' Your Podcard won\'t work without your iTunes id. Please update your iTunes id in your podcast settings so we can generate your player links.',
        action: {
            path: `/${podcastId}/podcast/settings`,
            text: 'Submit iTunes id'
        }
    }),
    [exports.ALERT_NO_SPOTIFY_URL]: (podcastId = '') => ({
        id: exports.ALERT_NO_SPOTIFY_URL,
        message: 'We were not able to detect your Spotify URL.',
        description: 'Your Podcard Spotify link won\'t work without your Spotify URL. Please update your Spotify URL in your podcast settings so we can generate your Spotify player link.',
        action: {
            path: `/${podcastId}/podcast/settings`,
            text: 'Submit Spotify URI'
        }
    }),
    [exports.ALERT_NO_PREFIX]: (podcastId = '') => ({
        id: exports.ALERT_NO_PREFIX,
        message: 'No prefix installed.',
        description: 'Podcards measures conversions through the use of a prefix. Install your prefix through your hosting provider and start tracking conversions.',
        action: {
            path: `/${podcastId}/podcast/settings`,
            text: 'Enable prefix'
        }
    })
}