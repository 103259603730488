import { compose, filter, join, map, toPairs } from 'ramda'
import React from 'react'
import styled from 'styled-components'

const Icon = styled.img`
`

const FollowallContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: ${({ wrapBehavior }) => wrapBehavior === 'wrap' ? 'wrap' : 'nowrap'};
    padding: 10px 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    max-width: 1000px;
    max-height: ${({ wrapBehavior }) => wrapBehavior === 'wrap' ? '100%' : '170px'};
    scroll-bar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
`

export const FollowallIconConfig = ({
    property = 'followall',
    podcastId = '',
    episodeGuid,
    sessionId,
    progress,
    duration,
    app = 'apple',
    iconSize = 'sm',
    iconType = 'icon',
    margin = '0px 0px 0px 0px'
}) => {
    const SIZES = {
        sm: {
            width: iconType === 'icon' || iconType === 'svg' ? 35 : 120,
            height: iconType === 'icon' || iconType === 'svg' ? 35 : 32,
        },
        md: {
            width: iconType === 'icon' || iconType === 'svg' ? 50 : 150,
            height: iconType === 'icon' || iconType === 'svg' ? 50 : 39,
        },
        lg: {
            width: iconType === 'icon' || iconType === 'svg' ? 60 : 237,
            height: iconType === 'icon' || iconType === 'svg' ? 60 : 62,
        }
    }

    const extentions = {
        icon: '.png',
        svg: '.svg',
    }

    const extention = extentions[iconType] || '-badge.png'

    const APP_ICONS = {
        apple: {
            appId: 'apple-podcasts',
            name: 'Apple Podcasts',
            icon: require(`./assets/icons/apple${extention}`)
        },
        google: {
            appId: 'google-podcasts',
            name: 'Google Podcasts',
            icon: require(`./assets/icons/google${extention}`)
        },
        spotify: {
            appId: 'spotify',
            name: 'Spotify',
            icon: require(`./assets/icons/spotify${extention}`)
        },
        overcast: {
            appId: 'overcast',
            name: 'Overcast',
            icon: require(`./assets/icons/overcast${extention}`)
        },
        pocketcasts: {
            appId: 'pocketcasts',
            name: 'Pocket Casts',
            icon: require(`./assets/icons/pocketcasts${extention}`)
        },
        podcastaddict: {
            appId: 'podcastaddict',
            name: 'Podcast Addict',
            icon: require(`./assets/icons/podcastaddict${extention}`)
        },
        castbox: {
            appId: 'castbox',
            name: 'Castbox',
            icon: require(`./assets/icons/castbox${extention}`)
        },
        iheartradio: {
            appId: 'iheartradio',
            name: 'iHeartRadio',
            icon: require(`./assets/icons/iheartradio${extention}`)
        },
        castro: {
            appId: 'castro',
            name: 'Castro',
            icon: require(`./assets/icons/castro${extention}`)
        },
        // radiopublic: {
        //     appId: 'radiopublic',
        //     name: 'RadioPublic',
        //     icon: require(`./assets/icons/radiopublic${extention}`)
        // },
        rss: {
            appId: 'rss',
            name: 'RSS',
            icon: require(`./assets/icons/rss${extention}`)
        },
    }

    const { icon, appId } = APP_ICONS[app] || {}

    const iconStyle = {
        width: `${SIZES[iconSize].width}px`,
        height: `${SIZES[iconSize].height}px`,
        margin: `${margin}`,
        fill: 'green',
        color: 'green'
    }

    const qs = {
        property,
        episodeGuid,
        sessionId,
        progress,
        duration,
        app: appId
    }

    const query = compose(
        join('&'),
        map(([key, value]) => `${key}=${value}`),
        filter(([key, value]) => value),
        toPairs
    )(qs)

    return APP_ICONS[app] ? {
        appId,
        icon: <Icon src={icon} preview={false} style={iconStyle} data-testid={appId} />,
        link: `${process.env.FLYWHEEL_ENDPOINT}/podcasts/${podcastId}/follow/${app}?${query}`
    } : { }
}

export const FollowallIconGenerator = ({
    property = 'followall',
    podcastId = '',
    episodeGuid,
    sessionId,
    progress,
    duration,
    app = 'apple',
    iconSize = 'sm',
    iconType = 'icon',
    margin = '0px 0px 0px 0px'
}) => {

    const { appId, icon, link } = FollowallIconConfig({
        property,
        podcastId,
        episodeGuid,
        sessionId,
        progress,
        duration,
        app,
        iconSize,
        iconType,
        margin
    })

    return appId ? (
        <div key={app}>
            <a href={link} target="_blank" rel="noopener noreferrer">{icon}</a>
        </div>
    ) : null
}

const Followall = ({
    property = 'followall',
    podcastId = '',
    episodeGuid = '',
    sessionId = '',
    progress = null,
    duration = null,
    followall = {},
    iconSize = 'md',
    iconType = 'icon',
    margin = '0px 0px 0px 0px',
    wrapBehavior = 'wrap',
}) => {

    return (
        <FollowallContainer wrapBehavior={wrapBehavior}>
            {followall.apps.map(app => FollowallIconGenerator({
                property,
                podcastId,
                episodeGuid,
                sessionId,
                progress,
                duration,
                app,
                iconSize,
                iconType,
                margin
            }))}
        </FollowallContainer>
    )
}

export default Followall