import React, { useState } from 'react'
import { CopyOutlined, GlobalOutlined } from '@ant-design/icons'
import { Col, Input, message } from 'antd'
import Typography from 'antd/es/typography/Typography'
import { compose } from 'ramda'
import { withPodcasts } from '../Podcast'
import styled from 'styled-components'
import { Notification, NotificationConstants } from '../Notification'


const UrlContainer = styled(Col)`
    margin: 0px 0px 25px 0px;
`

const UrlInput = styled(Input)`
    font-family: monospace, monospace;
    font-weight: 600;
    color: #000;
    cursor: pointer !important;
`

const PodCardUrl = ({
    Podcasts: { podcast = {} },
    url = '',
}) => {
    const { ALERTS, ALERT_NO_ITUNES_ID } = NotificationConstants

    const [copyActive, setCopyActive] = useState(false)

    const copyText = compose(
        () => setCopyActive(true),
        () => message.info('Podcard url copied to clipboard', 1.5, () => setCopyActive(false)),
        async () => await navigator.clipboard.writeText(url),
    )

    const iconStyle = { padding: '5px', fontSize: '1.25rem' }

    const Addon = (
        <React.Fragment>
            <CopyOutlined onClick={copyText} style={iconStyle} />
            <a href={url} target='_blank'>
                <GlobalOutlined style={iconStyle} />
            </a>
        </React.Fragment>
    )

    const UrlContent = podcast.itunesId
        ? <UrlInput disabled={true} value={url} addonAfter={Addon} />
        : <Notification alert={ALERTS[ALERT_NO_ITUNES_ID](podcast.id)} />

    return (
        <UrlContainer span={24}>
            <Typography.Text>Your Podcard URL</Typography.Text>
            {UrlContent}
        </UrlContainer>
    )
}

export default compose(
    withPodcasts
)(PodCardUrl)