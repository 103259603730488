import axios from "axios"

export const BuilderApi = {
    parseFeed: async (feedUrl) => {
        const { data } = await axios({
            method: 'GET',
            url: `${process.env.FLYWHEEL_ENDPOINT}/feed/parse?feedUrl=${encodeURIComponent(feedUrl)}`
        })

        return data
    }
}
