import { call } from "ramda";
import { all, put, takeEvery } from "redux-saga/effects";
import { fetchPodcast, fetchPodcastError, fetchPodcasts, fetchPodcastsError, fetchPodcastsSuccess, fetchPodcastSuccess, registerPodcast, registerPodcastError, registerPodcastSuccess, searchPodcast, searchPodcastError, searchPodcastSuccess } from "./podcastSlice";
import PodcastApi from "./podcast.api";

export function* fetchPodcastFromFeedUrl(action = { payload: {} }) {
    const { payload: { feedUrl } } = action

    try {
        const podcast = yield call(PodcastApi.fetch, feedUrl)

        yield put(fetchPodcastSuccess(podcast))
    } catch (error) {
        yield put(fetchPodcastError(error.message))
    }
}

export function* watchFetchPodcast() {
    yield takeEvery(fetchPodcast().type, fetchPodcastFromFeedUrl)
}

export function* fetchAllPodcasts() {
    try {
        const podcasts = yield call(PodcastApi.fetchPodcasts)

        yield put(fetchPodcastsSuccess(podcasts))
    } catch (error) {
        yield put(fetchPodcastsError(error.message))
    }
}

export function* watchFetchPodcasts() {
    yield takeEvery(fetchPodcasts().type, fetchAllPodcasts)
}

export function* registerPodcastFromFeedUrl(action = { payload: {} }) {
    const { payload: { feedUrl } } = action

    try {
        yield call(PodcastApi.register, feedUrl)

        yield put(registerPodcastSuccess())
    } catch (error) {
        yield put(registerPodcastError(error.message))
    }
}

export function* watchRegisterPodcast() {
    yield takeEvery(registerPodcast().type, registerPodcastFromFeedUrl)
}

export function* searchPodcastQuery(action = { payload: {} }) {
    const { payload: { q } } = action

    try {
        const { results } = yield call(PodcastApi.search, q)

        yield put(searchPodcastSuccess(results))
    } catch (error) {
        yield put(searchPodcastError(error.message))
    }
}

export function* watchSearchPodcastQuery() {
    yield takeEvery(searchPodcast().type, searchPodcastQuery)
}

export default function* podcastSaga() {
    yield all([
        watchSearchPodcastQuery()
    ])
}