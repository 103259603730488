import React, { useEffect } from "react"
import { compose, filter, hasPath, identity, map, not, pick, tap } from "ramda"
import withNotifications from "./withNotifications"
import { withPodcasts } from "../Podcast"
import styled from "styled-components"
import { Alert } from "antd"
import { Link } from "react-router-dom"
import { ALERT_NO_ITUNES_ID, ALERT_NO_SPOTIFY_URL } from "./Constants"

const AlertsContainer = styled.div`
    margin: 20px 0px;
`

const Notifications = ({
    Notifications: { alerts = [], dispatchClearAlert = identity, dispatchCreateAlert = identity } = {},
    Podcasts: { podcast = {}, fetchingPodcast = false } = {}
}) => {
    useEffect(() => {
        const checkMissingProperty = (path = []) => compose(not, hasPath(path))
        
        const checkMissingItunesId = checkMissingProperty(['itunesId'])
        const checkMissingSpotifyUrl = checkMissingProperty(['appLinks', 'spotifyUrl'])

        const getAppAlerts = (podcast = {}) => ([
            {
                id: ALERT_NO_ITUNES_ID,
                podcastId: podcast.id,
                predicate: checkMissingItunesId(podcast)
            },
            {
                id: ALERT_NO_SPOTIFY_URL,
                podcastId: podcast.id,
                predicate: checkMissingSpotifyUrl(podcast)
            }
        ])
        
        if (podcast.id && !fetchingPodcast) {
            const clearInactiveAlerts = compose(
                map(dispatchClearAlert),
                filter((({ predicate }) => !predicate)),
                getAppAlerts
            )

            const dispatchAlerts = compose(
                map(dispatchCreateAlert),
                map(pick(['id', 'podcastId'])),
                filter((({ predicate }) => predicate)),
                getAppAlerts
            )

            clearInactiveAlerts(podcast)
            dispatchAlerts(podcast)
        }

    }, [podcast])

    return (
        <AlertsContainer>
            {alerts.length ? (
                alerts.map(alert =>
                    <Alert
                        key={alert.id}
                        message={alert.message}
                        description={alert.description}
                        action={<Link to={alert.action.path}>{alert.action.text}</Link>}
                        type="warning"
                        showIcon
                    />)
            ) : null}
        </AlertsContainer>
    )
}

export default compose(
    withNotifications,
    withPodcasts
)(Notifications)