import React, { useEffect, useState } from "react"
import { compose } from "ramda"
import withPodcasts from './withPodcasts'
import styled from "styled-components"
import withLoading from "../App/withLoading"
import { Row, Col, Select, Divider } from "antd"
import { Label } from "../../Typography"
import ConnectedButton from "../Shared/ConnectedButton"
import { withCustomer } from "../Customer"

const Container = styled.div``

const LoadingContainer = withLoading(Container)

const ColorSquare = styled.div`
    background-color: ${({ color = [], opactiy = 1 }) => `rgba(${color.join(',')}, ${opactiy})`};
    height: 25px;
    width: 25px;
    margin: 0px 10px 0px 0px;
`

const ColorSquarePreview = styled(ColorSquare)`
    margin-left: auto;
`

const ColorSelectionContainer = styled.div`
    margin: 0px 0px 25px 0px;
`

const ColorSelection = styled(Row)`
`


const Settings = ({
    Podcasts: { 
        podcast,
        fetchingPodcast,
        updatingPodcast,
        dispatchUpdatePodcastSettings,
        unsavedChanges,
        dispatchUpdatePodcast
    },
    Customer: { hasBasicSubscription },
}) => {
    
    const { settings = {}, palette = {} } = podcast
    
    const [ backgroundColor, setBackgroundColor  ] = useState([])
    const [ accentColor, setaccentColor  ] = useState([])
    const [ gradientTopColor, setgradientTopColor  ] = useState([])
    const [ gradientBottomColor, setgradientBottomColor  ] = useState([])

    const { Vibrant, DarkVibrant, LightVibrant, Muted, DarkMuted, LightMuted } = palette
    
    const paletteColors = [ Vibrant, DarkVibrant, LightVibrant, Muted, DarkMuted, LightMuted ]

    useEffect(() => {
        if (podcast.id) {
            setBackgroundColor(settings.backgroundColor)
            setaccentColor(settings.accentColor)
            setgradientTopColor(settings.gradientTopColor)
            setgradientBottomColor(settings.gradientBottomColor)
        }
    }, [podcast.id])

    const colorSelections = [
        { 
            value: accentColor,
            handler: value => {
                setaccentColor(value)
                dispatchUpdatePodcastSettings({ accentColor: value})
            },
            title: 'Accent',
            key: 'Accent',
            opactiy: 1,
            options: paletteColors.map((color = []) => ({ label: <ColorSquare color={color} opactiy={1} />, value: color }))
        },
        { 
            value: gradientBottomColor,
            handler: value => {
                setgradientBottomColor(value)
                dispatchUpdatePodcastSettings({ gradientBottomColor: value})
            },
            title: 'Drop Shaddow',
            key: 'Drop Shaddow',
            opactiy: 1,
            options: paletteColors.map((color = []) => ({ label: <ColorSquare color={color} opactiy={1} />, value: color }))
        },
        { 
            value: gradientTopColor,
            handler: value => {
                setgradientTopColor(value)
                dispatchUpdatePodcastSettings({ gradientTopColor: value})
            },
            title: 'Background',
            key: 'Background',
            opactiy: 1,
            options: paletteColors.map((color = []) => ({ label: <ColorSquare color={color} opactiy={1} />, value: color }))
        }
    ]

    const handleUpdateSettings = () => dispatchUpdatePodcast({ id: podcast.id, settings: {
        backgroundColor,
        accentColor,
        gradientTopColor,
        gradientBottomColor
    }})

    return (
        <LoadingContainer predicate={fetchingPodcast}>
            <ColorSelectionContainer>
                { colorSelections.map(({ key, value, handler, title, opactiy, options }) => {
                    return (
                        <ColorSelection key={key}>
                            <Col sm={24} md={16}>
                                <Label>{title}</Label>
                                <Select
                                    disabled={!hasBasicSubscription}
                                    defaultValue={null}
                                    options={options}
                                    bordered={false}
                                    onSelect={handler}
                                    style={{ width: '50px' }}
                                />
                            </Col>
                            <Col flex='auto'>
                                <ColorSquarePreview color={value} opactiy={opactiy} />
                            </Col>
                        </ColorSelection>
                    )
                }) }
            </ColorSelectionContainer>
            <ConnectedButton
                handleClick={handleUpdateSettings}
                disabled={!unsavedChanges || !hasBasicSubscription} content='Save'
                loading={updatingPodcast}
            />
        </LoadingContainer>
    )
}

export default compose(
    withPodcasts,
    withCustomer
)(Settings)