import { compose } from "ramda"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchFollowallSummary, fetchFollowallTimeseries, selectFetchingFollowallSummary, selectFetchingFollowallSummaryError, selectFetchingFollowallTimeseries, selectFetchingFollowallTimeseriesError, selectFollowallSummary, selectFollowallTimeseries } from "./followallSlice"
import { withConnection } from "../../Connection"
import withSearchParams from "../../App/withSearchParams"
import { DATE_FORMAT } from "../Constants"
import dayjs from "dayjs"
import { withPodcasts } from "../../Podcast"

const withSearchParamsPodcastsAndConnection = compose(
    withSearchParams,
    withConnection,
    withPodcasts
)

const withFollowallAnalytics = (Component = React.Fragment) => withSearchParamsPodcastsAndConnection((props = {}) => {
    const { 
        Connection: { connected = false },
        Podcasts: { podcast: { id: podcastId, slug: podcastSlug } = {} } = {},
        SearchParams: {
            startDate = dayjs().subtract(7, 'day').startOf('day').format(DATE_FORMAT),
            endDate = dayjs().endOf('day').format(DATE_FORMAT)
        }
    } = props

    const dispatch = useDispatch()

    const FollowallAnalytics = {
        followallSummary: useSelector(selectFollowallSummary),
        fetchingFollowallSummary: useSelector(selectFetchingFollowallSummary),
        fetchingFollowallSummaryError: useSelector(selectFetchingFollowallSummaryError),

        dispatchFetchFollowallSummary: compose(dispatch, fetchFollowallSummary),

        followallTimeseries: useSelector(selectFollowallTimeseries),
        fetchingFollowallTimeseries: useSelector(selectFetchingFollowallTimeseries),
        fetchingFollowallTimeseriesError: useSelector(selectFetchingFollowallTimeseriesError),

        dispatchFetchFollowallTimeseries: compose(dispatch, fetchFollowallTimeseries)
    }

    useEffect(() => {
        if (connected && podcastId) {
            const params = {
                startDate,
                endDate,
                interval: 'day',
                resourceId: podcastId,
                resourceSlug: podcastSlug,
                property: 'followall'
            }

            FollowallAnalytics.dispatchFetchFollowallSummary({ params })
            FollowallAnalytics.dispatchFetchFollowallTimeseries({ params })
        }
    }, [connected, startDate, endDate, podcastId])

    return <Component {...props} FollowallAnalytics={FollowallAnalytics}/>
})

export default withFollowallAnalytics