import React from "react";
import styled from "styled-components";
import { PodcastTitle } from "../Typography";
import CoverArt from "./CoverArt";
import { find } from "ramda";
import Player from "./Player";
import { MiniPlayer } from "../player/Player/MiniPlayer";

const PlayPillContainer = styled.div`
    display: flex;
    border-radius: 40px;
    align-items: center;
    height: 90px;
    width: calc(100% - 11px);
    bottom: 6px;
    background-color: ${({ settings: { accentColor = [] } }) => `rgba(${accentColor.join(',')}, 1)`};
    padding: 0px 23px;
    justify-content: space-evenly;
    position: absolute;
`

const PodcastDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
`

const PlayPillPodcastTitle = styled(PodcastTitle)`
    font-size: 0.75rem;
`

const PlayPillEpisodeTitle = styled(PodcastTitle)`
    font-size: 1rem;
    font-weight: 600;
`

const PlayPill = ({
    podcast = {},
    activeEpisodeGuid = '',
    sessionId = ''
}) => {
    const { settings = [], title = '', episodes = [] } = podcast;
    const episode = find(({ guid }) => guid === activeEpisodeGuid, episodes)

    return (
        <PlayPillContainer settings={settings}>
            <MiniPlayer podcast={podcast} episode={episode} sessionId={sessionId}/>
        </PlayPillContainer>
    )
}

export default PlayPill;