import { Col, Row } from "antd"
import { identity } from "ramda"
import React from "react"
import styled from "styled-components"
import withLoading from "../../App/withLoading"
import Card from "../../../Components/Card"

const SummaryBoxContainer = styled(Card)`
`

const IntervalSummaryTitle = styled.h2`
    font-weight: 600;
    font-size: 1.5rem;    
`

const IntervalSummaryNumber = styled.h2`
    font-weight: 900;
    font-size: 1.75rem;  
`

const IntervalSummarySubTitle = styled.p`
    font-weight: 300;
    font-size: 1.25rem;
`

const IntervalSummarySubNumber = styled.p`
    font-weight: 300;
    font-size: 1rem;  
`

const IntervalSummaryContainer = styled(Col)`
    text-align: center;
`

const SummaryBox = ({
    Statistics = [],
    SubStatistics = [],
    loadingPredicate = true
}) => {
    const SummaryRow = withLoading(Row)

    return (
        <SummaryBoxContainer>
            <SummaryRow predicate={loadingPredicate}>
                {Statistics.map(({ title = '', statistic = 'N/A', onClick = identity }) => (
                    <Col xs={{ span: 24 }} md={{ span: 8 }} key={title}>
                        <IntervalSummaryContainer span={24}>
                            <IntervalSummaryTitle>{title}</IntervalSummaryTitle>
                        </IntervalSummaryContainer>
                        <IntervalSummaryContainer span={24} onClick={onClick}>
                            <IntervalSummaryNumber>{statistic}</IntervalSummaryNumber>
                        </IntervalSummaryContainer>
                    </Col>        
                ))}
                {SubStatistics.map(({ title = '', statistic = 'N/A', onClick = identity }) => (
                    <Col xs={{ span: 24 }} md={{ span: 8 }} key={title}>
                        <IntervalSummaryContainer span={24}>
                            <IntervalSummarySubTitle>{title}</IntervalSummarySubTitle>
                        </IntervalSummaryContainer>
                        <IntervalSummaryContainer span={24} onClick={onClick}>
                            <IntervalSummarySubNumber>{statistic}</IntervalSummarySubNumber>
                        </IntervalSummaryContainer>
                    </Col>        
                ))}
            </SummaryRow>
        </SummaryBoxContainer>
    )

}

export default SummaryBox
