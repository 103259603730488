import React, { useEffect, useState } from 'react'
import { __, compose, filter, identity, includes, path, tap } from "ramda"
import withPodcasts from "./withPodcasts"
import withLoading from "../App/withLoading"
import styled from "styled-components"
import { Card, Col, Input, Row } from 'antd'
import { useNavigate } from 'react-router-dom'
import { SearchOutlined } from '@ant-design/icons'

const Container = styled.div`
    width: 100%;
    overflow: hidden;
`

const PodcastCardContainer = styled(Col)`
    margin: 0px 0px 20px 0px;
`

const PodcastSearchCard = styled(Card)`
    margin: 0px 0px 50px 0px;
    height: 100%;
`

const PodcastSearch = ({ handleSearch = identity, setSearchTerm = identity, searchTerm = '' }) =>
    <Input
        allowClear
        onChange={compose(handleSearch, tap(setSearchTerm), path(['target', 'value']))}
        value={searchTerm}
        autoFocus
        addonBefore={<SearchOutlined />}
    />

const PodcastCard = ({ podcast, handlePodcastClick }) => (
    <PodcastCardContainer xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
        <Card
            hoverable
            cover={<img src={podcast.itunes.image} />}
            onClick={() => handlePodcastClick({ id: podcast.id })}
        >
            <Card.Meta title={podcast.title} />
        </Card>
    </PodcastCardContainer>
)

const Podcasts = ({
    Podcasts: { podcasts, fetchingPodcasts, dispatchFetchPodcast }
}) => {
    const navigate = useNavigate()
    const [filteredPodcasts, setFilteredPodcasts] = useState([])
    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        setFilteredPodcasts(podcasts)
    }, [podcasts])

    const LoadingContainer = withLoading(Container)

    const handlePodcastClick = ({ id }) => {
        dispatchFetchPodcast({ id })
        navigate(`/${id}/summary`)
    }

    const handleSearch = (podcasts = []) => (term = '') => compose(
        setFilteredPodcasts,
        () => filter(podcast => includes(term.toLowerCase(), podcast.title.toLowerCase()), podcasts)
    )(podcasts)

    return (
        <LoadingContainer predicate={fetchingPodcasts}>
            <PodcastSearchCard>
                <Col sm={{ span: 24 }} md={{ span: 8 }}>
                    <PodcastSearch handleSearch={handleSearch(podcasts)} setSearchTerm={setSearchTerm} searchTerm={searchTerm} />
                </Col>
            </PodcastSearchCard>
            <Row gutter={16}>
                {filteredPodcasts.map(podcast => <PodcastCard podcast={podcast} handlePodcastClick={handlePodcastClick} />)}
            </Row>
        </LoadingContainer>
    )
}

export default compose(
    withPodcasts
)(Podcasts)