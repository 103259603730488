import React, { useState } from "react"
import { compose, filter, has, map } from "ramda"
import withFollowall from "./withFollowall"
import { withPodcasts } from "../Podcast"
import { FollowallIconConfig } from "../../followall"
import { Button, Col, Row, Select, message } from "antd"
import { CopyOutlined, LinkOutlined } from "@ant-design/icons"
import { renderToStaticMarkup } from "react-dom/server"
import styled from "styled-components"

const ApsContainer = styled.div`
    margin: 20px 0px;
`

const ActiveAppContainer = styled(Row)`
    margin: 20px 0px 0px 0px;
    padding: 10px 0px 0px 0px;
`

const CopyButton = styled(Button)`
    margin: 0px 0px 20px 0px;
`

const FollowallApps = ({
    Podcasts: { podcast },
    Followall: { followall },
    iconSize = 'md',
    iconType = 'icon',
}) => {
    const { id } = podcast
    const { apps } = followall

    const appConfigs = map((app = 'apple') => FollowallIconConfig({
        property: 'followall',
        podcastId: id,
        app,
        iconSize,
        iconType,
        margin: '0px 20px 0px 0px'
    }), apps)

    const [activeApp, setActiveApp] = useState(appConfigs.find(({ appId }) => appId === 'apple-podcasts'))

    const copyText = compose(
        () => message.info('copied to clipboard', 1.5),
        async text => await navigator.clipboard.writeText(text),
    )

    const appOptions = compose(
        map(({ appId, name }) => ({ value: appId, label: name })),
        filter(has('appId'))
    )

    const handleAppSelect = compose(
        setActiveApp,
        value => appConfigs.find(({ appId }) => appId === value)
    )

    const handleCopyCode = (({ icon, link }) => copyText(
        renderToStaticMarkup(<div>
            <a href={link} target="_blank" rel="noopener noreferrer">{icon}</a>
        </div>)
    ))

    return (
        <ApsContainer>
            <Select options={appOptions(appConfigs)} defaultValue='apple-podcasts' style={{ width: '100%' }} onChange={handleAppSelect} />
            <ActiveAppContainer align="middle">
                <Col flex='auto'>
                    <a href={activeApp.link} target="_blank" rel="noopener noreferrer">{activeApp.icon}</a>
                </Col>
            </ActiveAppContainer>
            <ActiveAppContainer>
                <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                    <CopyButton icon={<LinkOutlined />} onClick={() => copyText(activeApp.link)} >
                        Copy Link
                    </CopyButton>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                    <CopyButton icon={<CopyOutlined />} onClick={() => handleCopyCode({ icon: activeApp.icon, link: activeApp.link })}>
                        Copy Code
                    </CopyButton>
                </Col>
            </ActiveAppContainer>
        </ApsContainer>
    )

}

export default compose(
    withFollowall,
    withPodcasts
)(FollowallApps)