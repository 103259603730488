import React from "react"
import { Col, Row, Skeleton, } from "antd"
import styled from "styled-components"
import { find, propEq, compose, path, prop, replace, split, map, fromPairs, __, mergeDeepLeft, ifElse, omit, identity, head, has } from "ramda"
import BuilderForm from "./BuilderForm"
import EmbedPreview from "./EmbedPreview"
import { withPodcasts } from "../Podcast"
import withLoading from "../App/withLoading"
import { useSearchParams } from "react-router-dom"
import { getSearchParams } from "../Utils"
import Card from "../../Components/Card"

const Container = styled.div`
    overflow: hidden;
`

const BuilderCard = styled(Card)`
    margin: 0px 0px 50px 0px;
`

const EmbedPreviewContainer = styled.div`
    padding: 50px 50px;
`

export const Builder = ({ 
    Podcasts: { podcast, fetchingPodcasts }
}) => {
    const { episodes = [] } = podcast;
    const { settings = { backgroundColor: [] } } = podcast;
    const { backgroundColor = [] } = settings

    const [searchParams, setSearchParams] = useSearchParams()
    
    const searchParamsObject = getSearchParams(searchParams.toString())
    const { type = 'playlist', episodeGuid, size = 'large' } = searchParamsObject

    const activeEpisodeGuid = episodeGuid || compose(prop('guid'), head)(episodes)

    const id = prop('id', podcast)

    const setActiveEpisodeGuid = (episodeGuid = activeEpisodeGuid) => compose(
        setSearchParams,
        mergeDeepLeft({ episodeGuid })
    )(searchParamsObject)

    const setType = type => compose(
        setSearchParams,
        ifElse(propEq('type', 'playlist'), omit(['episodeGuid']), identity),
        mergeDeepLeft({ type })
    )(searchParamsObject)

    const setSize = (size = 'large') => compose(
        setSearchParams,
        mergeDeepLeft({ size })
    )(searchParamsObject)

    const activeEpisode = find(propEq('guid', activeEpisodeGuid), episodes)

    const iframeSource = `${process.env.PLAYER_URL}/${id}?size=${size}&type=${type}${activeEpisodeGuid ? '&episodeGuid=' + activeEpisodeGuid : ''}&wmode=opaque`

    const LoadingContainer = withLoading(Container)

    const height = 
        size === 'small' && type === 'single' ? '550px'
        : size === 'small' && type !== 'single' ? '750px'
        : size !== 'small' && type === 'single' ? '220px' : '384px'

    const style = {
        width: '100%',
        minWidth: size === 'small' ? '400px' : '590px',
        maxWidth: size === 'small' ? '400px' : '100%',
        height,
        backgroundColor: `rgba(${backgroundColor.join(',')}, 0.75)`
    }

    return (
        <LoadingContainer predicate={fetchingPodcasts}>
            <Row justify="center">
                <Col sm={24}>
                    <BuilderCard title="Options">
                        <BuilderForm
                            onSelectEpisode={setActiveEpisodeGuid}
                            onSelectType={compose(setType, path(['target', 'value']))}
                            onSelectSize={compose(setSize, path(['target', 'value']))}
                            activeEpisode={activeEpisode}
                            episodes={episodes}
                            type={type}
                            size={size}
                            loading={fetchingPodcasts}
                            activeEpisodeGuid={activeEpisodeGuid}
                            iframeSource={iframeSource}
                            style={style}
                        />
                    </BuilderCard>
                </Col>
            </Row>
            <Row justify="center">
                <Col sm={24}>
                <EmbedPreviewContainer >
                    <Row align="center">
                        <Col span={16}>
                            <Skeleton size='small' />
                        </Col>
                    </Row>                    
                    <EmbedPreview
                        style={style}
                        iframeSource={iframeSource}
                    />
                    <Row align="center">
                        <Col span={16}>
                            <Skeleton size='small' />
                        </Col>
                    </Row>
                </EmbedPreviewContainer>
                </Col>
            </Row>
        </LoadingContainer>
    )
}

export default withPodcasts(Builder)