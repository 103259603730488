import { fetchFollowall, fetchFollowallError, fetchFollowallSuccess, updateFollowall, updateFollowallError, updateFollowallSuccess } from "./followallSlice"

export const FOLLOWALL_FETCH = 'followall:get:resource:id'
export const FOLLOWALL_FETCH_SUCCESS = 'followall:get:resource:id:success'
export const FOLLOWALL_FETCH_FAILED = 'followall:get:resource:id:failed'

export const FOLLOWALL_UPDATE = 'followall:update'
export const FOLLOWALL_UPDATE_SUCCESS = 'followall:update:success'
export const FOLLOWALL_UPDATE_FAILED = 'followall:update:failed'

export const events = {
    [fetchFollowall().type]: FOLLOWALL_FETCH,
    [updateFollowall().type]: FOLLOWALL_UPDATE
}

export const handlers = {
    [FOLLOWALL_FETCH_SUCCESS]: fetchFollowallSuccess,
    [FOLLOWALL_FETCH_FAILED]: fetchFollowallError,
    [FOLLOWALL_UPDATE_SUCCESS]: updateFollowallSuccess,
    [FOLLOWALL_UPDATE_FAILED]: updateFollowallError,
}