import React, { useEffect, useRef, useState } from "react"
import { compose, filter, map, move, path } from "ramda"
import withFollowall from "./withFollowall"
import { DndProvider, useDrag, useDrop } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import withLoading from "../App/withLoading"
import styled from "styled-components"
import { Row } from "antd"
import { FollowallIconConfig } from "../../followall/Followall"
import ConnectedButton from "../Shared/ConnectedButton"
import { withPodcasts } from "../Podcast"

const AppsContainer = styled(Row)`
    width: 100%;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    ::-webkit-scrollbar {
        display: none;
    }
    margin: 0px 0px 25px 0px;
`

const AppItem = ({ appId, icon, index, moveCard, dropCard }) => {
    const ref = useRef(null)

    const [{ handlerId }, drop] = useDrop({
        accept: 'card',

        collect: monitor => ({
            handlerId: monitor.getHandlerId(),
        }),

        drop: (item) => {
            const dragIndex = item.index
            const hoverIndex = index

            dropCard()
        },

        hover: (item, monitor) => {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            moveCard(dragIndex, hoverIndex)

            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag] = useDrag({
        type: 'card',
        item: () => ({ appId, index }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1

    drag(drop(ref))

    return (
        <div ref={ref} style={{ opacity, margin: '0px 20px 0px 0px' }} data-handler-id={handlerId}>
            {icon}
        </div>
    )
}

const Container = styled(Row)``
const LoadingContainer = withLoading(Container)

const FollowallAppOrder = ({
    Podcasts: { podcast },
    Followall: {
        followall,
        fetchingFollowall,
        updatingFollowall,
        unsavedChanges,
        dispatchUpdateFollowallSettings,
        dispatchUpdateFollowall
    }
}) => {

    const [apps, setApps] = useState([])

    useEffect(() => {
        setApps(followall.apps)
    }, [followall])

    const moveCard = (dragIndex, hoverIndex) => {
        const newApps = move(dragIndex, hoverIndex, apps)
        setApps(newApps)
    }

    const dropCard = () => {
        dispatchUpdateFollowallSettings({ apps })
    }

    const configuredApps = compose(
        map(app => FollowallIconConfig({ app, iconSize: 'md' })),
        filter(app => path(['appLinks', `${app}Url`], podcast))
    )(apps)

    const renderApp = ({ appId, icon }, index) => appId ? <AppItem key={appId} appId={appId} icon={icon} index={index} moveCard={moveCard} dropCard={dropCard} /> : null

    const handleUpdateAppOrder = () => {
        dispatchUpdateFollowall({ ...followall, apps })
    }

    return (
        <LoadingContainer predicate={fetchingFollowall}>
            <DndProvider backend={HTML5Backend}>
                <AppsContainer wrap={false}>
                    {configuredApps.map(renderApp)}
                </AppsContainer>
            </DndProvider>
            <ConnectedButton
                handleClick={handleUpdateAppOrder}
                disabled={!unsavedChanges}
                loading={updatingFollowall}
                content='Save'
            />
        </LoadingContainer>
    )
}

export default compose(
    withFollowall,
    withPodcasts
)(FollowallAppOrder)