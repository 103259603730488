import { createSlice } from "@reduxjs/toolkit"
import { mergeDeepLeft, path } from "ramda"

const initialState = {
    combinedTimeseries: [[]],
    fetchingCombinedTimeseries: true,
    fetchingCombinedTimeseriesError: null,
}

export const combinedSlice = createSlice({
    name: 'combined',
    initialState,
    reducers: {
        fetchCombinedTimeseries: mergeDeepLeft({
            fetchingCombinedTimeseries: true,
            fetchingCombinedTimeseriesError: null
        }),
        fetchCombinedTimeseriesSuccess: (state, action) => ({
            ...state,
            fetchingCombinedTimeseries: false,
            combinedTimeseries: action.payload    
        }),
        fetchCombinedTimeseriesError: (state, action) => mergeDeepLeft({
            fetchingCombinedTimeseries: false,
            fetchingCombinedTimeseriesError: action.payload    
        }, state),
    }
})

export const {
    fetchCombinedTimeseries,
    fetchCombinedTimeseriesSuccess,
    fetchCombinedTimeseriesError
} = combinedSlice.actions

const selectCombinedTimeseriesPath = (props = []) => path(['combined', ...props])

export const selectCombinedTimeseries = selectCombinedTimeseriesPath(['combinedTimeseries'])
export const selectFetchingCombinedTimeseries = selectCombinedTimeseriesPath(['fetchingCombinedTimeseries'])
export const selectFetchingCombinedTimeseriesError = selectCombinedTimeseriesPath(['fetchingCombinedTimeseriesError'])

export default combinedSlice.reducer