import React, { useEffect, useState } from 'react'
import { compose, identity } from "ramda"
import withCampaigns from "./withCampaigns"
import styled from "styled-components"
import Card from "../../Components/Card"
import { Form, Input } from 'antd'
import { useNavigate } from 'react-router-dom'
import { withPodcasts } from '../Podcast'
import ConnectedButton from '../Shared/ConnectedButton'

const NewCampaignCard = styled(Card)``

const NewCampaign = ({
    Podcasts: { podcast },
    Campaigns: {
        creatingCampaign = false,
        dispatchCreateCampaign = identity,
    }
}) => {
    const navigate = useNavigate()

    const [submitted, setSubmitted] = useState(false)

    useEffect(() => {
        if (submitted && !creatingCampaign) {
            navigate(`/${podcast.id}/campaigns`)
        }
    }, [creatingCampaign])

    const onSubmit = (values = {}) => {
        dispatchCreateCampaign(values)
        setSubmitted(true)
    }

    return (
        <NewCampaignCard title='New Campaign'>
            <Form
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 8 }}
                onFinish={onSubmit}
            >
                <Form.Item label='Name' name='name' rules={[{ required: true }]}>
                    <Input placeholder='A memorable campaign name'/>
                </Form.Item>
                <Form.Item label='Source' name={['utm', 'source']} rules={[{ 
                    pattern: /^\S*$/g, message: 'Source cannot have spaces. Use a dash to seperate words.'
                }]}>
                    <Input placeholder='Website, social media platform, or search engine generating the traffic'/>
                </Form.Item>
                <Form.Item label='Medium' name={['utm', 'medium']} rules={[{ 
                    pattern: /^\S*$/g, message: 'Medium cannot have spaces. Use a dash to seperate words.'
                }]}>
                    <Input placeholder='Type of content for your link e.g. post, email, newsletter'/>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 2, span: 8 }}>
                    <ConnectedButton
                        content='Submit'
                        htmlType="submit"
                        loading={creatingCampaign}
                    />
                </Form.Item>
            </Form>
        </NewCampaignCard>
    )
}

export default compose(
    withCampaigns,
    withPodcasts
)(NewCampaign)