import { createSlice } from "@reduxjs/toolkit"
import { path } from "ramda"

export const CONNECTION_CLOSED = 'CONNECTION_CLOSED'
export const CONNECTION_ERROR = 'CONNECTION_ERROR'
export const CONNECTION_OPENED = 'CONNECTION_OPENED'

export const initialState = {
    connecting: true,
    connected: false,
    error: null
}

const connectionSlice = createSlice({
    name: 'connection',
    initialState,
    reducers: {
        connect: (state, action) => ({        
            ...state,
            connecting: true,
            connected: false,
            error: null
        }),
        connectClosed: (state, action) => ({        
            ...state,
            connecting: false,
            connected: false
        }),
        connectSuccess: (state, action) => ({        
            ...state,
            connecting: false,
            connected: true,
            error: null
        }),
        connectFailure: (state, action) => ({        
            ...state,
            connecting: false,
            connected: false,
            error: action.payload
        })
    }
})

export const { connect, connectSuccess, connectFailure, connectClosed } = connectionSlice.actions

export const selectConnectionStatus = path(['connection', 'connected'])
export const selectConnectingStatus = path(['connection', 'connecting'])
export const selectConnectionError = path(['connection', 'error'])

export default connectionSlice.reducer