import axios from "axios"
import dayjs from "dayjs"
import { compose, defaultTo, evolve, join, prepend, prop, split, __ } from "ramda"

const EventLogger = ({ sessionId }) => async ({
    type = '',
    podcastId = '',
    episodeGuid = '',
    app = ''
}) => {
    try {
        const DATA_MAPPER = {
            'podcard/subscribe': {
                type,
                sessionId,
                timestamp: dayjs().format(process.env.DATE_FORMAT),
                episodeGuid,
                podcastId,
                app
            },
            default: {
                type,
                sessionId,
                timestamp: dayjs().format(process.env.DATE_FORMAT),
                episodeGuid,
                podcastId
            }
        }

        const createEvent = compose(
            evolve({ type: compose(join(':'), prepend('analytics'), split('/'))}),
            defaultTo(DATA_MAPPER.default),
            prop(__, DATA_MAPPER)
        )

        await axios({
            method: 'POST',
            url: `${process.env.FLYWHEEL_ENDPOINT}/analytics/client`,
            data: createEvent(type)
        })
    } catch (error) {
        
    }
}

export default EventLogger