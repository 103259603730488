import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { selectAuthenticating, selectUser } from "./authSlice"
import styled from "styled-components"
import withLoading from "../App/withLoading"

const Container = styled.div`
    background: #FFFFFF;
    overflow: hidden;
`

const Auth = () => {
    const user = useSelector(selectUser)
    const authenticating = useSelector(selectAuthenticating)
    const navigate = useNavigate()

    useEffect(() => {
        if (user.email) {
            navigate('/')
        }
    }, [user])
    
    const LoadingContainer = withLoading(Container)

    return authenticating (
        <LoadingContainer predicate={authenticating} />
    )
}

export default Auth