import { fetchFollowallSummary, fetchFollowallSummaryError, fetchFollowallSummarySuccess, fetchFollowallTimeseries, fetchFollowallTimeseriesError, fetchFollowallTimeseriesSuccess } from "./followallSlice"

export const ANALYTICS_FOLLOWALL_SUMMARY_FETCH = 'analytics:followall:summaries:get'
export const ANALYTICS_FOLLOWALL_SUMMARY_FETCH_SUCCESS = 'analytics:followall:summaries:get:success'
export const ANALYTICS_FOLLOWALL_SUMMARY_FETCH_ERROR = 'analytics:followall:summaries:get:failed'

export const ANALYTICS_FOLLOWALL_TIMESERIES_FETCH = 'analytics:followall:timeseries:get'
export const ANALYTICS_FOLLOWALL_TIMESERIES_FETCH_SUCCESS = 'analytics:followall:timeseries:get:success'
export const ANALYTICS_FOLLOWALL_TIMESERIES_FETCH_ERROR = 'analytics:followall:timeseries:get:failed'

export const events = {
    [fetchFollowallSummary().type]: ANALYTICS_FOLLOWALL_SUMMARY_FETCH,
    [fetchFollowallTimeseries().type]: ANALYTICS_FOLLOWALL_TIMESERIES_FETCH
}

export const handlers = {
    [ANALYTICS_FOLLOWALL_SUMMARY_FETCH_SUCCESS]: fetchFollowallSummarySuccess,
    [ANALYTICS_FOLLOWALL_SUMMARY_FETCH_ERROR]: fetchFollowallSummaryError,
    [ANALYTICS_FOLLOWALL_TIMESERIES_FETCH_SUCCESS]: fetchFollowallTimeseriesSuccess,
    [ANALYTICS_FOLLOWALL_TIMESERIES_FETCH_ERROR]: fetchFollowallTimeseriesError
}