import React  from 'react'
import CampaignPaywall from './CampaignPaywall'
import PromoPaywall from './PromoPaywall'

const withPaywall = (Component = React.Fragment) => (props = {}) => {
    const { paywall, predicate } = props

    const paywalls = {
        campaign: CampaignPaywall,
        promo: PromoPaywall
    }

    const PaywallComponent = paywalls[paywall]

    return predicate ? <PaywallComponent {...props} /> : <Component {...props} />;
}

export default withPaywall
