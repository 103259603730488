import React from "react";
import Campaigns from "./CampaignsContainer";
import NewCampaign from "./NewCampaign";

export default [
    {
        label: 'Campaigns',
        path: '/:id/campaigns',
        element: <Campaigns />
    },
    {
        path: '/:id/campaigns/new',
        element: <NewCampaign />
    }
]