export { default as analyticsReducers } from './analyticsSlice'
export { events as analyticsEvents, handlers as analyticsHandlers } from './analyticsEvents'
export { default as withAnalytics } from './withAnalytics'
export { default as analyticsRoutes } from './analytics.routes'

import Options from './Charts/Options'
import CombinedTimeseriesChart from './Combined/CombinedTimeseriesChart'
import PromoSummary from './Promo/PromoSummary'

export const Components = {
    Options,
    CombinedTimeseriesChart,
    PromoSummary
}