import React from "react"
import utc from "dayjs/plugin/utc"
import dayjs from "dayjs"
import { compose, flatten, head, last, map } from "ramda"
import { withPodcasts } from "../../Podcast"
import TimeseriesChart from "../Charts/TimeseriesChart"
import theme from "../../../theme"
import withSearchParams from "../../App/withSearchParams"
import withFollowallAnalytics from "./withFollowallAnalytics"

dayjs.extend(utc)

const FollowallTimeseriesChart = ({
    FollowallAnalytics: { followallTimeseries = [] } = {},
    interval = 'day'
}) => {

    return <TimeseriesChart
        datasets={[{
            label: 'Click Throughs',
            data: compose(flatten, map(last), head)(followallTimeseries),
            backgroundColor: theme['subscriptions-graph-color']
        }]}
        labels={compose(flatten, map(head), head)(followallTimeseries)}
        interval={interval}
    /> 

}

export default compose(
    withFollowallAnalytics,
    withPodcasts,
    withSearchParams
)(FollowallTimeseriesChart)