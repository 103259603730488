import { Button } from "antd";
import styled from "styled-components"
import theme from "./theme";
import { createMediaQuery } from "./Utils/style.utils";
import { Link } from "react-router-dom";

export const SectionHeader = styled.h3`
    ${createMediaQuery({
    breakpoint: 'xs', properties: `
        font-size: 2.75em;
        font-weight: 700;
        margin: 0px 0px 25px 0px;
    `})}
    ${createMediaQuery({
        breakpoint: 'sm', properties: `
        font-size: 2.75em;
        font-weight: 900;
        margin: 0px 0px 50px 0px;
    `})}
    ${createMediaQuery({
        breakpoint: 'md', properties: `
        font-size: 2.75em;
        font-weight: 900;
        margin: 0px 0px 50px 0px;
    `})}    
    ${createMediaQuery({
        breakpoint: 'lg', properties: `
        font-size: 4em;
        font-weight: 900;
    `})}
    margin: 0px 0px 100px 0px;
`

export const ParagraphHeader = styled.h4`
    ${createMediaQuery({
    breakpoint: 'xs', properties: `
        font-size: 1.5em;
        font-weight: 700;
        margin: 0px 0px 25px 0px;
    `})}
    ${createMediaQuery({
        breakpoint: 'sm', properties: `
        font-size: 1.5em;
        font-weight: 900;
        margin: 0px 0px 50px 0px;
    `})}
    ${createMediaQuery({
        breakpoint: 'md', properties: `
        font-size: 1.5em;
        font-weight: 900;
        margin: 0px 0px 50px 0px;
    `})}    
    ${createMediaQuery({
        breakpoint: 'lg', properties: `
        font-size: 2.75em;
        font-weight: 900;
    `})}
    margin: 0px 0px 100px 0px;
    color: ${({ color = theme['primary-black'] }) => color};
`

export const Header = styled.h3`
    ${createMediaQuery({
    breakpoint: 'xs', properties: `
        font-size: 1.75em;
        font-weight: 700;
    `})}
    ${createMediaQuery({
        breakpoint: 'sm', properties: `
        font-size: 1.75em;
        font-weight: 900;
    `})}
    ${createMediaQuery({
        breakpoint: 'md', properties: `
        font-size: 1.75em;
        font-weight: 900;
    `})}    
    ${createMediaQuery({
        breakpoint: 'lg', properties: `
        font-size: 2em;
        font-weight: 900;
    `})}
`

export const SubHeader = styled.h4`
    ${createMediaQuery({
        breakpoint: 'xs', properties: `
        font-size: 1.25em;
        font-weight: 700;
    `})}
    ${createMediaQuery({
        breakpoint: 'sm', properties: `
        font-size: 2em;
        font-weight: 900;
    `})}
    ${createMediaQuery({
        breakpoint: 'md', properties: `
        font-size: 2.25em;
        font-weight: 900;
    `})}    
    ${createMediaQuery({
        breakpoint: 'lg', properties: `
        font-size: 2.75em;
        font-weight: 900;
    `})}
    color: ${({ color = theme['bright-white'] }) => color};
    margin: 0px;
`

export const BoldText = styled.span`
    font-weight: 700;
`

export const ListContainer = styled.div`
    display: inline-block;
`

export const ListItem = styled.div`
    display: flex;
    margin: 0px 0px 25px 0px;
`

export const ListItemDot = (dotColor = theme['primary-blue']) => styled.div`
    ${createMediaQuery({
        breakpoint: 'xs', properties: `
        height: 0.5rem;
        width: 0.5rem;
        margin-top: 0.5rem;
    `})}
    ${createMediaQuery({
        breakpoint: 'sm', properties: `
        height: 0.5rem;
        width: 0.5rem;
        margin-top: 0.5rem;
    `})}
    display: inline-block;
    border-radius: 50%;
    background-color: ${dotColor};
    height: 0.75rem;
    width: 0.75rem;
    margin-top: 0.5rem;
`
export const BlueListDot = ListItemDot(theme['primary-blue'])
export const RedListDot = ListItemDot(theme['primary-red'])
export const YellowListDot = ListItemDot(theme['primary-yellow'])

export const BodyTextContainer = styled.div`
    display: inline-block;
    width: calc(100% - 0.75rem);
`

export const BodyText = styled.p`
    ${createMediaQuery({
        breakpoint: 'xs', properties: `
        font-size: 1.25em;
        line-height: 1.5rem;
        margin: 0px 0px 0px 0.5rem;
    `})}
    ${createMediaQuery({
        breakpoint: 'sm', properties: `
        font-size: 1.25em;
        line-height: 1.5rem;
        margin: 0px 0px 0px 0.5rem;
    `})}
    ${createMediaQuery({
        breakpoint: 'lg', properties: `
        font-size: 1.35rem;
        line-height: 1.65rem;
        `
    })}      
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 300;
    margin: 0px 0px 0px 1rem;
`

export const SmallBodyText = styled.p`
    ${createMediaQuery({
        breakpoint: 'xs', properties: `
        font-size: 0.75em;
        line-height: 1rem;
        margin: 0px 0px 0px 0.5rem;
    `})}
    ${createMediaQuery({
        breakpoint: 'sm', properties: `
        font-size: 1em;
        line-height: 1rem;
        margin: 0px 0px 0px 0.5rem;
    `})}
    ${createMediaQuery({
        breakpoint: 'lg', properties: `
        font-size: 1rem;
        line-height: 1.65rem;
        `
    })}      
    font-size: 1rem;
    line-height: 2rem;
    font-weight: 300;
    margin: 0px 0px 0px 1rem;
`

export const Description = styled.p`
    ${createMediaQuery({
        breakpoint: 'xs', properties: `
        font-size: 0.75em;
        line-height: 0.5rem;
    `})}
    ${createMediaQuery({
        breakpoint: 'sm', properties: `
        font-size: 0.75em;
        line-height: 1rem;
    `})}
    ${createMediaQuery({
        breakpoint: 'lg', properties: `
        font-size: 0.75rem;
        line-height: 1rem;
        `
    })}      
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 300;
    margin: 15px 0px;
`

export const BigBodyText = styled.h4`
    ${createMediaQuery({
    breakpoint: 'xs', properties: `
        font-size: 1.25em;
        font-weight: 600;
        margin: 0px 0px 25px 0px;
    `})}
    ${createMediaQuery({
        breakpoint: 'sm', properties: `
        font-size: 1.5em;
        font-weight: 600;
        margin: 0px 0px 50px 0px;
    `})}
    ${createMediaQuery({
        breakpoint: 'md', properties: `
        font-size: 2em;
        font-weight: 600;
        margin: 0px 0px 50px 0px;
    `})}    
    ${createMediaQuery({
        breakpoint: 'lg', properties: `
        font-size: 2em;
        font-weight: 600;
    `})}
    ${createMediaQuery({
        breakpoint: 'xl', properties: `
        font-size: 2em;
        font-weight: 600;
    `})}
    ${createMediaQuery({
        breakpoint: 'xxl', properties: `
        font-size: 2.75em;
        font-weight: 600;
    `})}
    margin: 0px 0px 100px 0px;
`

export const Underline = styled.span`
    text-decoration: underline;
`

export const Bold = styled.span`
    font-weight: 800;
`

export const Label = styled.label`
    font-size: 0.85rem;
    font-weight: 400;
    margin: 0px 20px 0px 0px;
`

export const PodCardsButton = styled(Button)`
    background: ${({ disabled }) => disabled ? theme['disabled-color'] : theme['primary-blue'] } !important;
    color: ${theme['text-contrast']} !important;
`

export const AnchorLink = styled.a`
    text-decoration: underline;
    color: ${theme['primary-blue']};
    &:hover {
        font-weight: 600;
        color: ${theme['primary-blue']};
        text-decoration: underline;
    }
`

export const BlueLink = styled(Link)`
    color: ${theme['primary-blue']}
`

export const BlueHeader = styled.span`
    color: ${theme['primary-blue']}
`

export const YellowHeader = styled.span`
    color: ${theme['primary-yellow']}
`

export const RedHeader = styled.span`
    color: ${theme['primary-red']}
`

export const OptionsSectionHeader = styled(BlueHeader)`
${createMediaQuery({
    breakpoint: 'xs', properties: `
        font-size: 1.25em;
        font-weight: 600;
        margin: 0px 0px 25px 0px;
    `})}
    ${createMediaQuery({
        breakpoint: 'sm', properties: `
        font-size: 1.25em;
        font-weight: 600;
        margin: 0px 0px 50px 0px;
    `})}
    ${createMediaQuery({
        breakpoint: 'md', properties: `
        font-size: 1.25em;
        font-weight: 600;
        margin: 0px 0px 50px 0px;
    `})}    
    ${createMediaQuery({
        breakpoint: 'lg', properties: `
        font-size: 1.25em;
        font-weight: 600;
    `})}
    ${createMediaQuery({
        breakpoint: 'xl', properties: `
        font-size: 1.25em;
        font-weight: 600;
    `})}
    ${createMediaQuery({
        breakpoint: 'xxl', properties: `
        font-size: 1.5em;
        font-weight: 600;
    `})}
    line-height: 2.5rem;
    margin: 0px 0px 100px 0px;
`

export const EpisodeTitle = styled.h4`   
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 600;
    margin: 0px 0px 0px 0rem;
    color: ${theme['text-contrast']};
`

export const PodcastTitle = styled.h5`    
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 300;
    margin: 0px 0px 0px 0rem;
    color: ${theme['primary-white']};
`