import { createSlice } from "@reduxjs/toolkit"
import { mergeDeepLeft, path } from "ramda"

const initialState = {
    conversionSummary: {
        app_clicks: { count: 0 },
        conversion_ratios: { sessions: 0, conversions: 0},
        conversions: { total_conversions: 0 },
        total_listens: { total:  0 },
        total_listens_from_visits: { total:  0 },
    },
    fetchingConversionSummary: true,
    fetchingConversionSummaryError: null,
    conversionTimeseries: [[]],
    fetchingConversionTimeseries: false,
    fetchingConversionTimeseriesError: null,
    conversionReferers: [],
    fetchingConversionReferers: false,
    fetchingConversionReferersError: null,
    conversionEpisodes: [],
    fetchingConversionEpisodes: false,
    fetchingConversionEpisodesError: null
}

export const conversionSlice = createSlice({
    name: 'conversion',
    initialState,
    reducers: {
        fetchConversionSummary: mergeDeepLeft({
            fetchingConversionSummary: true,
            fetchingConversionSummaryError: null
        }),
        fetchConversionSummarySuccess: (state, action) => ({
            ...state,
            fetchingConversionSummary: false,
            conversionSummary: action.payload    
        }),
        fetchConversionSummaryError: (state, action) => mergeDeepLeft({
            fetchingConversionSummary: false,
            fetchingConversionSummaryError: action.payload    
        }, state),
        fetchConversionTimeseries: mergeDeepLeft({
            fetchingConversionTimeseries: true,
            fetchingConversionTimeseriesError: null
        }),
        fetchConversionTimeseriesSuccess: (state, action) => ({
            ...state,
            fetchingConversionTimeseries: false,
            conversionTimeseries: action.payload    
        }),
        fetchConversionTimeseriesError: (state, action) => mergeDeepLeft({
            fetchingConversionTimeseries: false,
            fetchingConversionTimeseriesError: action.payload    
        }, state),
        fetchConversionReferers: mergeDeepLeft({
            fetchingConversionReferers: true,
            fetchingConversionReferersError: null
        }),
        fetchConversionReferersSuccess: (state, action) => ({
            ...state,
            fetchingConversionReferers: false,
            conversionReferers: action.payload    
        }),
        fetchConversionReferersError: (state, action) => mergeDeepLeft({
            fetchingConversionReferers: false,
            fetchingConversionReferersError: action.payload    
        }, state),
        fetchConversionEpisodes: mergeDeepLeft({
            fetchingConversionEpisodes: true,
            fetchingConversionEpisodesError: null
        }),
        fetchConversionEpisodesSuccess: (state, action) => ({
            ...state,
            fetchingConversionEpisodes: false,
            conversionEpisodes: action.payload    
        }),
        fetchConversionEpisodesError: (state, action) => mergeDeepLeft({
            fetchingConversionEpisodes: false,
            fetchingConversionEpisodesError: action.payload    
        }, state),
    }
})

export const {
    fetchConversionSummary,
    fetchConversionSummarySuccess,
    fetchConversionSummaryError,
    fetchConversionTimeseries,
    fetchConversionTimeseriesSuccess,
    fetchConversionTimeseriesError,
    fetchConversionReferers,
    fetchConversionReferersSuccess,
    fetchConversionReferersError,
    fetchConversionEpisodes,
    fetchConversionEpisodesSuccess,
    fetchConversionEpisodesError
} = conversionSlice.actions

const selectConversionPath = (props = []) => path(['conversion', ...props])

export const selectConversionSummary = selectConversionPath(['conversionSummary'])
export const selectFetchingConversionSummary = selectConversionPath(['fetchingConversionSummary'])
export const selectFetchingConversionSummaryError = selectConversionPath(['fetchingConversionSummaryError'])
export const selectConversionTimeseries = selectConversionPath(['conversionTimeseries'])
export const selectFetchingConversionTimeseries = selectConversionPath(['fetchingConversionTimeseries'])
export const selectFetchingConversionTimeseriesError = selectConversionPath(['fetchingConversionTimeseriesError'])
export const selectConversionReferers = selectConversionPath(['conversionReferers'])
export const selectFetchingConversionReferers = selectConversionPath(['fetchingConversionReferers'])
export const selectFetchingConversionReferersError = selectConversionPath(['fetchingConversionReferersError'])
export const selectConversionEpisodes = selectConversionPath(['conversionEpisodes'])
export const selectFetchingConversionEpisodes = selectConversionPath(['fetchingConversionEpisodes'])
export const selectFetchingConversionEpisodesError = selectConversionPath(['fetchingConversionEpisodesError'])

export default conversionSlice.reducer