import React, { useEffect, useState } from "react"
import { Affix, Col, Row } from "antd"
import styled from "styled-components"
import { find, propEq, compose, __, head } from "ramda"
import PodcardOptions from "./PodcardOptions"
import PodcardPreview from "./PodcardPreview"
import { withPodcasts } from "../Podcast"
import withLoading from "../App/withLoading"
import Card from "../../Components/Card"
import { withClips } from "../Clip"
import withEpisodes from "../Episode/withEpisodes"
import withSearchParams from "../App/withSearchParams"

const Container = styled.div`
    overflow: hidden;
`

const LoadingContainer = withLoading(Container)

const PodcardCard = styled(Card)`
    margin: 0px 0px 50px 0px;
`

const PodcardPreviewContainer = styled(Affix)`
    align-items: center;
`

export const Podcard = ({
    Podcasts: { podcast, fetchingPodcast },
    Episodes: { episodes, fetchingEpisodes },
    Clips: { clips, dispatchlistClips, fetchingClips, fetchingClipsError },
    SearchParams: { episodeGuid, campaignId, setSearchParamsCampaignId }
}) => {
    const { id: podcastId, slug: podcastSlug } = podcast;

    const findActiveEpisode = (episodes = []) => (episodeGuid = '') => 
        find(propEq('guid', episodeGuid), episodes) || head(episodes) || {}

    const [ activeEpisodeGuid, setActiveEpisodeGuid ] = useState(episodeGuid)
    const [ activeEpisode, setActiveEpisode ] = useState(findActiveEpisode(episodes)(episodeGuid))
    
    useEffect(() => {
        const activeEpisode = findActiveEpisode(episodes)(activeEpisodeGuid)

        setActiveEpisode(activeEpisode)
    }, [activeEpisodeGuid])

    useEffect(() => {
        if (podcastId && activeEpisode.id && !fetchingClips && !fetchingClipsError) {
            dispatchlistClips({ episodeId: activeEpisode.id })
        }
    }, [podcastId, activeEpisode])

    return (
        <LoadingContainer predicate={fetchingPodcast}>
            <Row justify="space-between">
                <Col sm={24} md={10} lg={10}>
                    <PodcardCard>
                        <PodcardOptions
                            onSelectEpisode={setActiveEpisodeGuid}
                            activeEpisode={activeEpisode}
                            activeEpisodeGuid={activeEpisodeGuid}
                            onSelectCampaign={setSearchParamsCampaignId}
                            campaignId={campaignId}
                            episodes={episodes}
                            clip={head(clips)}
                            loading={fetchingPodcast || fetchingEpisodes}
                        />
                    </PodcardCard>
                </Col>
                <Col sm={24} md={10} lg={12}>
                    <PodcardPreviewContainer >  
                        <PodcardPreview id={podcastId} activeEpisodeGuid={activeEpisodeGuid} />
                    </PodcardPreviewContainer>
                </Col>
            </Row>
        </LoadingContainer>
    )
}

export default compose(
    withPodcasts,
    withEpisodes,
    withClips,
    withSearchParams
)(Podcard)