import { createSlice } from "@reduxjs/toolkit"
import { mergeDeepLeft, path } from "ramda"

const initialState = {
    sessionsSummary: {},
    fetchingSessionsSummary: true,
    fetchingSessionsSummaryError: null,
    sessions: [],
    fetchingSessions: false,
    fetchingSessionsError: null,
    referers: [],
    fetchingReferers: false,
    fetchingReferersError: null,
    locations: [],
    fetchingLocations: false,
    fetchingLocationsError: null,
    referersByProgress: [],
    fetchingReferersByProgress: false,
    fetchingReferersByProgressError: null,
}

const sessionSlice = createSlice({
    name: 'sessions',
    initialState,
    reducers: {
        fetchSessions: mergeDeepLeft({
            fetchingSessions: true,
            fetchingSessionsError: null
        }),
        fetchSessionsSuccess: (state, action) => ({
            ...state,
            fetchingSessions: false,
            sessions: action.payload    
        }),
        fetchSessionsError: (state, action) => mergeDeepLeft({
            fetchingSessions: false,
            fetchingSessionsError: action.payload    
        }, state),
        fetchSessionsSummary: mergeDeepLeft({
            fetchingSessionsSummary: true,
            fetchingSessionsSummaryError: null
        }),
        fetchSessionsSummarySuccess: (state, action) => ({
            ...state,
            fetchingSessionsSummary: false,
            sessionsSummary: action.payload    
        }),
        fetchSessionsSummaryError: (state, action) => mergeDeepLeft({
            fetchingSessionsSummary: false,
            fetchingSessionsSummaryError: action.payload    
        }, state),
        fetchReferers: mergeDeepLeft({
            fetchingReferers: true,
            fetchingReferersError: null
        }),
        fetchReferersSuccess: (state, action) => ({
            ...state,
            fetchingReferers: false,
            referers: action.payload    
        }),
        fetchReferersError: (state, action) => mergeDeepLeft({
            fetchingLocataions: false,
            fetchingLocataionsError: action.payload    
        }, state),
        fetchLocations: mergeDeepLeft({
            fetchingLocataions: true,
            fetchingLocataionsError: null
        }),
        fetchLocationsSuccess: (state, action) => ({
            ...state,
            fetchingLocataions: false,
            locations: action.payload    
        }),
        fetchLocationsError: (state, action) => mergeDeepLeft({
            fetchingLocataions: false,
            fetchingLocataionsError: action.payload    
        }, state),
        fetchReferersByProgress: mergeDeepLeft({
            fetchingReferersByProgress: true,
            fetchingReferersByProgressError: null
        }),
        fetchReferersByProgressSuccess: (state, action) => ({
            ...state,
            fetchingReferersByProgress: false,
            referersByProgress: action.payload    
        }),
        fetchReferersByProgressError: (state, action) => mergeDeepLeft({
            fetchingReferersByProgress: false,
            fetchingReferersByProgressError: action.payload    
        }, state),
    }
})

export const {
    fetchSessions,
    fetchSessionsSuccess,
    fetchSessionsError,
    fetchSessionsSummary,
    fetchSessionsSummarySuccess,
    fetchSessionsSummaryError,
    fetchReferers,
    fetchReferersSuccess,
    fetchReferersError,
    fetchLocations,
    fetchLocationsSuccess,
    fetchLocationsError,
    fetchReferersByProgress,
    fetchReferersByProgressSuccess,
    fetchReferersByProgressError,
} = sessionSlice.actions

export const selectSessionPath = (props = []) => path(['session', ...props])

export const selectSessions = selectSessionPath(['sessions'])
export const selectFetchingSessions = selectSessionPath(['fetchingSessions'])
export const selectFetchingSessionsError = selectSessionPath(['fetchingSessionsError'])
export const selectSessionsSummary = selectSessionPath(['sessionsSummary'])
export const selectFetchingSessionsSummary = selectSessionPath(['fetchingSessionsSummary'])
export const selectFetchingSessionsSummaryError = selectSessionPath(['fetchingSessionsSummaryError'])
export const selectReferers = selectSessionPath(['referers'])
export const selectFetchingReferers = selectSessionPath(['fetchingReferers'])
export const selectFetchingReferersError = selectSessionPath(['fetchingReferersError'])
export const selectLocations = selectSessionPath(['locations'])
export const selectFetchingLocations = selectSessionPath(['fetchingLocations'])
export const selectFetchingLocationsError = selectSessionPath(['fetchingLocationsError'])
export const selectReferersByProgress = selectSessionPath(['referersByProgress'])
export const selectFetchingReferersByProgress = selectSessionPath(['fetchingReferersByProgress'])
export const selectFetchingReferersByProgressError = selectSessionPath(['fetchingReferersByProgressError'])

export default sessionSlice.reducer