import { createSlice } from "@reduxjs/toolkit"
import { always, evolve, mergeDeepLeft, path, prepend } from "ramda"

const initialState = {
    unsavedChanges: false,
    podcast: {},
    podcasts: [],
    registeringPodcast: false,
    registeringPodcastError: null,
    fetchingPodcast: false,
    fetchingPodcastError: null,
    fetchingPodcasts: true,
    fetchingPodcastsError: null,
    updatingPodcast: false,
    updatePodcastError: null,
    searchingPodcastResults: [],
    searchingPodcast: false,
    searchPodcastError: null,
}

export const podcastSlice = createSlice({
    name: 'podcast',
    initialState,
    reducers: {
        registerPodcast: (state) => mergeDeepLeft({
            registeringPodcast: true,
            registeringPodcastError: null
        }, state),
        registerPodcastError: (state, action) => mergeDeepLeft({
            registeringPodcast: false,
            registeringPodcastError: action.payload.message
        }, state),
        registerPodcastSuccess: (state, action) => evolve({
            podcast: always(action.payload),
            podcasts: prepend(action.payload),
            registeringPodcast: always(false),
            registeringPodcastError: always(null),
            searchingPodcastResults: always([]),
        }, state),
        fetchPodcast: (state) => mergeDeepLeft({
            fetchingPodcast: true,
            podcast: {},
            fetchPodcastError: null
        }, state),
        fetchPodcastSuccess: (state, action) => ({
            ...state,
            fetchingPodcast: false,
            podcast: action.payload,
            fetchPodcastError: null
        }),
        fetchPodcastError: (state, action) => mergeDeepLeft({
            fetchingPodcast: false,
            podcast: {},
            fetchPodcastError: action.payload
        }, state),
        fetchPodcasts: (state) => mergeDeepLeft({
            fetchingPodcasts: true,
            podcast: [],
            fetchPodcastsError: null
        }, state),
        fetchPodcastsSuccess: (state, action) => mergeDeepLeft({
            fetchingPodcasts: false,
            podcasts: action.payload,
            fetchPodcastError: null
        }, state),
        fetchPodcastsError: (state, action) => mergeDeepLeft({
            fetchingPodcasts: false,
            podcasts: [],
            fetchPodcastsError: action.payload
        }, state),
        updatePodcastSettings: (state, action) => mergeDeepLeft({
            unsavedChanges: true,
            podcast: mergeDeepLeft({ settings: action.payload }, state.podcast)
        }, state),
        updatePodcast: (state) => mergeDeepLeft({
            unsavedChanges: false,
            updatingPodcast: true,
            updatePodcastError: null
        }, state),
        updatePodcastSuccess: (state, action) => mergeDeepLeft({
            updatingPodcast: false,
            podcast: action.payload.id === state.podcast.id ? action.payload : state.podcast,
            updatePodcastError: null
        }, state),
        updatePodcastError: (state, action) => mergeDeepLeft({
            updatingPodcast: false,
            updatePodcastError: action.payload
        }, state),
        searchPodcast: (state) => mergeDeepLeft({
            searchingPodcast: true,
            searchingPodcastResults: [],
            searchPodcastError: null
        }, state),
        searchPodcastSuccess: (state, action) => mergeDeepLeft({
            searchingPodcast: false,
            searchingPodcastResults: action.payload,
            searchPodcastError: null
        }, state),
        searchPodcastError: (state, action) => mergeDeepLeft({
            searchingPodcast: false,
            searchingPodcastResults: [],
            searchPodcastError: action.payload
        }, state),
    }
})

export const podcastActions = podcastSlice.actions

export const {
    registerPodcast,
    registerPodcastError,
    registerPodcastSuccess,
    fetchPodcast,
    fetchPodcastSuccess,
    fetchPodcastError,
    fetchPodcasts,
    fetchPodcastsSuccess,
    fetchPodcastsError,
    updatePodcastSettings,
    updatePodcast,
    updatePodcastSuccess,
    updatePodcastError,
    searchPodcast,
    searchPodcastSuccess,
    searchPodcastError,
} = podcastSlice.actions

const selectPodcastPath = (props = []) => path(['podcast', ...props])

export const selectPodcast = selectPodcastPath(['podcast'])
export const selectPodcasts = selectPodcastPath(['podcasts'])
export const selectPodcastRegistering = selectPodcastPath(['registeringPodcast'])
export const selectPodcastRegisteringError = selectPodcastPath(['registeringPodcastError'])
export const selectPodcastFetching = selectPodcastPath(['fetchingPodcast'])
export const selectPodcastFetchingError = selectPodcastPath(['fetchingPodcastError'])
export const selectPodcastsFetching = selectPodcastPath(['fetchingPodcasts'])
export const selectPodcastsFetchingError = selectPodcastPath(['fetchingPodcastsError'])
export const selectUnsavedChanges = selectPodcastPath(['unsavedChanges'])
export const selectPodcastUpdating = selectPodcastPath(['updatingPodcast'])
export const selectPodcastUpdatingError = selectPodcastPath(['updatingPodcastError'])
export const selectPodcastSearchResults = selectPodcastPath(['searchingPodcastResults'])
export const selectPodcastSearching = selectPodcastPath(['searchingPodcast'])
export const selectPodcastSearchingError = selectPodcastPath(['searchingPodcastError'])

export default podcastSlice.reducer