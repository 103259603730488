import { Button } from "antd"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import theme from "../theme"
import { Link } from "react-router-dom"
import { ThunderboltFilled } from "@ant-design/icons"
import { compose, has, ifElse, prop } from "ramda"
import { withCustomer } from "../app/Customer"
import withAnalytics from "./withAnalytics"

const UpgradePrimary = styled(Button)`
    background: ${theme['primary-blue']} !important;
    color: ${theme['text-contrast']} !important;
    border: none;
    font-weight: 600;
    width: 110px;
`

const UpgradeSecondary = styled(UpgradePrimary)`
    background: ${theme['contrast-background']} !important;
    color: ${theme['primary-blue']} !important;
    border: none;
`

const UpgradeLink = styled(Link)`
`

const UpgradeButton = ({
    Customer: { customer, hasSubscription, hasBasicSubscription, hasMarketerSubscription, fetchingCustomer },
    Analytics: { event }, 
    text = 'Upgrade',
    type = 'primary',
    location = 'header',
    upgradePath = 'basic'
}) => {
    const [predicate, setPredicate] = useState(true)

    useEffect(() => {
        const planCheck = {
            'default': hasSubscription,
            'basic': hasBasicSubscription,
            'marketer': hasMarketerSubscription
        }
        
        const hasPlan = ifElse(has(upgradePath),prop(upgradePath),prop('default'))

        setPredicate(hasPlan(planCheck))
    }, [customer])

    const Wrapper = type === 'primary' ? UpgradePrimary : UpgradeSecondary

    const onUpgradeClick = () => {
        event({ type: `upgrade_${location}`, method: 'app'})
        window.open(`${process.env.STRIPE_ACCOUNT_URL}?prefilled_email=${encodeURIComponent(customer.email)}`)
    }
    
    return predicate ? null : (
        <Wrapper loading={fetchingCustomer}>
            <UpgradeLink onClick={onUpgradeClick} style={{ display: fetchingCustomer ? 'none' : '' }} >
                <ThunderboltFilled /> {text}
            </UpgradeLink>
        </Wrapper>
    )
}

export default compose(
    withCustomer,
    withAnalytics
)(UpgradeButton)