import React from "react"
import { find } from "ramda"
import styled from "styled-components"
import { Col, Row } from "antd"
import { EpisodeDetails } from "../Podcast"
import PlayerControls from "./PlayerControls"
import theme from "../../theme"


const Container = styled(Row)`
    max-width: 90%;
    margin-top: -80px;
`

const EpisodeDetailsContainer = styled(Row)``
const PlayerControllsContainer = styled(Row)``

const DetailsControlContainer = styled(Row)`
    width: 90%;
`

const DetailsControl = styled(Col)`
    border-radius: ${theme['border-radius-base']};
    padding: 10px 15px;
    margin: 20px 0px;
`

export const Player = ({
    podcast = {},
    episodeGuid = ''
}) => {

    const { episodes = [] } = podcast
    
    const episode = find(({ guid }) => guid === episodeGuid, episodes)

    return podcast.id ? (
        <Container align="center">
            <DetailsControlContainer align="center">
                <DetailsControl xs={24}>
                    <EpisodeDetailsContainer align="center">
                        <EpisodeDetails podcast={podcast} episode={episode} />
                    </EpisodeDetailsContainer>
                    <PlayerControllsContainer align="center">
                        <PlayerControls podcast={podcast} episode={episode} />
                    </PlayerControllsContainer>
                </DetailsControl>
            </DetailsControlContainer>
        </Container>
    ) : null
}