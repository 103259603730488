import { compose, defaultTo, find, head, match, nth, path, prop, propOr, replace } from "ramda";

export const getFwSessionIdFromDocument = compose(
    nth(1),
    match(/FW_SESSION_ID=(.*)/),
    prop('cookie')
)

export const parseWindowPodcast = compose(
    JSON.parse,
    replace(/\f/g, '\\f'),
    replace(/\n/g, '\\n'),
    replace(/\r/g, '\\r'),
    replace(/\t/g, '\\t'),
    propOr('{}', '__podcast')
)

export const getActiveEpisode = (window = {}) => compose(
    defaultTo(compose(
        prop('guid'),
        head,
        prop('episodes')
    )(parseWindowPodcast(window))),
    Params => Params.get('episodeGuid'),
    search => new URLSearchParams(search),
    path(['location', 'search'])
)(window)

export const findEpisode = ({ activeEpisodeGuid = '', episodes = [] }) => find(({ guid }) => guid === activeEpisodeGuid, episodes)