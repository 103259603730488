import React from "react"

const withAnalytics = (Component = React.Fragment) => (props = {}) => {

    const Analytics = {
        event: ({ type = '', method = 'app' }) => fathom.trackEvent(type, { method })
    }

    return <Component {...props} Analytics={Analytics} />
}

export default withAnalytics