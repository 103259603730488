import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchUser, selectUser, selectUserFetching, selectUserFetchingError } from "./userSlice"
import { compose } from "ramda"

const withUser = (Component = React.Fragment) => (props = {}) => {
    const dispatch = useDispatch()

    const User = {
        user: useSelector(selectUser),
        fetchingUser: useSelector(selectUserFetching),
        fetchingUserError: useSelector(selectUserFetchingError),
    
        dispatchFetchUser: compose(dispatch, fetchUser),
    }

    return <Component {...props} User={User} />
}

export default withUser