import { compose, identity, ifElse, includes } from "ramda"
import React, { useEffect, useRef, useState } from "react"
import { withEventLogger } from "../../HOC/EventLogger"

export const MediaPlayer = ({
    url = '',
    autoPlay = false,
    preload = 'none',
    playing = false,
    progressInterval = 1000,
    seekTime = 0,
    onPlay = identity,
    onPause = identity,
    onProgress = identity,
    onTimeUpdate = identity,
    onDuration = identity,
    onBuffer = identity,
    onBufferEnd = identity,
    height ='10px',
    width = '10px'
}) => {
    const player = useRef(null)

    const [throttle, setThrottle] = useState(false)

    useEffect(() => {
        if (playing) {
            player.current.play()
        } else {
            player.current.pause()
        }
    }, [playing])

    useEffect(() => {
        if (playing) {
            player.current.play()
        }
    }, [url])

    useEffect(() => {
        if (seekTime > 0) {
            player.current.currentTime = seekTime
        }
    }, [seekTime])

    const appendPlayerIdToUrl = ifElse(
        includes('?'),
        url => `${url}&player=podcards`,
        url => `${url}?player=podcards`,
    )

    return (
        <audio
            autoPlay={autoPlay}
            preload={preload}
            ref={player}
            controls={false}
            src={appendPlayerIdToUrl(url)}
            onPlay={() => onPlay()}
            onPause={() => onPause()}
            onTimeUpdate={({ target: { currentTime }}) => {
                onTimeUpdate(currentTime)

                if (!throttle) {
                    setThrottle(true)
                    onProgress(currentTime)
                    setTimeout(() => setThrottle(false), progressInterval)
                }
            }}
            onDurationChange={({ target: { duration }}) => onDuration(duration)}
            onCanPlay={() => onBufferEnd(true)}
            onWaiting={() => onBuffer(true)}
            style={{ width, height }}
        />
    )
}

export default compose(
    withEventLogger
)(MediaPlayer)