export { default as DatePicker } from './DatePicker'
const { compose, fromPairs, split, map, replace, head, slice, propOr, propEq, find, filter } = require("ramda");

export const getSearchParams = compose(
    filter(x => x !== 'undefined'),
    fromPairs,
    map(split('=')),
    split('&'),
    replace('?', ''),
    decodeURIComponent
)

export const truncateReferrer = (referrer = '') => {
    const isDirect = referrer === '-'

    const test = /(?:[^:]+:\/\/)(?:www\.)*([.a-z0-9]+)+/
    const rootRef = test.exec(referrer) || []
    return isDirect ? 'Direct' : head(rootRef)
}

export const truncateString = (length = 20) => (str = '') => compose(
    x => str.length > 20 ? `${x}...` : x,
    slice(0, length)
)(str)

export const unescapeHtml = (html = '') => {
    const parsed = html
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'")
        .replace(/&nbsp;/g, ' ')

    return parsed
}

export const getTopEpisodeTitleByGuid = (podcast = {}) => (guid = '') => compose(
    truncateString(20),
    unescapeHtml,
    propOr('N/A', 'title'),
    find(propEq('guid', guid))
)(podcast)

export const getTopEpisodeTitleById = (podcast = {}) => (id = '') => compose(
    truncateString(20),
    unescapeHtml,
    propOr('N/A', 'title'),
    find(propEq('id', id))
)(podcast)