import React, { useState } from "react"
import styled from "styled-components"
import { CheckCircleOutlined, CopyOutlined } from '@ant-design/icons'
import { Col, Input, message, Radio, Row, Select, Typography } from "antd"
import { identity, map, compose, path, head, prop, evolve } from "ramda"
import theme from "../../theme"
import { unescapeHtml } from "../Utils"

const IframeCodeContainer = styled(Col)`
    margin: 25px 0px;
`

const IFrameCodeBlock = styled(Input)`
    font-family: monospace, monospace;
    font-weight: 600;
    color: #000;
    cursor: pointer !important;
`

const IframeSource = ({
    iframeSource = '',
    style = {}
}) => {
    const [copyActive, setCopyActive] = useState(false)
    const { width, minWidth, maxWidth, height, background } = style
    const styleString = `width: ${width}; min-width: ${minWidth}; max-width: ${maxWidth}; height: ${height}; background: ${background};`

    const iframeCode = `<iframe title="flywheel-embed" frameborder="0" scrolling="no" src="${iframeSource}" data-embed="true" style="${styleString}"></iframe>`

    const copyText = compose(
        () => setCopyActive(true),
        () => message.info('code copied to clipboard', 1.5, () => setCopyActive(false)),
        async () => await navigator.clipboard.writeText(iframeCode),
    )

    const Addon = copyActive ? <CheckCircleOutlined /> : <CopyOutlined onClick={copyText} />

    return (
        <IframeCodeContainer span={24}>
            <Typography.Text>Copy embed code</Typography.Text>
            <IFrameCodeBlock
                disabled={true}
                value={iframeCode}
                addonAfter={Addon}
            />
        </IframeCodeContainer>
    )
}

const BuilderFormConatiner = styled.div`
    overflow: hidden;
    padding: 20px;
`

const BuidlerFormRow = styled(Row)`
    margin: 15px 0px;
`

const ColContainer = styled(Col)`
    margin: 0px 0px 20px 0px;
`

const BuilderForm = ({
    episodes = [],
    onSelectEpisode = identity,
    onSelectType = identity,
    onSelectSize = identity,
    type = 'playlist',
    size = 'large',
    activeEpisodeGuid = '',
    iframeSource = '',
    style = {}
}) => {

    const mapEscapedEpisode = map(evolve({
        title: unescapeHtml
    }))

    return (
        <BuilderFormConatiner>
                <BuidlerFormRow>
                    <Col span={24}>
                        <ColContainer span={12}>
                            <Typography.Text>Player type: </Typography.Text>
                            <Radio.Group options={['playlist', 'single']} onChange={onSelectType} value={type} />
                        </ColContainer>
                        <ColContainer span={12}>
                            <Typography.Text>Player size: </Typography.Text>
                            <Radio.Group options={['small', 'large']} onChange={onSelectSize} value={size} />
                        </ColContainer>
                    </Col>
                    <ColContainer span={24}>
                        <Col span={24}>
                            <Typography.Text>Select Episode</Typography.Text>
                        </Col>
                        <Col span={12}>
                            <Select
                                onChange={onSelectEpisode}
                                data-testid='episode-select'
                                value={activeEpisodeGuid}
                                style={{ width: '100%' }}
                                disabled={type !== 'single'}
                            >
                                {
                                    compose(
                                        map((episode) =>
                                            <Select.Option
                                                key={episode.guid}
                                                value={episode.guid}
                                                data-testid={episode.guid}
                                            >{episode.title}</Select.Option>
                                        ),
                                        mapEscapedEpisode
                                    )(episodes)
                                }
                            </Select>
                        </Col>
                    </ColContainer>
                </BuidlerFormRow>
                <IframeSource
                    iframeSource={iframeSource}
                    type={type}
                    style={style}
                />
        </BuilderFormConatiner>
    )
}

export default BuilderForm