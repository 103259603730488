import { all, call, put, select, takeEvery } from "redux-saga/effects";
import AuthApi from "./auth.api";
import { authenticate, authenticateError, authenticateSuccess, logout, parseAuthenticationHash, register, selectAuthenticationHash, selectEmail, selectPassword, selectRedirectPath } from "./authSlice";

export function* registerUser() {
    const name = yield select(selectEmail)
    const email = yield select(selectEmail)
    const password = yield select(selectPassword)

    try {
        const user = yield call(AuthApi.register, { name, email, password })
        
        yield put(authenticateSuccess(user))
    } catch (error) {
        yield put(authenticateError(error.message))
    }    
}

export function* watchRegisterUser() {
    yield takeEvery(register().type, registerUser)
}


export function* authenticateUser() {
    const redirectPath = yield select(selectRedirectPath)

    try {
        yield call(AuthApi.signin, redirectPath)
    } catch (error) {
        yield put(authenticateError(error.message))
    }
}

export function* watchAuthenticateUser() {
    yield takeEvery(authenticate().type, authenticateUser)
}

export function* parseAuthenticationResult() {
    const hash = yield select(selectAuthenticationHash)

    try {
        const user = yield call(AuthApi.parse, hash)
        
        yield put(authenticateSuccess(user))   
    } catch (error) {
        yield put(authenticateError(error.message))
    }
}

export function* watchParseAuthenticationResult() {
    yield takeEvery(parseAuthenticationHash().type, parseAuthenticationResult)
}

export function* logoutUser() {
    try {
        yield call(AuthApi.logout)
    } catch (error) {
        yield put(authenticateError(error.message)) 
    }
}

export function* watchLogout() {
    yield takeEvery(logout().type, logoutUser)
}

export default function* saga() {
    yield all([
        watchAuthenticateUser(),
        watchRegisterUser(),
        watchParseAuthenticationResult(),
        watchLogout()
    ])
}
