import React from "react"
import { compose, flatten, identity, map, prop, __ } from 'ramda';
import styled from 'styled-components'
import { withPodcasts } from '../../Podcast';
import { Bar } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { DATE_FORMAT } from "../Constants";
import withSession from "./withSession";
import { truncateReferrer } from "../../Utils";
import Card from "../../../Components/Card";
import theme from "../../../theme";
import { useFetchAnalytics } from "../useFetchAnalytics";
import withSearchParams from "../../App/withSearchParams";
import dayjs from "dayjs";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const ChartConatiner = styled.div`
    margin: 50px 0px;
`

const ChartCard = styled(Card)`
    margin: 50px 0px;
`

const ReferersChart = ({
    Session: { referers = [], dispatchFetchReferers = identity } = {},
    Podcasts: { podcast: { id: podcastId, slug: podcastSlug } = {} } = {},
    SearchParams: {
        startDate = dayjs().subtract(7, 'day').startOf('day').format(DATE_FORMAT),
        endDate = dayjs().endOf('day').format(DATE_FORMAT),
        campaignId = null
    },
    interval = 'day',
    episodeGuid = ''
}) => {

    useFetchAnalytics({
        handler: dispatchFetchReferers,
        startDate,
        endDate,
        interval,
        resourceId: podcastId,
        resourceSlug: podcastSlug,
        episodeGuid,
        campaignId
    })

    return podcastId && (
        <ChartCard title="Top Visitor Sources">
            <ChartConatiner>
                <Bar
                    options={{
                        indexAxis: 'y',
                        elements: {
                            bar: {
                                borderWidth: 2,
                            },
                        },
                        responsive: true,
                        plugins: {
                            legend: {
                                display: false,
                                position: 'right',
                            },
                            title: {
                                display: false,
                                text: 'Referers',
                            },
                        },
                        scales: {
                            x: {
                                type: 'linear'
                            }
                        }
                    }}

                    data={{
                        labels: compose(map(truncateReferrer), flatten, map(prop('referer')))(referers),
                        datasets: [{
                            label: 'Visitors',
                            data: compose(flatten, map(prop('referer_count')))(referers),
                            backgroundColor: theme['sessions-graph-color']
                        }]
                    }}
                />
            </ChartConatiner>
        </ChartCard>
    )
}

export default compose(
    withSession,
    withPodcasts,
    withSearchParams
)(ReferersChart)