import React from 'react'
import { compose, identity } from "ramda"
import withConnection from "../Connection/withConnection"
import styled from "styled-components"
import { PodCardsButton } from "../../Typography"

const Button = styled(PodCardsButton)``

const ConnectedButton = ({
    Connection: { connected, connecting },
    content = React.Fragment,
    loading = false,
    disabled = false,
    icon = null,
    htmlType = null,
    style = {},
    handleClick = identity
}) => {
    return (
        <Button onClick={handleClick} style={style} htmlType={htmlType} disabled={disabled || !connected} loading={loading || connecting} icon={icon}>
            {content}
        </Button>
    )
}

export default compose(
    withConnection
)(ConnectedButton)