const dayjs = require("dayjs")
const { identity } = require("ramda")
const { useEffect } = require("react")
const { DATE_FORMAT } = require("./Constants")
const { undefinedFilter } = require('../Utils/Search')

export const useFetchAnalytics = ({
    handler = identity,
    startDate = dayjs().subtract(7, 'day').startOf('day').format(DATE_FORMAT),
    endDate = dayjs().endOf('day').format(DATE_FORMAT),
    interval = 'day',
    resourceId = '',
    resourceSlug = '',
    episodeGuid = null,
    campaignId = null,
    limit = 5
}) => {
    useEffect(() => {
        if (resourceId) {
            handler({ params: undefinedFilter({ 
                startDate,
                endDate,
                interval,
                resourceId,
                resourceSlug,
                episodeGuid,
                campaignId,
                limit
            }) })
        }
    }, [resourceId, startDate, endDate, campaignId])
}