import { analyticsEvents } from "../Analytics";
import { clipEvents } from "../Clip";
import { customerEvents } from "../Customer";
import { followallEvents } from "../Followall";
import { linkEvents } from "../Link";
import { podcastEvents } from "../Podcast";
import { userEvents } from "../User";
import { episodeEvents } from '../Episode'
import { campaignEvents } from '../Campaign'
import { promoEvents } from '../Promo'

export default {
    ...podcastEvents,
    ...analyticsEvents,
    ...followallEvents,
    ...userEvents,
    ...customerEvents,
    ...clipEvents,
    ...linkEvents,
    ...episodeEvents,
    ...campaignEvents,
    ...promoEvents
}