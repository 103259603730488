import { SyncOutlined } from "@ant-design/icons"
import { Row, Col, Spin } from "antd"
import React from "react"

const withLoading = (Component = React.Fragment) => (props = {}) =>
    props.predicate ? (
        <Row justify="center">
            <Spin spinning indicator={<SyncOutlined spin />} style={{ alignItems: 'center', justifyContent: 'center', ...props.style }} />
        </Row>
    ) : <Component {...props}/>

export default withLoading