import React from "react"
import styled from "styled-components"
import PlayerControls from "./PlayerControls"
import { escapeAndStripHtml } from "../../Utils/string.utils"
import { PodcastTitle } from "../../Typography"
import CoverArt from "../../podcards/CoverArt"
import theme from "../../theme"
import { createMediaQuery } from "../../Utils/style.utils"

const DetailsControlContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
`

const PodcastDetailsContainer = styled.div`
    ${createMediaQuery({
    breakpoint: 'md', properties: `
        min-width: 240px;
    `})}
    margin: 0px 10px 0px 10px;
    flex-grow: 1;
`

const PlayPillPodcastTitle = styled(PodcastTitle)`
    font-size: 0.75rem;
    color: ${theme["bright-white"]};
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 195px;
`

const PlayPillEpisodeTitle = styled(PodcastTitle)`
    ${createMediaQuery({
    breakpoint: 'md', properties: `
        max-width: 250px;
    `})}
    font-size: 1rem;
    font-weight: 600;
    color: ${theme["bright-white"]};
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 195px;
`

export const MiniPlayer = ({
    podcast = {},
    episode = {}
}) => {

    const { title : episodeTitle = '' } = episode
    const { title : podcastTitle = '' } = podcast

    const escapedEpisodeTitle = escapeAndStripHtml(episodeTitle)
    const escapedPodcastTitle = escapeAndStripHtml(podcastTitle)

    return podcast.id && episode.id ? (
            <DetailsControlContainer>
                    <CoverArt podcast={podcast} coverSize={50} activeEpisodeGuid={episode.guid}/>
                    <PodcastDetailsContainer>
                        <PlayPillEpisodeTitle>{escapedEpisodeTitle}</PlayPillEpisodeTitle>
                        <PlayPillPodcastTitle>{escapedPodcastTitle}</PlayPillPodcastTitle>
                    </PodcastDetailsContainer>
                    <PlayerControls podcast={podcast} episode={episode} displaySlider={false} />
            </DetailsControlContainer>
    ) : null
}