import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { BuilderApi } from "./builder.api";
import { parsePodcastFeed, parsePodcastFeedFailure, parsePodcastFeedSuccess, selectFeedUrl } from "./builderSlice";

export function* parsePodcastFeedSaga() {
    const feedUrl = yield select(selectFeedUrl)

    try {
        const data = yield call(BuilderApi.parseFeed, feedUrl)

        yield put(parsePodcastFeedSuccess(data))
    } catch (error) {
        yield put(parsePodcastFeedFailure(error.message))
    }
}

export function* watchparsePodcastFeed() {
    yield takeEvery(parsePodcastFeed().type, parsePodcastFeedSaga)
}

export default function* builderSaga() {
    yield all([watchparsePodcastFeed()])
}