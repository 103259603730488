import React from 'react';
import styled from 'styled-components';
import { EllipsisOutlined } from '@ant-design/icons';
import { createMediaQuery } from '../Utils/style.utils';

const ClaimContainer = styled.div`
    ${createMediaQuery({
    breakpoint: 'md', properties: `
        background: rgba(256, 256, 256, 0.75);
    `})}
    border-radius: 50px;
    padding: 7px;
`

const Claim = styled(EllipsisOutlined)`
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0px;
    color: ${({ settings: { gradientTopColor = [] } }) => `rgba(${gradientTopColor.join(',')}, 0.75)`};
    
`

const Header = ({
    podcast = {}
}) => {
    const { settings = {} } = podcast;

    return (
        <ClaimContainer settings={settings}>
            <Claim settings={settings} />
        </ClaimContainer>
    );
}

export default Header;