import { LinkOutlined } from "@ant-design/icons";
import { faFacebook, faInstagram, faPatreon, faTiktok, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const getLinkIcon = (url = '', style = {} ) => {
    let type = url.includes('twitter') ? 'twitter'
        : url.includes('facebook') ? 'facebook'
            : url.includes('tiktok') ? 'tiktok'
                : url.includes('patreon') ? 'patreon'
                    : url.includes('instagram') ? 'instagram'
                        : 'link';

    const icons = {
        twitter: <FontAwesomeIcon size='lg' icon={faTwitter} style={style}/>,
        facebook: <FontAwesomeIcon size='lg' icon={faFacebook} style={style}/>,
        tiktok: <FontAwesomeIcon size='lg' icon={faTiktok} style={style}/>,
        patreon: <FontAwesomeIcon size='lg' icon={faPatreon} style={style}/>,
        instagram: <FontAwesomeIcon size='lg' icon={faInstagram} style={style}/>,
        link: <LinkOutlined style={{ fontSize: '1.25em', ...style }} />
    }

    return icons[type]
}
