import { compose } from "ramda"
import React from "react"
import SummaryBox from "../Charts/SummaryBox"
import withSearchParams from "../../App/withSearchParams"
import withFollowallAnalytics from "./withFollowallAnalytics"

const FollowallSummary = ({
    FollowallAnalytics: { fetchingFollowallSummary, followallSummary } = {},
}) => {

    const {
        total_clicks,
     } = followallSummary

    const Statistics = [
        {
            title: 'Click Throughs',
            statistic: total_clicks.count || 0
        }
    ]

    return (
        <SummaryBox
            Statistics={Statistics}
            loadingPredicate={fetchingFollowallSummary}
        />
    )

}

export default compose(
    withFollowallAnalytics,
    withSearchParams
)(FollowallSummary)
