import { default as conversionReducer } from "./Conversion/conversionSlice";
import { default as playerReducer } from "./Player/playerSlice";
import { default as sessionReducer } from "./Session/sessionSlice";
import { default as combinedReducer } from "./Combined/combinedSlice";
import { default as promoReducer } from "./Promo/promoSlice";
import { default as followallReducer } from "./Followall/followallSlice";

export default  {
    conversion: conversionReducer,
    session: sessionReducer,
    player: playerReducer,
    combined: combinedReducer,
    promoAnalytics: promoReducer,
    followallAnalytics: followallReducer
}